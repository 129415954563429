import { FC } from "react";
import { useHistory } from "react-router-dom";

import {
  SectionContainer,
  SectionHeader,
  SectionButton,
  SectionImage,
  ImagesRow,
  MediumHeader,
} from "../style/MainPageStyle.js";
import modsImage from "../../../assets/modsimage.jpg";
import modsBottomDotsImage from "../../../assets/modsBottomDotsImage.png";
import { isMobile } from "react-device-detect";

const Section6Mods: FC = () => {
  let history = useHistory();

  const redirectToPosterCreator = () => {
    history.push("/kreator");
  };

  return (
    <SectionContainer
      className="section section4"
      gridRowStart={7}
      gridColumnStart={2}
      gridColumnEnd={3}
      mTop={isMobile ? 40 : 125}
    >
      <SectionHeader
        alignSelf="center"
        customWidth={`${window.innerWidth - 190 * 2}`}
      >
        Dwa kolory; jasny & ciemny
      </SectionHeader>
      <ImagesRow mTop={59}>
        <SectionImage
          className="section-6-image"
          src={modsImage}
          width={1410}
        ></SectionImage>
      </ImagesRow>
      <MediumHeader
        textAlign={"center"}
        mTop={isMobile ? 30 : 155}
        alignSelf={"center"}
        maxWidth={1104}
        className="mobile"
      >
        Stwórz jedyny taki plakat o tematyce krypto, a my wydrukujemy go dla
        Ciebie.
      </MediumHeader>
      <SectionButton
        onClick={redirectToPosterCreator}
        mTop={55}
        alignSelf={"center"}
      >
        Przejdź do kreatora
      </SectionButton>
      <SectionImage
        src={modsBottomDotsImage}
        mTop={90}
        className="bottom-dots-image"
        // width={window.innerWidth}
        position={"relative"}
        left={-255}
      ></SectionImage>
    </SectionContainer>
  );
};

export default Section6Mods;
