import {
  CHANGE_FORMAT,
  CHANGE_MODE,
  CHANGE_CHART_RESULTS_QUANTITY,
  CHANGE_CHART_THEME,
  CHANGE_MAIN_HEADER,
  CHANGE_SUB_HEADER,
  CHANGE_PARAGRAPH,
  CHANGE_SHOW_LOGO,
  CHANGE_SHOW_DATE,
  CHANGE_SHOW_CRYPTO_PRICE,
  CHANGE_SHOW_MARKET_DOMINANCE,
  CHANGE_SHOW_MARKET_CAP,
  CHOOSE_SLOGAN,
  CHOOSE_QUOTE,
  CHANGE_SHOW_SLOGAN,
  CHANGE_SHOW_QUOTE,
  CHANGE_SHOW_MARKET_RANK,
  CHANGE_PERIOD_LABEL,
  CHANGE_START_INVEST_DATE,
  CHANGE_CURRENCY,
  SHOW_AVARAGE_PRICE,
  SHOW_CRYPTO_STATS,
  SHOW_START_INVEST,
  SHOW_TRADEGRAPHY_LOGO,
  SET_CHART_DATA,
  SET_CHOSEN_SIZE,
  SET_CURRENCIES,
  CHANGE_CHART_PERIOD,
  SET_MAX_PRICE,
  SET_MIN_PRICE,
  SET_CHANGE,
  SET_AVARAGE_PRICE,
  CHANGE_ACCENT_COLOR,
  CHANGE_USER_QUOTE,
  CHANGE_GALLERY_QUOTE,
  CHOOSE_QUOTE_ARR,
} from "./actionTypes";

export const changeFormat = (format) => ({
  type: CHANGE_FORMAT,
  payload: {
    format: format,
  },
});

export const changeMode = (mode) => ({
  type: CHANGE_MODE,
  payload: {
    mode: mode,
  },
});

export const changeChartResultsAmount = (amount) => ({
  type: CHANGE_CHART_RESULTS_QUANTITY,
  payload: {
    resultsAmount: amount,
  },
});

export const changeChartTheme = (theme) => ({
  type: CHANGE_CHART_THEME,
  payload: {
    theme: theme,
  },
});

export const changeMainHeaderTextAction = (text) => ({
  type: CHANGE_MAIN_HEADER,
  payload: {
    text: text,
  },
});

export const changeSubHeaderTextAction = (text) => ({
  type: CHANGE_SUB_HEADER,
  payload: {
    text: text,
  },
});

export const changeParagraphTextAction = (text) => ({
  type: CHANGE_PARAGRAPH,
  payload: {
    text: text,
  },
});

// SETTINGS ACTIONS

export const changeShowLogoAction = (showLogo) => ({
  type: CHANGE_SHOW_LOGO,
  payload: {
    showLogo: showLogo,
  },
});

export const changeShowDateAction = (showDate) => ({
  type: CHANGE_SHOW_DATE,
  payload: {
    showDate: showDate,
  },
});

export const changeShowCryptoPriceAction = (showCryptoPrice) => ({
  type: CHANGE_SHOW_CRYPTO_PRICE,
  payload: {
    showCryptoPrice: showCryptoPrice,
  },
});

export const changeShowMarketDominanceAction = (showMarketDominance) => ({
  type: CHANGE_SHOW_MARKET_DOMINANCE,
  payload: {
    showMarketDominance: showMarketDominance,
  },
});

export const changeShowMarketCapAction = (showMarketCap) => ({
  type: CHANGE_SHOW_MARKET_CAP,
  payload: {
    showMarketCap: showMarketCap,
  },
});

export const changeShowSlogan = (showSlogan) => ({
  type: CHANGE_SHOW_SLOGAN,
  payload: {
    showSlogan: showSlogan,
  },
});

export const changeShowQuote = (showQuote) => ({
  type: CHANGE_SHOW_QUOTE,
  payload: {
    showQuote: showQuote,
  },
});

export const changeQuoteArr = (quoteArr) => ({
  type: CHOOSE_QUOTE_ARR,
  payload: {
    quoteArr: quoteArr,
  },
});

export const changeShowMArketRank = (showMarketRank) => ({
  type: CHANGE_SHOW_MARKET_RANK,
  payload: {
    showMarketRank: showMarketRank,
  },
});

export const setChosenSlogan = (slogan) => ({
  type: CHOOSE_SLOGAN,
  payload: {
    slogan: slogan,
  },
});

export const setChosenQuote = (quote) => ({
  type: CHOOSE_QUOTE,
  payload: {
    quote: quote,
  },
});

export const changePeriodLabel = (label) => ({
  type: CHANGE_PERIOD_LABEL,
  payload: {
    label: label,
  },
});

export const changeStartIvestDate = (date) => ({
  type: CHANGE_START_INVEST_DATE,
  payload: {
    date: date,
  },
});

export const changeCurrency = (currency) => ({
  type: CHANGE_CURRENCY,
  payload: {
    currency: currency,
  },
});

export const changeShowAvaragePrice = (showAvaragePrice) => ({
  type: SHOW_AVARAGE_PRICE,
  payload: {
    showAvaragePrice: showAvaragePrice,
  },
});

export const changeShowCryptoStats = (showCryptoStats) => ({
  type: SHOW_CRYPTO_STATS,
  payload: {
    showCryptoStats: showCryptoStats,
  },
});

export const changeShowStartInvest = (showStartInvest) => ({
  type: SHOW_START_INVEST,
  payload: {
    showStartInvest: showStartInvest,
  },
});

export const changeShowTradegraphyLogo = (showTradegraphy) => ({
  type: SHOW_TRADEGRAPHY_LOGO,
  payload: {
    showTradegraphy: showTradegraphy,
  },
});

export const setChartData = (chartData) => ({
  type: SET_CHART_DATA,
  payload: {
    chartData: chartData,
  },
});

export const setChosenSize = (chosenSize) => ({
  type: SET_CHOSEN_SIZE,
  payload: {
    selectedFormat: chosenSize,
  },
});

export const changeMainColorLightTheme = (color) => ({
  type: SET_CHOSEN_SIZE,
  payload: {
    mainColorLightTheme: color,
  },
});

export const changeMainColorDarkTheme = (color) => ({
  type: SET_CHOSEN_SIZE,
  payload: {
    mainColorDarkTheme: color,
  },
});

export const setAllCurrencies = (allCurrencies) => ({
  type: SET_CURRENCIES,
  payload: {
    allCurrencies: allCurrencies,
  },
});

export const changeChartPeriod = (chartPeriod) => ({
  type: CHANGE_CHART_PERIOD,
  payload: {
    chartPeriod: chartPeriod,
  },
});

export const setMaxPrice = (maxPrice) => ({
  type: SET_MAX_PRICE,
  payload: {
    maxPrice: maxPrice,
  },
});

export const setMinPrice = (minPrice) => ({
  type: SET_MIN_PRICE,
  payload: {
    minPrice: minPrice,
  },
});

export const setChange = (change) => ({
  type: SET_CHANGE,
  payload: {
    change: change,
  },
});

export const setAvaragePrice = (avaragePrice) => ({
  type: SET_AVARAGE_PRICE,
  payload: {
    avaragePrice: avaragePrice,
  },
});

export const changeAccentColor = (accentColor) => ({
  type: CHANGE_ACCENT_COLOR,
  payload: {
    accentColor: accentColor,
  },
});

export const changeUserQuote = (userQuote) => ({
  type: CHANGE_USER_QUOTE,
  payload: {
    userQuote: userQuote,
  },
});

export const changeGalleryQuote = (galleryQuote) => ({
  type: CHANGE_GALLERY_QUOTE,
  payload: {
    galleryQuote: galleryQuote,
  },
});
