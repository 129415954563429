import {
  HamburgerIcon,
  PosterPageMobileNavContainer,
  HeaderNavButton,
} from "../../MainPage/style/MainPageStyle";
import history from "../../../history";

import CartIcon from "../../../assets/cart-icon.svg";
import CartIconDark from "../../../assets/cart-icon-white.svg";

const PosterPageMobileNavigation = ({ theme, handleHamburgerClick }) => {
  return (
    <PosterPageMobileNavContainer theme={theme}>
      <HamburgerIcon theme={theme} onClick={() => handleHamburgerClick()} />

      <HeaderNavButton
        className="mobile-cart-icon"
        width={"35px"}
        height={"35px"}
        image={theme == "dark" ? CartIconDark : CartIcon}
        marginRight={"unset"}
        margin={"10px"}
        onClick={() => history.push("/podsumowanie")}
      ></HeaderNavButton>
    </PosterPageMobileNavContainer>
  );
};

export default PosterPageMobileNavigation;
