import React, { useState } from "react";

import { Row } from "../../PosterSizesStepPage/style/PosterSizesStepPageStyle";
import { PolicyContainer } from "../style/MainPageStyle";
import {
  HeaderMobileLogoAndButtonContainer,
  HamburgerIcon,
  HeaderCartContainer,
  HeaderNavButton,
} from "../style/MainPageStyle";
import { isMobile } from "react-device-detect";
import CartIcon from "../../../assets/cart-icon.svg";
import history from "../../../history";
import cookie from "react-cookies";
import NavigationHeaderContainer from "./NavigationHeader.tsx";

const Regulations = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);

  const handleHamburgerClick = () => {
    setMenuOpen(!isMenuOpen);
  };
  return (
    <PolicyContainer>
      <HeaderMobileLogoAndButtonContainer
        width={"100vw"}
        justify="space-between"
        className="left-pos"
      >
        <HamburgerIcon onClick={handleHamburgerClick} />
        {isMobile && (
          <HeaderCartContainer>
            <HeaderNavButton
              className="mobile-cart-icon"
              width={"35px"}
              height={"35px"}
              image={CartIcon}
              marginRight={"unset"}
              margin={"unset"}
              onClick={() => history.push("/podsumowanie")}
            ></HeaderNavButton>
            {cookie.load("userEnteredCheckout") &&
              localStorage.getItem("var") && (
                <div className="cart-items">1</div>
              )}
          </HeaderCartContainer>
        )}
      </HeaderMobileLogoAndButtonContainer>

      <NavigationHeaderContainer
        isMenuOpen={isMenuOpen}
        handleHamburgerClick={handleHamburgerClick}
        position={""}
        theme={""}
      />
      <Row className="center header">Regulamin Sklepu</Row>
      <Row>
        Produkty oferowane na stronie są sprzedawane przez
        <div className="display-contents bold"> Softroids Sp. z o.o.</div>, ul.
        Krynicka 8 lok. 10, 50-555 Wrocław, wpisana do Krajowego Rejestru
        Sądowego przez Sąd Rejonowy dla Wrocławia-Fabrycznej we Wrocławiu, VI
        Wydział Gospodarczy Krajowego Rejestru Sądowego pod numerem
        KRS:0000881847, NIP:8992892803, REGON: 388087871, e-mail:{" "}
        <a href="mailto:hello@tradegraphy.com">hello@tradegraphy.com</a>.
      </Row>

      <Row margin="2.5rem 0 0 0" className="header-paragraph bold">
        § 1
      </Row>
      <Row margin="0 0 2.5rem 0" className="header-paragraph underline bold">
        Informacje oglólne
      </Row>

      <ol>
        <li>
          <Row>Podstawowe pojęcia:</Row>

          <ol>
            <li>
              <Row>Regulamin – niniejszy Regulamin.</Row>
            </li>
            <li>
              <Row>
                Dostawa – oznacza dostarczenie Klientowi Produktu przez
                Sprzedawcę
              </Row>
            </li>
            <li>
              <Row>
                Dostawca – podmiot, z którym współpracuje Sprzedawca, a którego
                zadaniem jest zrealizowanie dostawy (szczegółowe informacje dot.
                dostawców i form dostawcy określono na stronie Sprzedawcy
              </Row>
            </li>
            <li>
              <Row>
                Dni robocze – dni od poniedziałku do piątku za wyjątkiem dni
                ustawowo wolnych od pracy.
              </Row>
            </li>
            <li>
              <Row>
                Konsument – osoba fizyczna, zawierająca ze Sprzedawcą umowę
                niezwiązaną z bezpośrednio z jej działalnością gospodarczą lub
                zawodową.
              </Row>
            </li>
            <li>
              <Row>
                Przedsiębiorca na prawach konsumenta – osoba fizyczna
                zawierająca umowę bezpośrednio związaną z jej działalnością
                gospodarczą, gdy z treści tej umowy wynika, że nie posiada ona
                dla niej charakteru zawodowego, wynikającego w szczególności z
                przedmiotu wykonywanej przez nią działalności gospodarczej,
                udostępnionego na podstawie przepisów o Centralnej Ewidencji i
                Informacji o Działalności Gospodarczej
              </Row>
            </li>
            <li>
              <Row>
                Klient/Użytkownik – podmiot, który planuje dokonać zakupu lub
                dokonuje zakupu produktu/ów, tj. osoba fizyczna posiadająca
                pełną zdolność do czynności prawnych, a w wypadkach
                przewidzianych przez przepisy powszechnie obowiązujące także
                osoba fizyczna posiadająca ograniczoną zdolność do czynności
                prawnych, osoba prawna, a także jednostka organizacyjna
                nieposiadająca osobowości prawnej, której ustawa przyznaje
                zdolność prawną – która zawarła lub zamierza zawrzeć Umowę
                Sprzedaży ze Sprzedawcą, zwany dalej także Użytkownikiem.
              </Row>
            </li>
            <li>
              <Row>
                Produkt – jest to produkt oferowany przez Sprzedawcę na stronie,
                który może być przedmiotem umowy sprzedaży. Produkty oferowane w
                sklepie są produktami przygotowywanymi na specjalne zamówienie,
                zgodnie z Wytycznymi Użytkownika.
              </Row>
            </li>
            <li>
              <Row>
                Sklep/strona – strona, na której prowadzona jest sprzedaż
                produktów przez Sprzedawcę.
              </Row>
            </li>
            <li>
              <Row>
                Trwały nośnik - oznacza materiał lub narzędzie do przechowywania
                informacji, które pozwala na dostęp do tych informacji w
                przyszłości przez czas niezbędny do celów, jakim te informacje
                służą i które pozwalają na odtworzenie przechowywanych
                informacji w niezmienionej postaci.
              </Row>
            </li>
          </ol>
        </li>
      </ol>

      <Row margin="2.5rem 0  0" className="header-paragraph  bold">
        § 2
      </Row>
      <Row margin="0 0 2.5rem 0" className="header-paragraph underline bold">
        Najważniejsze informacje dot. umowy sprzedaży
      </Row>

      <ol>
        <li>
          Warunki realizacji umowy sprzedaży określa Regulamin oraz szczegółowy
          opis wskazany przy opisie produktu.
        </li>
        <li>
          Umowa sprzedaży zostaje zawarta z chwilą potwierdzenia przez
          Sprzedawcę przyjęcia zamówienia do realizacji i opłacenia zamówienia.
        </li>
        <li>
          Ceny są cenami brutto i zawierają wszystkie podatki wymagane
          przepisami prawa. Ceny nie zawierają informacji odnośnie kosztów
          dostawy czy innych kosztów, które Klient zobowiązany będzie ponieść, a
          o których to kosztach będzie poinformowany przy składaniu zamówienia.
        </li>
      </ol>

      <Row margin="2.5rem 0 0 0" className="header-paragraph  bold">
        § 3
      </Row>
      <Row margin="0 0 2.5rem 0" className="header-paragraph underline bold">
        {" "}
        Zakupy{" "}
      </Row>

      <ol>
        <li>
          <Row>
            Zamówienia produktów mogą być dokonywane przez 7 dni w tygodniu, 24
            godziny na dobę.
          </Row>
        </li>
        <li>
          <Row>
            Płatności za produkt można dokonać za pomocą przelewu
            elektronicznego/kartą płatniczą.
          </Row>
        </li>
        <li>
          <Row>Płatności będą dokonywane za pośrednictwem serwisu Stripe.</Row>
        </li>
        <li>
          <Row>
            Za pośrednictwem serwisu Stripe, Użytkownik będzie mógł dokonać
            płatności kartą płatniczą lub przelewem elektronicznym operatora
            płatności Przelewy 24.
          </Row>
        </li>
        <li>
          <Row>
            W przypadku gdy przy opisie produktu nie wskazano inaczej, płatności
            należy dokonać niezwłocznie po złożeniu zamówienia.
          </Row>
        </li>
        <li>
          <Row>W tym celu:</Row>
          <ol>
            <li>
              <Row>
                wygeneruj swój produkt (plakat) wykorzystując do tego kreator
                plakatu,
              </Row>
            </li>
            <li>
              <Row>wybierz rozmiar produktu (plakatu),</Row>
            </li>
            <li>
              <Row>
                po wygenerowaniu swojego produktu (plakatu) i określeniu
                wszystkich wymaganych informacji, wskaż formę płatności oraz
                sposób dostawy. Przed zaakceptowaniem zamówienia Klient jest
                informowany o łącznej cenie za wybrane produkty, w tym za
                dostawę oraz o innych dodatkowych kosztach wynikających ze
                złożonego zamówienia,
              </Row>
            </li>
            <li>
              <Row>
                o zapoznaniu się z ww. informacją o zamówieniu, Klient powinien
                potwierdzić, że składa zamówienie,
              </Row>
            </li>
            <li>
              <Row>
                Klient dokonuje płatności za zamówienie zgodnie z wybranym
                sposobem płatności.
              </Row>
            </li>
          </ol>
        </li>
        <li>
          <Row>
            Składając zamówienie i dokonując płatności, Klient składa Sprzedawcy
            ofertę zawarcia umowy sprzedaży.
          </Row>
        </li>
        <li>
          <Row>
            Sprzedawca przesyła Klientowi elektronicznie (na wskazany przez
            Klienta adres e-mail) informację o przyjęciu zamówienia do
            realizacji, co jest równoznaczne z zawarciem umowy sprzedaży. Po
            zawarciu umowy, Sprzedawca przesyła Klientowi także jej warunki.
          </Row>
        </li>
      </ol>

      <Row margin="2.5rem 0 0 0" className="header-paragraph">
        § 4
      </Row>
      <Row margin="0 0 2.5rem 0" className="header-paragraph underline bold">
        Realizacja zamówienia
      </Row>

      <ol>
        <li>
          <Row>
            Termin, w którym Klient otrzyma przesyłkę jest uzależniony od
            wskazanego przez Sprzedawcę terminu przygotowania zamówienia, a
            następnie od terminu dostawy przewidzianego dla wybranego przez
            Klienta sposobu dostawy.
          </Row>
        </li>

        <li>
          <Row>
            Termin na realizację zamówienia, w tym na dostawę produktów należy
            liczyć od dnia zawarcia umowy (tj. potwierdzenia przyjęcia
            zamówienia do realizacji).
          </Row>
        </li>

        <li>
          <Row>
            Szacowany czas przygotowania produktu do wysyłki (tzw. przygotowania
            produktu do wysyłki) wynosi do 2 dni roboczych (o ile w opisie
            produktu nie wskazano inaczej).
          </Row>
        </li>

        <li>
          <Row>
            Dostawa jest realizowana na adres wskazany przez Klienta w
            formularzu zamówienia lub do punktu odbioru wskazanego przez Klienta
            – w zależności od wybranej metody dostawy.
          </Row>
        </li>

        <li>
          <Row>
            Klient może wybrać opcje dostarczenia zamówienia przez paczkomaty
            Inpost lub przesyłką kurierską (In Post, DPD lub za pośrednictwem
            innego dostawcy – zgodnie z wyborem).
          </Row>
        </li>

        <li>
          <Row>
            W razie problemów z dostarczeniem przesyłki przez Dostawcę takich
            jak m.in. nieobecność Klienta pod wskazanym adresem, Dostawca
            pozostawia awizo pod wskazanym adresem lub telefonicznie ustala inny
            termin, w którym możliwe będzie dostarczenie zamówienia. W przypadku
            nieodebrania zamówienia w wyznaczonym terminie, zamówienie zostanie
            odesłane do Sprzedawcy. Po odesłaniu zostanie ustalony z Klientem
            nowy termin dostawy oraz określone zostaną koszty ponownej dostawy.
          </Row>
        </li>

        <li>
          <Row>
            Klient powinien zbadać odebrane zamówienie przy odbiorze przesyłki,
            a w razie stwierdzenia nieprawidłowości – ma prawo zażądać od
            Dostawcy sporządzenia protokołu z odbioru.
          </Row>
        </li>

        <li>
          <Row>
            Koszty dostawy zamówienia do Klienta ponosi Sprzedawca, chyba że
            Klient przed akceptacją zamówienia został powiadomiony o kosztach
            przesyłki.
          </Row>
        </li>
      </ol>

      <Row margin="2.5rem 0 0 0" className="header-paragraph  bold">
        § 5
      </Row>
      <Row margin="0 0 2.5rem 0" className="header-paragraph underline bold">
        Odstąpienie od umowy{" "}
      </Row>

      <ol>
        <li>
          <Row>
            Zgodnie z art. 38 pkt 3 ustawy o prawach konsumenta, prawo
            odstąpienia od umowy zawartej na odległość nie przysługuje Klientowi
            w przypadku gdy przedmiotem świadczenia jest rzecz
            nieprefabrykowana, wyprodukowana według specyfikacji Klienta lub
            służąca zaspokojeniu jego zindywidualizowanych potrzeb.
          </Row>
        </li>
        <li>
          <Row>
            W przypadku produktów dostępnych w ofercie Sprzedawcy, które to
            produkty są nieprefabrykowane, produkowane według specyfikacji
            Klienta wskazanej podczas generowania produktu w kreatorze, prawo do
            odstąpienia od umowy przewidziane w ustawie o prawach konsumenta nie
            przysługuje.{" "}
          </Row>
        </li>
        <li>
          <Row>
            W pozostałych przypadkach, Klient będący Konsumentem lub
            Przedsiębiorcą na prawach konsumenta, który zawarł ze Sprzedawcą
            umowę na odległość, ma prawo odstąpić od umowy bez podawania
            przyczyny w terminie 14 dni od dnia zawarcia umowy z zastrzeżeniem
            wyjątków wskazanych poniżej. Aby odstąpić od umowy, Klient musi
            poinformować Sprzedawcę o swojej decyzji o odstąpieniu od umowy w
            drodze jednoznacznego oświadczenia (wzór – załącznik nr 2 do
            Regulaminu)
          </Row>
        </li>
      </ol>

      <Row margin="2.5rem 0 00" className="header-paragraph  bold">
        § 6
      </Row>
      <Row margin="0 0 2.5rem 0" className="header-paragraph underline bold">
        Odpowiedzialność za wady
      </Row>
      <ol>
        <li>
          <Row>
            Sprzedawca ma obowiązek dostarczyć Klientowi Produkt wolny od wad.
          </Row>
        </li>
        <li>
          <Row>
            Sprzedawca jest odpowiedzialny względem Klienta, jeżeli Produkt ma
            wadę fizyczną lub prawną (rękojmia za wady).
          </Row>
        </li>
        <li>
          <Row>
            Zakres i zasady odpowiedzialności (rękojmi) za wady regulują
            przepisy ustawy – kodeks cywilny lub ustawy o prawach konsumenta. Na
            zasadach określonych w tych przepisach, Klient może m.in. żądać
            usunięcia wady, wymiany rzeczy na wolną od wad, złożyć oświadczenie
            o obniżeniu ceny lub o odstąpieniu od umowy.
          </Row>
        </li>
        <li>
          <Row>
            Jeżeli Klient stwierdzi wadę Produktu powinien poinformować o tym
            Sprzedawcę, określając jednocześnie swoje roszczenie związane ze
            stwierdzoną wadą lub składając oświadczenie stosownej treści.
          </Row>
        </li>
        <li>
          <Row>
            Klient może kontaktować się ze Sprzedawcą zarówno pocztą tradycyjną
            (adres: Softroids Sp. z o.o., ul. Krynicka 8 lok. 10, 50-555
            Wrocław) lub pocztą elektroniczną (hello@tradegraphy.com) Sprzedawca
            rozpatruje reklamacje w ciągu 14 dni od dnia doręczenia mu
            reklamacji za pomocą takiego środka komunikacji, przy wykorzystaniu
            którego reklamacja została złożona.
          </Row>
        </li>
      </ol>

      <Row margin="2.5rem 0 0 0" className="header-paragraph  bold">
        § 7
      </Row>
      <Row margin="0 0 2.5rem 0" className="header-paragraph underline bold">
        Odpowiedzialność za wady
      </Row>
      <ol>
        <li>
          <Row>
            Sprzedawca oświadcza, iż publiczny charakter sieci Internet
            i korzystanie z usług świadczonych drogą elektroniczną wiązać może
            się z zagrożeniem pozyskania i modyfikowania danych Klientów przez
            osoby nieuprawnione, dlatego Klienci powinni stosować właściwe
            środki techniczne, które zminimalizują wskazane wyżej zagrożenia.
            W szczególności powinni stosować programy antywirusowe i chroniące
            tożsamość korzystających z sieci Internet. Sprzedawca nigdy nie
            zwraca się do Klienta z prośbą o udostępnienie mu w jakiejkolwiek
            formie Hasła.
          </Row>
        </li>
        <li>
          <Row>
            Dla złożenia zamówienia w Sklepie konieczne jest włączenie obsługi
            plików cookies oraz obsługi JavaScript w przeglądarce internetowej.
          </Row>
        </li>
        <li>
          <Row>
            W celu złożenia zamówienia, konieczne jest posiadanie przez Klienta
            aktywnego konta poczty elektronicznej.
          </Row>
        </li>
        <li>
          <Row>
            Aby dokonać zakupu produktów konieczne jest posiadanie przez
            Użytkownika urządzenia z aktualną przeglądarką internetową wspieraną
            przez producenta z dostępem do Internetu.
          </Row>
        </li>
      </ol>

      <Row margin="2.5rem 0 0 0" className="header-paragraph  bold">
        § 8
      </Row>
      <Row margin="0 0 2.5rem 0" className="header-paragraph underline bold">
        Postanowienia końcowe
      </Row>

      <ol>
        <li>
          <Row>
            Informacje zawarte na stronie internetowej Sprzedawcy nie stanowią
            oferty Sprzedawcy w rozumieniu Kodeksu Cywilnego, a jedynie
            zaproszenie Klientów do składania ofert zawarcia umowy sprzedaży w
            sposób opisany w niniejszym Regulaminie.
          </Row>
        </li>
        <li>
          <Row>
            W ramach korzystania z produktów zabrania się dostarczania
            informacji o charakterze bezprawnym oraz działania w sposób
            sprzeczny z prawem, dobrymi obyczajami lub naruszający dobra
            osobiste osób trzecich.
          </Row>
        </li>
        <li>
          <Row>
            Polubowne rozpatrywanie sporów i rozpatrywania reklamacji. Konsument
            ma możliwość zwrócenia się do:
          </Row>
        </li>
        <li>
          <Row>
            stałego polubownego sądu konsumenckiego z wnioskiem o
            rozstrzygnięcie sporu wynikłego z zawartej umowy.
          </Row>
        </li>
        <li>
          <Row>
            wojewódzkiego inspektora Inspekcji Handlowej z wnioskiem o wszczęcie
            postępowania mediacyjnego w sprawie polubownego zakończenia sporu
            między Użytkownikiem a Sprzedawcą.
          </Row>
        </li>
        <li>
          <Row>
            powiatowego (miejskiego) rzecznika praw konsumenta lub organizacji
            społecznej, do której statutowych zadań należy ochrona konsumentów i
            uzyskać pomoc w sprawie umowy lub ma prawo
          </Row>
        </li>
        <li>
          <Row>
            skorzystać z platformy ODR. Platforma służy rozstrzyganiu sporów
            pomiędzy konsumentami i przedsiębiorcami{"  "}
            <a href="http://ec.europa.eu/consumers/odr">
              {" "}
              http://ec.europa.eu/consumers/odr
            </a>
          </Row>
        </li>
        <li>
          <Row>
            Sprzedawca zastrzega sobie prawo do zmiany Regulaminu. Do umów
            zawartych przed wejściem w życie nowego Regulaminu, stosuje się
            wersję Regulaminu obowiązującą w dacie zawarcia przez Użytkownika
            Umowy.
          </Row>
        </li>
        <li>
          <Row>
            Do spraw nieuregulowanych stosuje się m.in. Kodeks cywilny, ustawę o
            prawach konsumenta, ustawę o świadczeniu usług drogą elektroniczną.
          </Row>
        </li>
        <li>
          <Row>
            Sprawy sporne między Sprzedawcą a Klientem niebędącym konsumentem/
            przedsiębiorcą na prawach konsumenta rozpatrywane są przez sąd
            właściwy dla siedziby Sprzedawcy.
          </Row>
        </li>
        <li>
          <Row>
            Zasady dot. ochrony danych osobowych uregulowane są w{" "}
            <a href="/polityka-prywatnosci"> Polityce prywatności</a>.
          </Row>
        </li>
      </ol>
    </PolicyContainer>
  );
};

export default Regulations;
