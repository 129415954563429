import { FC } from "react";
import { isMobile } from "react-device-detect";

import {
  SectionImage,
  FooterContainer,
  FooterColumn,
  FooterRow,
  FooterColumnTitle,
  FooterText,
  NewsletterInput,
  SubscribeButton,
  FooterSocialIcon,
} from "../style/MainPageStyle.js";
import tradeGroupLogoFooter from "../../../assets/logo-footer.svg";
import facebookIcon from "../../../assets/facebook-circular-logo.svg";
import youTubeIcon from "../../../assets/youtube.svg";
import twitterIcon from "../../../assets/twitter.svg";
import linkedInIcon from "../../../assets/linkedin-logo.svg";
import mainTheme from "../../../theme/mainTheme.jsx";
import { dimensionWidthFix } from "../../../utils/dimensionFixer";
import instaIcon from "../../../assets/insta-icon.png";

import history from "../../../history.js";

const Footer: FC = () => {
  const redirectUser = (mode: string) => {
    const fbLink = "https://www.facebook.com/tradegraphy/";
    const instaLink = " https://www.instagram.com/tradegraphy_posters/";
    let win: any;

    if (mode == "fb") {
      win = window.open(`${fbLink}`);
    } else {
      win = window.open(`${instaLink}`);
    }

    win.focus();
  };
  return (
    <FooterContainer
      className="footer"
      flexDir="column"
      gridRowStart={8}
      gridColumnStart={1}
      gridColumnEnd={4}
      mTop={0}
      backgroundColor={mainTheme.colors.footerBackgroundColor}
      padding={`${0}px ${dimensionWidthFix(255)}px ${0}px ${dimensionWidthFix(
        255
      )}px`}
    >
      <FooterRow className="footer-row">
        <FooterColumn mTop={!isMobile ? 157 : 20}>
          <FooterColumnTitle>TradeGraphy</FooterColumnTitle>
          <FooterText mTop={!isMobile ? 30 : 15}>
            {" "}
            <a href="#proces-kreatora"> Jak to działa?</a>
          </FooterText>
          <FooterText mTop={!isMobile ? 30 : 15}>
            <a href="#o-projekcie"> O projekcie</a>
          </FooterText>
          <FooterText mTop={!isMobile ? 30 : 15}>
            <a href="/regulamin"> Regulamin</a>
          </FooterText>
          <FooterText mTop={!isMobile ? 30 : 15}>
            <a href="/polityka-prywatnosci"> Polityka Prywatności</a>
          </FooterText>
        </FooterColumn>

        <FooterColumn mTop={!isMobile ? 157 : 20} mLeft={!isMobile ? 260 : 0}>
          <FooterColumnTitle>Kontakt</FooterColumnTitle>
          <FooterText mTop={!isMobile ? 30 : 15}>
            hello@tradegraphy.pl
          </FooterText>
        </FooterColumn>

        {/* <FooterColumn
          mTop={!isMobile ? 157 : 20}
          maxWidth={!isMobile ? 358 : 0}
          mLeftAuto
          className="margin-left-auto"
        >
          <FooterColumnTitle>Newsletter</FooterColumnTitle>
          <FooterText mTop={!isMobile ? 30 : 15}>
            Get the ideas, tool and tips you need to grow your poster straight
            to your inbox.
          </FooterText>

          <FooterRow className="flex-row" mTop={!isMobile ? 30 : 15}>
            <NewsletterInput type="email" placeholder="Enter your email" />
            <SubscribeButton>Subscribe</SubscribeButton>
          </FooterRow>
        </FooterColumn> */}
      </FooterRow>

      <FooterRow className="footer-last-row">
        <FooterColumn
          mTop={!isMobile ? 224 : 20}
          mBottom={!isMobile ? 77.5 : 20}
        >
          <SectionImage
            className="footer-image"
            src={tradeGroupLogoFooter}
            width={220}
          />
        </FooterColumn>

        <FooterColumn
          mTop={!isMobile ? 224 : 20}
          mBottom={!isMobile ? 77.5 : 20}
          mLeft={!isMobile ? 108 : 0}
          justify="center"
        >
          <FooterText>
            TradeGraphy © 2021 Wszystkie prawa zastrzeżone
          </FooterText>
        </FooterColumn>

        <FooterColumn
          mTop={!isMobile ? 224 : 20}
          mBottom={!isMobile ? 77.5 : 20}
          mLeft={!isMobile ? 58 : 0}
          justify="center"
        >
          <FooterText>
            <a
              onClick={() => history.push("/polityka-prywatnosci")}
              rel="nofollow noopener noreferrer"
              target="_blank"
            >
              Polityka prywatności
            </a>
          </FooterText>
        </FooterColumn>

        <FooterColumn
          mTop={!isMobile ? 224 : 20}
          mBottom={!isMobile ? 77.5 : 20}
          mLeft={!isMobile ? 57 : 0}
          justify="center"
        >
          <FooterText>
            <a
              onClick={() => history.push("/regulamin")}
              rel="nofollow noopener noreferrer"
              target="_blank"
            >
              Regulamin
            </a>
          </FooterText>
        </FooterColumn>

        <FooterColumn
          mTop={!isMobile ? 100 : 20}
          mBottom={!isMobile ? 77.5 : 20}
          mLeftAuto={!isMobile}
          justify={isMobile ? "center" : "flex-end"}
        >
          <FooterRow
            alignItems={"center"}
            className="flex-row mobile-footer-icons"
          >
            <FooterSocialIcon
              image={facebookIcon}
              width={23}
              height={23}
              mRight={39}
              className="social-icon"
              onClick={() => redirectUser("fb")}
            />

            <FooterSocialIcon
              image={instaIcon}
              width={46}
              height={46}
              mRight={39}
              className="social-icon insta"
              onClick={() => redirectUser("insta")}
            />
          </FooterRow>
        </FooterColumn>
      </FooterRow>
    </FooterContainer>
  );
};

export default Footer;
