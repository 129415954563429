export const CHANGE_FORMAT = "CHANGE_FORMAT";
export const CHANGE_MODE = "CHANGE_MODE";
export const CHANGE_CHART_RESULTS_QUANTITY = "CHANGE_CHART_RESULTS_QUANTITY";
export const CHANGE_CHART_THEME = "CHANGE_CHART_THEME";

export const CHANGE_MAIN_HEADER = "CHANGE_MAIN_HEADER";
export const CHANGE_SUB_HEADER = "CHANGE_SUB_HEADER";
export const CHANGE_PARAGRAPH = "CHANGE_PARAGRAPH";

export const CHANGE_SHOW_LOGO = "CHANGE_SHOW_LOGO";
export const CHANGE_SHOW_DATE = "CHANGE_SHOW_DATE";
export const CHANGE_SHOW_CRYPTO_PRICE = "CHANGE_SHOW_CRYPTO_PRICE";
export const CHANGE_SHOW_MARKET_DOMINANCE = "CHANGE_SHOW_MARKET_DOMINANCE";
export const CHANGE_SHOW_MARKET_CAP = "CHANGE_SHOW_MARKET_CAP";
export const CHANGE_SHOW_SLOGAN = "CHANGE_SHOW_SLOGAN";
export const CHANGE_SHOW_QUOTE = "CHANGE_SHOW_QUOTE";
export const CHANGE_SHOW_MARKET_RANK = "CHANGE_SHOW_MARKET_RANK";
export const SHOW_AVARAGE_PRICE = "SHOW_AVARAGE_PRICE";

export const CHOOSE_SLOGAN = "CHOOSE_SLOGAN";
export const CHOOSE_QUOTE = "CHOOSE_QUOTE";
export const CHOOSE_QUOTE_ARR = "CHOOSE_QUOTE_ARR";
export const CHOOSE_CURRENCY = "CHOOSE_CHOOSE_CURRENCY";
export const CHANGE_PERIOD_LABEL = "CHANGE_PERIOD_LABEL";

export const CHANGE_CURRENCY = "CHANGE_CURRENCY";

export const CHANGE_START_INVEST_DATE = "CHANGE_START_INVEST_DATE";

export const SHOW_CRYPTO_STATS = "SHOW_CRYPTO_STATS";

export const SHOW_START_INVEST = "SHOW_START_INVEST";

export const SHOW_TRADEGRAPHY_LOGO = "SHOW_TRADEGRAPHY_LOGO";

export const SET_CHART_DATA = "SET_CHART_DATA";

export const SET_CHOSEN_SIZE = "SET_CHOSEN_SIZE";

export const CHANGE_MAIN_COLOR_DARK_THEME = "CHANGE_MAIN_COLOR_DARK_THEME";
export const CHANGE_MAIN_COLOR_LIGH_THEME = "CHANGE_MAIN_COLOR_LIGH_THEME";

export const SET_CURRENCIES = "SET_CURRENCIES";

export const CHANGE_CHART_PERIOD = "CHANGE_CHART_PERIOD";

export const SET_MAX_PRICE = "SET_MAX_PRICE";
export const SET_MIN_PRICE = "SET_MIN_PRICE";
export const SET_CHANGE = "SET_CHANGE";
export const SET_AVARAGE_PRICE = "SET_AVARAGE_PRICE";

export const CHANGE_ACCENT_COLOR = "CHANGE_ACCENT_COLOR";

export const CHANGE_USER_QUOTE = "CHANGE_USER_QUOTE";
export const CHANGE_GALLERY_QUOTE = "CHANGE_GALLERY_QUOTE";
