import React, { FC, useRef, useEffect, useState } from "react";

import {
  SettingsModalContainer,
  ModalCloseButton,
  CurrencyRow,
  CurrenciesBox,
  SettingsRow,
  SettingText,
} from "../styles/SettingsStyles";

import CalendarModalDateRange from "./CalendarModalDateRange";

export interface iSettingsModalQuoteProps {
  theme: string;
  setCurrencyModalOpen: Function;
  currencies: Array<string>;
  handleChangeCurrency: Function;
  actualCurrency: any;
  allCurrencies: any;
  toggleCurrencyModal: Function;
}

const CurrencyModal: FC<iSettingsModalQuoteProps> = ({
  theme,
  setCurrencyModalOpen,
  currencies,
  handleChangeCurrency,
  actualCurrency,
  allCurrencies,
  toggleCurrencyModal,
}) => {
  const modalRef = useRef(null);
  const [modalWidth, setModalWidth] = useState(0);
  const [modalHeight, setModalHeight] = useState(0);

  useEffect(() => {
    calculateModalSizes();
  }, [modalRef]);

  const calculateModalSizes = () => {
    const element: any = modalRef.current;
    let width = element.offsetWidth;
    let height = element.offsetHeight;

    setModalWidth(width);
    setModalHeight(height);
  };

  return (
    <SettingsModalContainer ref={modalRef} theme={theme}>
      <ModalCloseButton
        width={modalWidth}
        height={modalHeight}
        onClick={() => toggleCurrencyModal()}
      ></ModalCloseButton>
      <SettingsRow>
        <SettingText
          theme={theme}
          marginTop={77}
          fSize={33}
          fWeight={600}
          className="bigText"
        >
          Wybierz walutę
        </SettingText>
      </SettingsRow>

      <CurrenciesBox>
        {allCurrencies &&
          allCurrencies.map((currency: any) => (
            <CurrencyRow
              onClick={() => handleChangeCurrency(currency.CoinInfo)}
              theme={theme}
              // active={actualCurrency.FullName  === currency.CoinInfo.FullName ||  actualCurrency == currency.CoinInfo.FullName}
            >
              <div className="container">
                {currency && currency.CoinInfo.FullName}
                <input
                  type="radio"
                  checked={
                    actualCurrency.FullName === currency.CoinInfo.FullName ||
                    actualCurrency == currency.CoinInfo.FullName
                  }
                />
                <span className="checkmark"></span>
              </div>
            </CurrencyRow>
          ))}
      </CurrenciesBox>
    </SettingsModalContainer>
  );
};

export default CurrencyModal;
