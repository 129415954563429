import { FC } from "react";
import { isMobile } from "react-device-detect";
import { useHistory } from "react-router-dom";

import {
  SectionContainer,
  SectionHeader,
  SectionParagraph,
  SectionButton,
  ContainerColumn,
  SectionImage,
  MobileContainer,
} from "../style/MainPageStyle.js";

import section3image1 from "../../../assets/section3image1.jpg";
import section3image2 from "../../../assets/section3image2.jpg";

const Section3About: FC = () => {
  let history = useHistory();

  const redirectToPosterCreator = () => {
    history.push("/kreator");
  };
  return (
    <SectionContainer
      className="section section3 flex-column"
      gridRowStart={4}
      gridColumnStart={2}
      gridColumnEnd={3}
      mTop={isMobile ? 0 : 150}
      flexDir={"row"}
    >
      {!isMobile && (
        <ContainerColumn>
          <MobileContainer className="flex-row">
            <SectionImage
              className="image-1-section-3"
              width={599}
              src={section3image1}
            ></SectionImage>
            <SectionParagraph className="paragraph-1-section-3 " mTop={168}>
              Zapewne byłby to koniec historii, ale zauważyliśmy, że plakat
              wzbudza duże zainteresowanie wśród naszych przyjaciół.
              Postanowiliśmy więc stworzyć aplikację, która umożliwi każdemu z
              Was stworzenie takiego plakatu dla siebie. Z dowolną kryptowalutą,
              z dowolnym cytatem, z dowolną zawartoscią. Jeśli Ci się podoba -
              śmiało, działaj. My to wydrukujemy.
            </SectionParagraph>
            <SectionParagraph mTop={48} className="paragraph-2-section-3">
              Plakaty drukowane są na matowym papierze bardzo, bardzo wysokiej
              jakości. Pakujemy je w specjalne, mocne tuby, które chronią plakat
              przed silnymi rzutami kuriera.
            </SectionParagraph>
          </MobileContainer>
          <SectionButton
            onClick={redirectToPosterCreator}
            mTop={100}
            className="full-width "
          >
            Przejdź do kreatora
          </SectionButton>
        </ContainerColumn>
      )}
      <ContainerColumn>
        <SectionHeader
          id={"o-projekcie"}
          className="full-width section3-header"
          alignSelf="flex-start"
        >
          O projekcie
        </SectionHeader>
        <MobileContainer className="flex-row">
          <SectionParagraph
            mTop={isMobile ? 30 : 48}
            className="paragraph-2-section-3"
          >
            Cała historia zaczęła się od tego, że z przyjacielem poszukiwaliśmy
            plakatu związanego z kryptowalutami. Czegoś co będzie nawiązywało do
            tematu krypto i jednocześnie zdobiło ścianę. Okazało się, że nie
            znaleźliśmy NIC co spełniło by te dwa kryteria. Postanowiliśmy
            stworzyć taki plakat na własną rękę z zaprzyjaźnionym projektantem i
            go wydrukować. Udało nam się stworzyć coś, co na ścianie zrobiło
            efekt WOW.
          </SectionParagraph>

          <SectionImage
            width={600}
            className="image-2-section-2"
            src={section3image2}
            alignSelf={isMobile ? "center" : "flex-end"}
            mTop={73}
          ></SectionImage>
        </MobileContainer>
      </ContainerColumn>

      {isMobile && (
        <ContainerColumn>
          <MobileContainer className="flex-row">
            <SectionParagraph className="paragraph-1-section-3 " mTop={168}>
              Zapewne byłby to koniec historii, ale zauważyliśmy, że plakat
              wzbudza duże zainteresowanie wśród naszych przyjaciół.
              Postanowiliśmy więc stworzyć aplikację, która umożliwi każdemu z
              Was stworzenie takiego plakatu dla siebie. Z dowolną kryptowalutą,
              z dowolnym cytatem, z dowolną zawartoscią. Jeśli Ci się podoba -
              śmiało, działaj. My to wydrukujemy.
            </SectionParagraph>
            <SectionImage
              className="image-1-section-3"
              width={599}
              src={section3image1}
            ></SectionImage>
            <SectionParagraph mTop={48} className="paragraph-2-section-3">
              Plakaty drukowane są na matowym papierze bardzo, bardzo wysokiej
              jakości. Pakujemy je w specjalne, mocne tuby, które chronią plakat
              przed silnymi rzutami kuriera.
            </SectionParagraph>
          </MobileContainer>
          <SectionButton
            onClick={redirectToPosterCreator}
            mTop={150}
            className="full-width "
          >
            Przejdź do kreatora
          </SectionButton>
        </ContainerColumn>
      )}
    </SectionContainer>
  );
};

export default Section3About;
