import React, { FC } from "react";

import {
  SettingsModalContainer,
  SettingsRow,
  SettingText,
  SettingsModalTextarea,
  ModalCloseButton,
} from "../styles/SettingsStyles";
import { SectionButton } from "../../MainPage/style/MainPageStyle";

import {
  dimensionHeightFix,
  dimensionWidthFix,
} from "../../../utils/dimensionFixer";

interface iSettingsModalSloganProps {
  theme: string;
  galleryTexts: Array<string>;
  handleTextAreaUserSlogan: Function;
  userSlogan: string;
  setUserSlogan: Function;
  tempUserSlogan: string;
  tempUserSloganLength: number;
  setSloganModalOpen: Function;
}

const SettingsModalSlogan: FC<iSettingsModalSloganProps> = ({
  theme,
  galleryTexts,
  handleTextAreaUserSlogan,
  userSlogan,
  setUserSlogan,
  tempUserSlogan,
  tempUserSloganLength,
  setSloganModalOpen,
}) => {
  return (
    <SettingsModalContainer theme={theme}>
      <ModalCloseButton onClick={() => setSloganModalOpen(false)} />
      <SettingsRow>
        <SettingText theme={theme} marginTop={77} fSize={33} fWeight={600}>
          Wybierz Twoje hasło
        </SettingText>
      </SettingsRow>
      <SettingsRow>
        <SettingText theme={theme} marginTop={7} fSize={14} fWeight={500}>
          limit: 30 znaków
        </SettingText>
      </SettingsRow>
      <SettingsRow>
        <SettingsModalTextarea
          theme={theme}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleTextAreaUserSlogan(e)
          }
          marginTop={30}
          maxLength={30}
        ></SettingsModalTextarea>
      </SettingsRow>
      <SettingsRow>
        <SettingText
          theme={theme}
          marginTop={6}
          fSize={12}
          fWeight={600}
          opacity={0.3}
        >
          znaki: {tempUserSloganLength} z 30
        </SettingText>
      </SettingsRow>
      <SettingsRow>
        {
          <SectionButton
            className={tempUserSloganLength > 0 ? "active" : "disabled"}
            theme={theme}
            padding={`${dimensionHeightFix(10) + "px"} ${
              dimensionWidthFix(45) + "px"
            }`}
            onClick={
              tempUserSloganLength > 0
                ? () => setUserSlogan(tempUserSlogan)
                : null
            }
          >
            Ustaw
          </SectionButton>
        }
      </SettingsRow>
      <SettingsRow>
        <SettingText
          theme={theme}
          marginTop={33}
          fSize={33}
          fWeight={600}
          className="bigText"
        >
          lub wybierz z naszej galerii
        </SettingText>
      </SettingsRow>
      {galleryTexts.map((galleryText: string, i: number) => (
        <SettingsRow key={i}>
          <SettingText
            className="gallery-slogan-single-text"
            theme={theme}
            marginTop={12}
            fSize={16}
            fWeight={700}
            opacity={0.5}
            onClick={() => setUserSlogan(galleryText)}
          >
            {galleryText}
          </SettingText>
        </SettingsRow>
      ))}
    </SettingsModalContainer>
  );
};

export default SettingsModalSlogan;
