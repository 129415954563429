import React, { useState } from "react";

import { Row } from "../../PosterSizesStepPage/style/PosterSizesStepPageStyle";
import { PolicyContainer } from "../style/MainPageStyle";
import {
  HeaderMobileLogoAndButtonContainer,
  HamburgerIcon,
  HeaderCartContainer,
  HeaderNavButton,
} from "../style/MainPageStyle";
import { isMobile } from "react-device-detect";
import CartIcon from "../../../assets/cart-icon.svg";
import history from "../../../history";
import cookie from "react-cookies";
import NavigationHeaderContainer from "./NavigationHeader.tsx";

const PrivacyPolicy = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);

  const handleHamburgerClick = () => {
    setMenuOpen(!isMenuOpen);
  };
  return (
    <PolicyContainer>
      <HeaderMobileLogoAndButtonContainer
        width={"100vw"}
        justify="space-between"
      >
        <HamburgerIcon onClick={handleHamburgerClick} />
        {isMobile && (
          <HeaderCartContainer>
            <HeaderNavButton
              className="mobile-cart-icon"
              width={"35px"}
              height={"35px"}
              image={CartIcon}
              marginRight={"unset"}
              margin={"unset"}
              onClick={() => history.push("/podsumowanie")}
            ></HeaderNavButton>
            {cookie.load("userEnteredCheckout") &&
              localStorage.getItem("var") && (
                <div className="cart-items">1</div>
              )}
          </HeaderCartContainer>
        )}
      </HeaderMobileLogoAndButtonContainer>

      <NavigationHeaderContainer
        isMenuOpen={isMenuOpen}
        handleHamburgerClick={handleHamburgerClick}
        position={""}
        theme={""}
      />
      <Row className="center header">POLITYKA PRYWATNOŚCI</Row>
      <Row>
        Dbamy o Twoją prywatność. Zbieramy i przetwarzamy Twoje dane tylko i
        wyłącznie, jeżeli jest to konieczne do prawidłowego świadczenia usługi.
      </Row>
      <Row>
        Zgodnie z RODO, poniżej przedstawiamy Ci zasady przetwarzania danych
        stosowane przez Administratora.
      </Row>
      <Row className="header-row">
        <span className="order"> I. </span>
        <span className="underlined bold"> Kim jest Administrator danych?</span>
      </Row>
      <Row>
        Administratorem danych jest{" "}
        <div className="bold display-contents">Softroids Sp. z o.o. </div>ul.
        Krynicka 8 lok. 10, 50-555 Wrocław, wpisana do Krajowego Rejestru
        Sądowego przez Sąd Rejonowy dla Wrocławia-Fabrycznej we Wrocławiu, VI
        Wydział Gospodarczy Krajowego Rejestru Sądowego pod numerem KRS:
        0000881847, NIP:8992892803, REGON: 388087871, e-mail:
        hello@tradegraphy.com.
      </Row>
      <Row className="header-row">
        <span className="order-2"> II. </span>
        <span className="underlined bold">
          {" "}
          W jakim celu zbieramy Twoje dane? Jak długo je przetwarzamy?
        </span>
      </Row>
      <Row margin="0 0 0.5rem 0">
        Możemy przetwarzać Twoje dane w następujących celach:
      </Row>
      <ol>
        <li>
          <Row margin="0.5rem 0" className="bold">
            Komunikacji z Tobą, w tym udzielania odpowiedzi na pytania
            przekazane przez formularz kontaktowy, wiadomość e-mail, w ramach
            prowadzonych spotkań on-line itp.
          </Row>
          <Row>
            Dane będą przetwarzane w oparciu o uzasadniony interes
            Administratora w postaci komunikacji z Użytkownikami Strony (art. 6
            ust. 1 lit. f RODO). Twoje dane będą przetwarzane nie dłużej niż do
            czasu wniesienia przez Ciebie sprzeciwu lub ustania celu
            biznesowego. Podanie tych danych jest dobrowolne, ale niezbędne do
            komunikacji z Tobą. Dane mogą być także przetwarzane w celu
            archiwizacji do celów wewnętrznych w oparciu o uzasadniony interes
            Administratora (art. 6 ust. 1 lit. f RODO) do czasu wniesienia
            sprzeciwu lub ustania celu biznesowego.
          </Row>
        </li>
        <li className="bold">
          Zawarcia umowy i jej realizacji (złożenia zamówienia).
        </li>
        <li className="bold">Ustalenia, obrony i dochodzenia roszczeń.</li>
        <li>
          <Row className="bold">
            Realizacji obowiązków prawnych ciążących na Administratorze (m.in.
            obowiązków podatkowych i archiwizacyjnych).
          </Row>
          <Row margin="0 0 0.5rem 0">
            <div className="bold display-contents">
              Dane niezbędne do zawarcia i realizacji umowy{" "}
            </div>
            będą przetwarzane przez czas realizacji umowy, w tym przez czas
            realizacji uprawnień wynikających z umowy, jak np. prawo do
            reklamacji z tytułu rękojmi (art. 6 ust. 1 lit. b i f RODO). Podanie
            tych danych jest dobrowolne, ale niezbędne do zawarcia i realizacji
            umowy.
          </Row>
          <Row>
            <div className="display-contents bold">
              Dane dodatkowe podane w celu m.in. usprawnienia realizacji umowy{" "}
            </div>
            będą przetwarzane nie dłużej niż do czasu wniesienia przez Ciebie
            sprzeciwu lub ustania celu biznesowego w oparciu o uzasadniony
            interes w postaci obsługi Klientów (art. 6 ust. 1 lit. f RODO).
          </Row>
          <Row>
            Po tym okresie,{" "}
            <div className="display-contents bold">
              {" "}
              dane będą przetwarzane przez okres przedawnienia roszczeń{" "}
            </div>
            w oparciu o uzasadniony interes Administratora w celu obrony przed
            roszczeniami, a także w celu ustalenia i dochodzenia roszczeń (art.
            6 ust. 1 lit. f RODO).
          </Row>
          <Row>
            W przypadku gdy{" "}
            <div className="display-contents bold">
              dane są niezbędne do realizacji obowiązków prawnych ciążących na
              Administratorze{" "}
            </div>{" "}
            (jak np. wystawianie i przechowywanie faktur) – dane będą
            przetwarzane w tym celu nie dłużej niż przez 6 lat (obowiązki
            archiwizacyjne dot. dokumentów księgowych), chyba że przepisy prawa
            wymagają dłuższego okresu (art. 6 ust. 1 lit. c RODO).
          </Row>
          <Row>
            Dane mogą być także archiwizowane do celów wewnętrznych i
            statystycznych do czasu wniesienia przez Ciebie sprzeciwu lub
            ustania celu biznesowego w oparciu o uzasadniony interes
            Administratora (art. 6 ust. 1 lit. f RODO).
          </Row>
        </li>
        <li margin="0.5rem 0" className="bold">
          Wysyłki informacji marketingowych (m.in. wysyłki newslettera oraz
          innych informacji o usługach, produktach, promocjach, darmowych
          treściach).
          <Row className="font-normal">
            Dane będą przetwarzane w oparciu o uzasadniony interes
            Administratora w celu marketingu produktów i usług Administratora
            (art. 6 ust. 1 lit. f RODO). Twoje dane będą przetwarzane nie dłużej
            niż do czasu wniesienia przez Ciebie sprzeciwu lub ustania celu
            biznesowego – w zależności od tego, co nastąpi wcześniej. Podanie
            danych jest dobrowolne, jednak niezbędne do otrzymywania
            newslettera.
          </Row>
          <Row className="font-normal">
            Do celów komunikacji handlowej potrzebujemy Twojej zgody zgodnie z
            art. 10 ustawy o świadczeniu usług drogą elektroniczną. Możesz ją w
            każdej chwili wycofać, klikając link w stopce maila lub pisząc do
            mnie na adres podany wyżej.
          </Row>
        </li>
        <li margin="0.5rem 0" className="bold">
          Administrowania i zarządzania stroną i grupami na platformach
          społecznościowych (m.in. Facebook, Instagram, Twitter) w przypadku
          przetwarzania danych na platformach społecznościowych, w tym
          komunikacji z Tobą, kierowania do Ciebie treści marketingowych.
          <Row className="font-normal">
            Dane te będą przetwarzane wyłącznie wtedy, gdy zdecydujesz się na
            polubienie strony / dołączenie do grupy / wybranie opcji „Obserwuj”
            lub w inny sposób zostawisz swoje dane na platformie zarządzanej
            przez nas, np. w formie umieszczenia wpisu lub komentarza. Dane będą
            przetwarzane przez okres istnienia strony/grupy lub do czasu
            wniesienia przez Ciebie sprzeciwu, co może nastąpić poprzez
            odkliknięcie opcji „Lubię to”, „Obserwuj”, usunięcie
            komentarza/wpisu lub w inny sposób przewidziany w ramach
            platformy/strony lub poprzez skontaktowanie się z nami. Informujemy,
            że zasady odnoszące się do strony/fanpage’a/grupy ustala
            Administrator, natomiast zasady korzystania z portalu
            społecznościowego, na którym umieszczona jest strona/fanpage/grupa
            ustala podmiot zarządzający tymi portalami.
          </Row>
        </li>
      </ol>

      <Row className="header-row">
        <span className="order-3"> III. </span>
        <span className="underlined bold">
          {" "}
          Komu możemy przekazać Twoje dane?
        </span>
      </Row>
      <Row>
        Przekazujemy Twoje dane innym podmiotom tylko wtedy, gdy jest to
        niezbędne do realizacji celów przetwarzania, o których mowa w punkcie
        II.
      </Row>
      <Row margin="1rem 0 0 0">
        W razie potrzeby Twoje dane mogą być przekazane podmiotom, z którymi
        współpracujemy przy realizacji ww. celów: programistom, firmie
        hostingowej, firmie informatycznej, firmie świadczącej usługi księgowe,
        firmie świadczącej usługi newslettera, firmie zajmującą się obsługą
        Klienta oraz wspierającą nas w realizacji zleceń dla Ciebie oraz inny
        podmiotom wspierającym nas w realizacji wskazanych wyżej celów
        przetwarzania.
      </Row>
      <Row>
        Co do zasady dane nie będą przekazywane poza obszar EOG. W sytuacji gdy
        jednak będą przekazane poza EOG, nastąpi to w oparciu o Twoją zgodę,
        standardowe klauzule umowne lub w oparciu o inne zabezpieczenia
        przewidziane w RODO po spełnieniu m.in. obowiązku informacyjnego, o czym
        zostaniesz powiadomiony.
      </Row>
      <Row className="header-row">
        <span className="order-2"> IV. </span>
        <span className="underlined bold"> Jakie prawa Ci przysługują?</span>
      </Row>
      <Row>związku z RODO przysługuje Ci prawo:</Row>
      <ul>
        <li>dostępu do swoich danych osobowych,</li>
        <li>sprostowania danych osobowych,</li>
        <li>usunięcia danych osobowych</li>
        <li>ograniczenia przetwarzania danych osobowych,</li>
        <li>sprzeciwu wobec przetwarzania danych osobowych,</li>
        <li>przenoszenia danych osobowych,</li>
        <li>
          cofnięcia zgody; wycofanie zgody nie wpływa na zgodność z prawem
          przetwarzania dokonanego przed jej cofnięciem.
        </li>
      </ul>
      <Row>
        Jeżeli uważasz, że Twoje dane osobowe są przetwarzane niezgodnie z
        obowiązującym prawem, przysługuje Ci skarga do Prezesa Urzędu Ochrony
        Danych Osobowych. W takim przypadku zachęcamy jednak do wcześniejszego
        kontaktu z nami celem wyjaśnienia swoich wątpliwości.
      </Row>
      <Row className="header-row">
        <span className="order"> V. </span>
        <span className="underlined bold">
          Przepisy prawa obowiązujące w zakresie danych osobowych
        </span>
      </Row>

      <Row>
        W sprawach nieuregulowanych stosuje się właściwe przepisy prawa
        polskiego i europejskiego, w tym w szczególności RODO.
      </Row>

      <Row className="header-row">
        <span className="order-2"> VI. </span>
        <span className="underlined bold">Polityka plików cookies</span>
      </Row>

      <Row>
        Administrator stosuje technologie obserwujące działania podejmowane
        przez Użytkownika w ramach Strony:
      </Row>
      <ol type="a">
        <li>
          piksel konwersji Facebooka – w celu zarządzania reklamami na Facebooku
          i prowadzenia działań remarketingowych;
        </li>
        <li>
          kod Google Analytics – w celu analizy statystyk Strony. Google
          Analytics korzysta z własnych plików cookies do analizowania działań i
          zachowań Użytkowników Strony. Pliki te służą do przechowywania
          informacji, np. z jakiej strony Użytkownik trafił na bieżącą stronę
          internetową. Pomagają udoskonalić Stronę;
        </li>
        <li>
          Google Ads - w celu umożliwienia wyświetleń reklamy tekstowej i
          produktowej w wyszukiwarce, reklamy graficzne (banerowe) w sieci
          reklamowej Google Display Network oraz reklamy wideo w portalu
          YouTube.
        </li>
      </ol>
      <Row>
        Strona nie zbiera w sposób automatyczny żadnych informacji, z wyjątkiem
        informacji zawartych w plikach cookies.
      </Row>
      <Row margin="0.5rem 0 0 0">
        Pliki cookies (tzw. „ciasteczka”) stanowią dane informatyczne, w
        szczególności pliki tekstowe, które przechowywane są w urządzeniu
        końcowym Użytkownika Strony i przeznaczone są do korzystania ze Strony.
        Cookies zazwyczaj zawierają nazwę strony internetowej, z której
        pochodzą, czas przechowywania ich na urządzeniu końcowym oraz unikalny
        numer.
      </Row>
      <Row margin="0.5rem 0 0 0">
        Pliki cookies wykorzystywane są w celu dostosowania zawartości Strony do
        preferencji Użytkownika oraz optymalizacji korzystania ze Strony;
        tworzenia statystyk, które pomagają zrozumieć, w jaki sposób Użytkownicy
        korzystają ze Strony, co umożliwia ulepszanie jej struktury i
        zawartości.
      </Row>
      <Row margin="0.5rem 0 0 0">
        Możesz dokonać samodzielnie zmian w ustawieniach dotyczących plików
        cookies. W wielu przypadkach przeglądarka internetowa domyślnie
        dopuszcza przechowywanie plików cookies w urządzeniu końcowym
        Użytkownika. Szczegółowe informacje o możliwości i sposobach obsługi
        plików cookies dostępne są w ustawieniach oprogramowania (przeglądarki
        internetowej).
      </Row>
      <Row margin="0.5rem 0 0 0">
        Brak wyrażenia zgody na pliki cookies może ograniczać działanie
        niektórych funkcjonalności na Stronie.
      </Row>

      <Row className="header-row">
        <span className="order-3"> VII. </span>
        <span className="underlined bold">Wtyczki społecznościowe</span>
      </Row>
      <Row>
        Na Stronie używane są wtyczki i inne narzędzia społecznościowe
        udostępniane przez portale takie jak: Facebook. Instagram, Google,
        Twitter. Zasady dotyczące przetwarzania danych osobowych opisane są
        bezpośrednio na stronach usługodawców tych portali społecznościowych.
      </Row>
      <Row margin="2rem 0 0 0" className="bold uppercase">
        WSPÓŁADMINISTROWANIE
      </Row>
      <Row>
        Administratorem danych przetwarzanych na potrzeby statystyk zbieranych w
        ramach platformy Facebook jest Softroids Sp. z o.o., ul. Krynicka 8 lok.
        10, 50-555 Wrocław, wpisana DO Krajowego Rejestru Sądowego przez Sąd
        Rejonowy dla Wrocławia-Fabrycznej we Wrocławiu, VI Wydział Gospodarczy
        Krajowego Rejestru Sądowego pod numerem KRS: 0000881847, NIP:8992892803,
        REGON: 388087871 e-mail:hello@tradegraphy.com, zwana/zwany dalej
        Administratorem oraz Facebook Ireland Limited, z siedzibą pod adresem 4
        Grand Canal Square, Grand Canal Harbour, Dublin 2, Irlandia, zwany dalej
        Współadministratorem. Dane te są przetwarzane w oparciu o
        współadministrowanie przez ww. podmioty. Szczegółowe zasady dotyczące
        współadministrowania danymi, w tym informacje o przysługującym prawach,
        opisane zostały na stronie{" "}
        <div className="display-contents bold">
          Informacje o statystykach strony
        </div>
      </Row>
      <Row>
        Administrator przetwarza dane w oparciu o uzasadniony interes
        Administratora polegający na prowadzeniu analiz aktywności Użytkowników,
        a także ich preferencji, w celu poprawy stosowanych funkcjonalności i
        świadczonych usług. W sprawach dotyczących danych osobowych można
        kontaktować się zarówno z Administratorem, jak i Współadministratorem.
      </Row>
    </PolicyContainer>
  );
};

export default PrivacyPolicy;
