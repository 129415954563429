import React, { FC, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useSelector } from "react-redux";
import moment from "moment";
import { reactLocalStorage } from "reactjs-localstorage";

import { calculateDimension, dimensions } from "../../PosterPage/Dimensions";
import {
  PosterContainer,
  PositionedElement,
  TextBlock,
  HidePosterMobileButton,
  PosterBox,
  PosterLayer,
} from "../../PosterPage/style/PosterPageStyle";
import Chart from "../../Chart/Chart.jsx";
import { FaArrowDown } from "react-icons/fa";
import { ChangeMarkElement } from "../styles/SettingsStyles";

interface iShowSettings {
  showCryptoPrice: boolean;
  showDate: boolean;
  showLogo: boolean;
  showMarketCap: boolean;
  showMarketDominance: boolean;
  showMarketRank: boolean;
  showQuote: boolean;
  showSlogan: boolean;
}

interface iQuote {
  quote: string;
  userQuoteAuthorFirstLine: string;
}

interface iFormattedData {
  id: number;
  x: string;
  y: number;
}

interface iPosterProps {
  theme: string;
  showSettings: iShowSettings;
  chosenSlogan: string;
  avarageBitcoinPrice: number | string;
  formattedData: Array<iFormattedData>;
  quote: iQuote;
  transformScale: number;
  transformScaleHeight: any;
  transformScaleWidth: any;
  mode: any;
  showPoster: boolean;
  toggleShowMobilePoster: Function;
  toggleLoader: Function;
  toggleCurrencyModal: Function;
  toggleChartPeriodModal: Function;
  toggleQuoteModal: Function;
  toggleStartInvestmentModal: Function;
  stateShowTradegraphy: boolean;
  stateShowCryptoStats: boolean;
  stateShowCryptoName: boolean;
  stateShowAvaragePrice: boolean;
  stateShowStartInvest: boolean;
  stateShowQuote: boolean;
}

const Poster: FC<iPosterProps> = ({
  theme,
  showSettings,
  formattedData,
  quote,
  transformScale,
  transformScaleWidth,
  transformScaleHeight,
  mode,
  showPoster,
  toggleShowMobilePoster,
  toggleCurrencyModal,
  toggleChartPeriodModal,
  toggleQuoteModal,
  toggleStartInvestmentModal,
  stateShowTradegraphy,
  stateShowCryptoStats,
  stateShowCryptoName,
  stateShowAvaragePrice,
  stateShowStartInvest,
  stateShowQuote,
}) => {
  const wholeStore: any = useSelector((state) => state);

  const posterSettings =
    reactLocalStorage.getObject("var") &&
    Object.keys(reactLocalStorage.getObject("var")).length !== 0
      ? reactLocalStorage.getObject("var")
      : wholeStore;

  const [isDataChart, setDataCHart] = useState(false);
  const [coinNameLength, setCoinNameLength] = useState(0);

  // Put loader on start and end rendering settings page

  useEffect(() => {
    if (wholeStore.chartData && wholeStore.chartData.length) {
      setDataCHart(true);
    }
  }, [wholeStore]);

  // Checking length of currency name

  useEffect(() => {
    wholeStore.currency && wholeStore.currency.FullName
      ? setCoinNameLength(wholeStore.currency.FullName.length)
      : setCoinNameLength(7);
  }, [wholeStore.currency]);

  // useEffect(() => {
  //   setTimeout(() => {
  //     setShowTradegraphy(!posterSettings.showTradegraphy);
  //   }, 200);
  // }, []);

  return (
    <PosterBox
      generatePageMode={mode !== undefined}
      className="poster-box"
      id="posterBox"
    >
      <PosterContainer
        id="posterContainer"
        className={`poster-container ${mode !== undefined && "generate-page"} ${
          showPoster ? "visible" : "invisible"
        }`}
        theme={theme}
        width={calculateDimension(
          !isMobile
            ? dimensions.posterContainer.width
            : dimensions.posterContainer.width,
          mode,
          wholeStore.format
        )}
        height={calculateDimension(
          !isMobile
            ? dimensions.posterContainer.height
            : dimensions.posterContainer.height,
          mode,
          wholeStore.format
        )}
        transformScale={transformScale}
        transformScaleWidth={transformScaleWidth}
        transformScaleHeight={transformScaleHeight}
      >
        <PosterLayer id="posterLayer" theme={theme}>
          {isMobile && (
            <HidePosterMobileButton
              id="hideButton"
              onClick={() => toggleShowMobilePoster()}
            >
              <FaArrowDown />
            </HidePosterMobileButton>
          )}

          {/* BITCOIN LOGO*/}
          {stateShowCryptoName && (
            <PositionedElement
              className="bitcoin-logo cursor"
              onClick={toggleCurrencyModal}
              top={calculateDimension(
                dimensions.bitcoinLogo.top,
                mode,
                wholeStore.format
              )}
              left={calculateDimension(
                dimensions.bitcoinLogo.left,
                mode,
                wholeStore.format
              )}
              width={calculateDimension(
                dimensions.bitcoinLogo.height,
                mode,
                wholeStore.format
              )}
              height={calculateDimension(
                dimensions.bitcoinLogo.width,
                mode,
                wholeStore.format
              )}
              accentColor
              color={theme === "dark" ? "white" : "black"}
              backgroundColor={theme === "dark" ? "white" : "black"}
              theme={wholeStore.theme}
              fSize={calculateDimension(
                dimensions.bitcoinLogo.fSize,
                mode,
                wholeStore.format,
                coinNameLength
              )}
            >
              {wholeStore.currency && wholeStore.currency.FullName != null
                ? wholeStore.currency.FullName
                : wholeStore.currency}
            </PositionedElement>
          )}

          {/* Chart Container */}
          <PositionedElement
            className="chart-container"
            top={calculateDimension(
              dimensions.chartContainer.top,
              mode,
              wholeStore.format
            )}
            right={0}
            // width={calculateDimension(dimensions.chartContainer.width)}
            width={"100%"}
            height={calculateDimension(
              dimensions.chartContainer.height * 1.6,
              mode,
              wholeStore.format
            )}
          >
            <Chart
              stateShowAvaragePrice={stateShowAvaragePrice}
              mode={mode}
              data={formattedData ? formattedData : []}
              width={
                !isMobile
                  ? calculateDimension(
                      dimensions.chartContainer.width * 1.015,
                      mode
                    )
                  : calculateDimension(
                      dimensions.chartContainer.width,
                      mode,
                      wholeStore.format
                    )
              }
              height={calculateDimension(
                dimensions.chartContainer.height,
                mode,
                wholeStore.format
              )}
              theme={wholeStore.theme}
              showPoster={showPoster}
            />
          </PositionedElement>

          {/* inside Chart Container - Chart*/}
          <PositionedElement
            className="chart"
            onClick={() => toggleChartPeriodModal()}
            top={calculateDimension(
              dimensions.chart.top,
              mode,
              wholeStore.format
            )}
            right={0}
            width={
              !isMobile
                ? calculateDimension(
                    dimensions.chartContainer.width * 1.05,
                    wholeStore.format
                  )
                : calculateDimension(
                    dimensions.chartContainer.width / 2,
                    mode,
                    wholeStore.format
                  )
            }
            height={calculateDimension(
              dimensions.chart.height,
              mode,
              wholeStore.format
            )}
          ></PositionedElement>

          <PositionedElement
            onClick={() => toggleStartInvestmentModal()}
            className={`first-investment ${
              wholeStore.showStartInvest ? "cursor" : ""
            }  ${mode !== undefined && "generate-page"}`}
            top={calculateDimension(
              dimensions.firstInvestmentBox.top,
              mode,
              wholeStore.format
            )}
            left={calculateDimension(
              dimensions.firstInvestmentBox.left,
              mode,
              wholeStore.format
            )}
            width={calculateDimension(
              dimensions.firstInvestmentBox.width,
              mode,
              wholeStore.format
            )}
            height={calculateDimension(
              dimensions.firstInvestmentBox.height,
              mode,
              wholeStore.format
            )}
          >
            {stateShowStartInvest && (
              <TextBlock
                className="date"
                fSize={calculateDimension(
                  dimensions.firstInvestmentBox
                    .firstInvestmentBoxFirstLineFSize,
                  mode,
                  wholeStore.format
                )}
                fWeight={"800"}
                fColor={wholeStore.theme === "dark" ? "white" : "black"}
              >
                {wholeStore.investStartDate &&
                  moment(wholeStore.investStartDate).format("DD MMM YYYY")}
              </TextBlock>
            )}

            {wholeStore.investStartDate && stateShowStartInvest && (
              <TextBlock
                fSize={calculateDimension(
                  dimensions.firstInvestmentBox
                    .secondInvestmentBoxFirstLineFSize,
                  mode,
                  wholeStore.format
                )}
                fWeight={"800"}
                fColor={wholeStore.theme === "dark" ? "white" : "black"}
              >
                INVESTED ON
              </TextBlock>
            )}
          </PositionedElement>

          {/* CRYPTO STATS */}
          {stateShowCryptoStats && (
            <>
              <PositionedElement
                className="low-price"
                top={calculateDimension(
                  dimensions.lowPriceBox.top,
                  mode,
                  wholeStore.format
                )}
                left={calculateDimension(
                  dimensions.lowPriceBox.left,
                  mode,
                  wholeStore.format
                )}
                width={calculateDimension(
                  dimensions.lowPriceBox.width,
                  mode,
                  wholeStore.format
                )}
                height={calculateDimension(
                  dimensions.lowPriceBox.height,
                  mode,
                  wholeStore.format
                )}
              >
                <TextBlock
                  className="price"
                  fSize={calculateDimension(
                    dimensions.lowPriceBox.lowPriceBoxPriceFSize,
                    mode,
                    wholeStore.format
                  )}
                  fWeight={"800"}
                  fColor={theme === "dark" ? "#ff9400" : "#ff9400"}
                >
                  $
                  {wholeStore.minPrice && wholeStore.minPrice.toFixed(2) == 0.0
                    ? wholeStore.minPrice.toFixed(6)
                    : wholeStore.minPrice > 1000
                    ? wholeStore.minPrice.toFixed(0)
                    : wholeStore.minPrice.toFixed(2)}
                </TextBlock>

                <TextBlock
                  fSize={calculateDimension(
                    dimensions.lowPriceBox.lowPriceBoxTextFSize,
                    mode,
                    wholeStore.format
                  )}
                  fWeight={"800"}
                  fColor={wholeStore.theme === "dark" ? "white" : "black"}
                >
                  LOW
                </TextBlock>
              </PositionedElement>

              <PositionedElement
                className="high-price"
                top={calculateDimension(
                  dimensions.highPriceBox.top,
                  mode,
                  wholeStore.format
                )}
                left={calculateDimension(
                  dimensions.highPriceBox.left,
                  mode,
                  wholeStore.format
                )}
                width={calculateDimension(
                  dimensions.highPriceBox.width,
                  mode,
                  wholeStore.format
                )}
                height={calculateDimension(
                  dimensions.highPriceBox.height,
                  mode,
                  wholeStore.format
                )}
              >
                <TextBlock
                  className="price"
                  fSize={calculateDimension(
                    dimensions.highPriceBox.lowPriceBoxPriceFSize,
                    mode,
                    wholeStore.format
                  )}
                  fWeight={"800"}
                  fColor={theme === "dark" ? "#ff9400" : "#ff9400"}
                >
                  $
                  {wholeStore.maxPrice && wholeStore.maxPrice.toFixed(2) == 0.0
                    ? wholeStore.maxPrice.toFixed(6)
                    : wholeStore.maxPrice > 1000
                    ? wholeStore.maxPrice.toFixed(0)
                    : wholeStore.maxPrice.toFixed(2)}
                </TextBlock>

                <TextBlock
                  fSize={calculateDimension(
                    dimensions.highPriceBox.lowPriceBoxTextFSize,
                    mode,
                    wholeStore.format
                  )}
                  fWeight={"800"}
                  fColor={wholeStore.theme === "dark" ? "white" : "black"}
                >
                  HIGH
                </TextBlock>
              </PositionedElement>

              {/* <PositionedElement
                className="change-mark"
                top={calculateDimension(
                  dimensions.changeBox.top,
                  mode,
                  wholeStore.format
                )}
                left={calculateDimension(
                  dimensions.changeBox.left,
                  mode,
                  wholeStore.format
                )}
                width={calculateDimension(
                  dimensions.changeMark.width,
                  mode,
                  wholeStore.format
                )}
                height={calculateDimension(
                  dimensions.changeMark.height,
                  mode,
                  wholeStore.format
                )}
              >

              </PositionedElement> */}

              <PositionedElement
                className="change-box"
                top={calculateDimension(
                  dimensions.changeBox.top,
                  mode,
                  wholeStore.format
                )}
                left={calculateDimension(
                  dimensions.changeBox.left,
                  mode,
                  wholeStore.format
                )}
                width={calculateDimension(
                  dimensions.changeBox.width,
                  mode,
                  wholeStore.format
                )}
                height={calculateDimension(
                  dimensions.changeBox.height,
                  mode,
                  wholeStore.format
                )}
              >
                <TextBlock
                  className="value flex"
                  fSize={calculateDimension(
                    dimensions.changeBox.lowPriceBoxPriceFSize,
                    mode,
                    wholeStore.format
                  )}
                  fWeight={"800"}
                  fColor={
                    theme === "dark"
                      ? wholeStore.accentColor.dark
                      : wholeStore.accentColor.light
                  }
                >
                  <ChangeMarkElement
                    version={
                      wholeStore.change.toFixed() >= 0 ? "plus" : "minus"
                    }
                    width={dimensions.changeMark.width}
                    height={dimensions.changeMark.height}
                  ></ChangeMarkElement>
                  {wholeStore.change.toFixed() <= 1 &&
                  wholeStore.change.toFixed() >= -1
                    ? wholeStore.change.toFixed(2)
                    : wholeStore.change.toFixed(0)}
                  %
                </TextBlock>

                <TextBlock
                  fSize={calculateDimension(
                    dimensions.changeBox.lowPriceBoxTextFSize,
                    mode,
                    wholeStore.format
                  )}
                  fWeight={"800"}
                  fColor={wholeStore.theme === "dark" ? "white" : "black"}
                >
                  {`${
                    wholeStore.chartPeriod !== "ALL"
                      ? wholeStore.chartPeriod + " CHANGE"
                      : "CHANGE"
                  }`}
                </TextBlock>
              </PositionedElement>
            </>
          )}

          {stateShowTradegraphy && (
            <PositionedElement
              className={`trademark-logo ${
                wholeStore.theme == "dark" ? "dark" : "light"
              }`}
              top={calculateDimension(
                dimensions.tradeGraphyLogo.top,
                mode,
                wholeStore.format
              )}
              left={calculateDimension(
                dimensions.tradeGraphyLogo.left,
                mode,
                wholeStore.format
              )}
              width={calculateDimension(
                dimensions.tradeGraphyLogo.width,
                mode,
                wholeStore.format
              )}
              height={calculateDimension(
                dimensions.tradeGraphyLogo.height,
                mode,
                wholeStore.format
              )}
            ></PositionedElement>
          )}

          {/* Quote mark */}
          {stateShowQuote && (
            <>
              <PositionedElement
                className="quote-mark"
                bgColor={wholeStore.accentColor[theme]}
                top={calculateDimension(
                  dimensions.quoteMark.top,
                  mode,
                  wholeStore.format
                )}
                left={calculateDimension(
                  dimensions.quoteMark.left,
                  mode,
                  wholeStore.format
                )}
                width={calculateDimension(
                  dimensions.quoteMark.width,
                  mode,
                  wholeStore.format
                )}
                height={calculateDimension(
                  dimensions.quoteMark.height,
                  mode,
                  wholeStore.format
                )}
              ></PositionedElement>

              {/* Quote box */}
              <PositionedElement
                className="quote-box cursor"
                onClick={() => toggleQuoteModal()}
                top={calculateDimension(
                  dimensions.quoteBox.top,
                  mode,
                  wholeStore.format
                )}
                right={calculateDimension(
                  dimensions.quoteBox.right,
                  mode,
                  wholeStore.format
                )}
                width={calculateDimension(
                  dimensions.quoteBox.width,
                  mode,
                  wholeStore.format
                )}
                height={calculateDimension(
                  dimensions.quoteBox.height,
                  mode,
                  wholeStore.format
                )}
              >
                <TextBlock
                  fSize={calculateDimension(
                    dimensions.quoteBox.fSize,
                    mode,
                    wholeStore.format
                  )}
                  fWeight={"900"}
                  fColor={wholeStore.theme === "dark" ? "white" : "black"}
                >
                  {quote.quote && quote.quote}
                </TextBlock>

                <TextBlock
                  className="quote-box-author"
                  display="block"
                  fSize={calculateDimension(
                    dimensions.quoteBoxAuthor.fSize,
                    mode,
                    wholeStore.format
                  )}
                  fWeight={"800"}
                  fColor={wholeStore.theme === "dark" ? "white" : "black"}
                >
                  {quote.userQuoteAuthorFirstLine &&
                    quote.userQuoteAuthorFirstLine}
                </TextBlock>
              </PositionedElement>
            </>
          )}
        </PosterLayer>
      </PosterContainer>
    </PosterBox>
  );
};

export default Poster;
