import React, { FC } from "react";
import { useHistory } from "react-router-dom";

import {
  SectionContainer,
  SectionHeader,
  SectionBackground,
  SectionParagraph,
  SectionButton,
} from "../style/MainPageStyle.js";
import section1Background from "../../../assets/section1Background.jpg";
import arrowDownIcon from "../../../assets/arrow-down.svg";

const Section1ChartPoster: FC = () => {
  let history = useHistory();

  const redirectToPosterCreator = () => {
    history.push("/kreator");
  };
  return (
    <SectionContainer
      className="section section1"
      gridRowStart={2}
      gridColumnStart={2}
      gridColumnEnd={3}
    >
      <SectionHeader className="section1-header-1" maxWidth={720}>
        Stwórz Twój plakat z wykresem wybranej kryptowaluty.
      </SectionHeader>
      <SectionParagraph
        maxWidth={690}
        mTop="37"
        className="first-section-paragraph"
      >
        Twoja kryptowalutowa inwestycja na plakacie. Cytat, który Cię motywuje
        lub dużo dla Ciebie znaczy. Zobacz możliwości kreatora plakatu i stwórz
        plakat w 3 minuty, a my go wydrukujemy.
      </SectionParagraph>
      <SectionButton
        onClick={redirectToPosterCreator}
        className="full-width"
        mTop="62"
      >
        Przejdź do kreatora
      </SectionButton>
      <SectionBackground
        className="section-1-img-1"
        src={section1Background}
        right={"0"}
      />
      <SectionButton
        image={arrowDownIcon}
        noBackground
        width="24.6"
        height="12.3"
        noPadding
        alignSelf="center"
        mTop="131"
      />
    </SectionContainer>
  );
};

export default Section1ChartPoster;
