import { FC, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { reactLocalStorage } from "reactjs-localstorage";
import { isMobile } from "react-device-detect";

// other components
import PosterPageMobileNav from "../PosterPage/PosterPageComponents/PosterPageMobileNav.jsx";
import NavigationHeader from "../MainPage/subComponents/NavigationHeader";
import PosterPageSteps from "../SettingsComponent/subComponents/PosterPageSteps.jsx";
// styles
import {
  Ccntainer,
  Box,
  Row,
  Text,
  FormatBubble,
  Divider,
  Column,
  FormatVisualization,
  Button,
  BackButton,
} from "./style/PosterSizesStepPageStyle";

import { setChosenSize } from "../../redux/actions";

// import {

// } from "../styles/SettingsStyles";

interface iChart {
  resultsAmount: number;
}
interface iLabel {
  dateFrom: null | string | any;
  dateTo: null | string | any;
}
interface iQuote {
  quote: string;
  userQuoteAuthorFirstLine: string;
}

interface iShowSettings {
  showAvaragePrice: boolean;
  showCryptoPrice: boolean;
  showDate: boolean;
  showLogo: boolean;
  showMarketCap: boolean;
  showMarketDominance: boolean;
  showMarketRank: boolean;
  showQuote: boolean;
  showSlogan: boolean;
}

interface iWholeStore {
  chart: iChart;
  currency: string;
  format: string;
  investStartDate: null | string | any;
  label: iLabel;
  mode: string;
  quote: iQuote;
  showCryptoStats: boolean;
  showSettings: iShowSettings;
  showStartInvest: boolean;
  showTradegraphy: boolean;
  theme: string;
}
interface PosterSizesStepPageProps {}

const PosterSizesStepPage: FC<PosterSizesStepPageProps> = () => {
  // defining app entire state
  const wholeStore: iWholeStore | any = useSelector((state) => state);
  const history = useHistory();
  const dispatch = useDispatch();

  const posterStorage: any = reactLocalStorage.getObject("var");

  //local state
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [selectedFormat, setSelectedFormat] = useState(
    wholeStore.selectedFormat
      ? wholeStore.selectedFormat
      : posterStorage.selectedFormat
      ? posterStorage.selectedFormat
      : "a1"
  );

  //usefull variables
  const formatDImensions: any = {
    a4: { width: 210, height: 297 },
    a3: { width: 297, height: 420 },
    a2: { width: 420, height: 594 },
    a1: { width: 594, height: 841 },
    a0: { width: 841, height: 1189 },
    b4: { width: 250, height: 353 },
    b3: { width: 250, height: 353 },
    b2: { width: 500, height: 707 },
    b1: { width: 707, height: 1000 },
    b0: { width: 1000, height: 1414 },
  };

  //useEffects

  useEffect(() => {
    if (selectedFormat) {
      switch (selectedFormat) {
        case "a4":
        case "b4":
          document.querySelector(".format-4")?.classList.add("active");
          break;
        case "a3":
        case "b3":
          document.querySelector(".format-3")?.classList.add("active");
          break;
        case "a2":
        case "b2":
          document.querySelector(".format-2")?.classList.add("active");
          break;
        case "a1":
        case "b1":
          document.querySelector(".format-1")?.classList.add("active");
          break;
        case "a0":
        case "b0":
          document.querySelector(".format-0")?.classList.add("active");
          break;
        default:
          break;
      }

      switch (selectedFormat) {
        case "a4":
          document.querySelector(".format-bubble-a4")?.classList.add("active");
          break;
        case "a3":
          document.querySelector(".format-bubble-a3")?.classList.add("active");
          break;
        case "a2":
          document.querySelector(".format-bubble-a2")?.classList.add("active");
          break;
        case "a1":
          document.querySelector(".format-bubble-a1")?.classList.add("active");
          break;
        case "a0":
          document.querySelector(".format-bubble-a0")?.classList.add("active");
          break;

        case "b4":
          document.querySelector(".format-bubble-b4")?.classList.add("active");
          break;

        case "b3":
          document.querySelector(".format-bubble-b3")?.classList.add("active");
          break;

        case "b2":
          document.querySelector(".format-bubble-b2")?.classList.add("active");
          break;

        case "b1":
          document.querySelector(".format-bubble-b1")?.classList.add("active");
          break;

        case "b0":
          document.querySelector(".format-bubble-b0")?.classList.add("active");
          break;
        default:
          break;
      }
    }
  }, [selectedFormat]);

  // useEffect(() => {
  //   if (wholeStore.chartData && wholeStore.chartData.length) {
  //     reactLocalStorage.setObject("var", wholeStore);
  //   }
  // }, [wholeStore]);

  //local functions
  const handleHamburgerClick = () => {
    setMenuOpen(!isMenuOpen);
  };

  const selectFormat = (format: string, e: any) => {
    setSelectedFormat(format);

    dispatch(setChosenSize(format));

    document
      .querySelectorAll(".format-visualization")
      .forEach((element: any) => {
        if (element.classList.contains("active")) {
          element.classList.remove("active");
        }
      });

    switch (format) {
      case "a4":
      case "b4":
        document.querySelector(".format-4")?.classList.add("active");
        break;
      case "a3":
      case "b3":
        document.querySelector(".format-3")?.classList.add("active");
        break;
      case "a2":
      case "b2":
        document.querySelector(".format-2")?.classList.add("active");
        break;
      case "a1":
      case "b1":
        document.querySelector(".format-1")?.classList.add("active");
        break;
      case "a0":
      case "b0":
        document.querySelector(".format-0")?.classList.add("active");
        break;
      default:
        break;
    }

    switch (format) {
      case "a4":
        document.querySelector(".format-bubble-a4")?.classList.add("active");
        break;
      case "a3":
        document.querySelector(".format-bubble-a3")?.classList.add("active");
        break;
      case "a2":
        document.querySelector(".format-bubble-a2")?.classList.add("active");
        break;
      case "a1":
        document.querySelector(".format-bubble-a1")?.classList.add("active");
        break;
      case "a0":
        document.querySelector(".format-bubble-a0")?.classList.add("active");
        break;

      case "b4":
        document.querySelector(".format-bubble-b4")?.classList.add("active");
        break;

      case "b3":
        document.querySelector(".format-bubble-b3")?.classList.add("active");
        break;

      case "b2":
        document.querySelector(".format-bubble-b2")?.classList.add("active");
        break;

      case "b1":
        document.querySelector(".format-bubble-b1")?.classList.add("active");
        break;

      case "b0":
        document.querySelector(".format-bubble-b0")?.classList.add("active");
        break;
      default:
        break;
    }

    if (selectedFormat !== format) {
      document.querySelectorAll(".format-bubble").forEach((element: any) => {
        if (element.classList.contains("active")) {
          element.classList.remove("active");
        }
      });
      e.target.classList.add("active");
    }
  };

  return (
    <>
      <Ccntainer className="sizes-page__container">
        <PosterPageMobileNav
          theme={"light"}
          handleHamburgerClick={handleHamburgerClick}
        />
        <NavigationHeader
          isMenuOpen={isMenuOpen}
          handleHamburgerClick={handleHamburgerClick}
          position={"fixed"}
          theme={"light"}
        />
        {/* left box */}
        <Box>
          <PosterPageSteps mode="step-mode" theme={"light"} activeStep={2} />
          <Row order={1} margin={"15% 0 0 0"}>
            <Text fSize={"1.5rem"} fWeight={600}>
              Wybierz rozmiar plakatu
            </Text>
          </Row>

          <Row order={2} margin={"5% 0 5% 0"}>
            <Row justify={"space-between"}>
              <Text>Format A</Text>
              <FormatBubble
                onClick={(e: any) => selectFormat("a4", e)}
                className="format-bubble format-bubble-a4"
              >
                A4
              </FormatBubble>
              <FormatBubble
                onClick={(e: any) => selectFormat("a3", e)}
                className="format-bubble format-bubble-a3"
              >
                A3
              </FormatBubble>
              <FormatBubble
                onClick={(e: any) => selectFormat("a2", e)}
                className="format-bubble format-bubble-a2"
              >
                A2
              </FormatBubble>
              <FormatBubble
                onClick={(e: any) => selectFormat("a1", e)}
                className="format-bubble format-bubble-a1"
              >
                A1
              </FormatBubble>
              <FormatBubble
                onClick={(e: any) => selectFormat("a0", e)}
                className="format-bubble format-bubble-a0"
              >
                A0
              </FormatBubble>
            </Row>
          </Row>

          <Divider />

          {!isMobile && (
            <>
              <Row order={isMobile ? 4 : 3}>
                <Column
                  margin={"0 0 0 0%"}
                  width={"100%"}
                  padding={isMobile ? "0 0 0 0%" : "0 0 0 0%"}
                >
                  <Row padding={"5% 0"} justify="flex-end">
                    <Text fSize={".9rem"} fWeight={600}>
                      Cena:
                    </Text>
                    <Text fSize={"1.7rem"} fWeight={600} margin={"0 0 0 10%"}>
                      {`${
                        selectedFormat &&
                        wholeStore.formatPrices[selectedFormat]
                      } zł`}
                    </Text>
                  </Row>
                  <Row justify="flex-end" margin="-5% 0 2% 0">
                    <Text fWeight="400">Darmowa dostawa!</Text>
                  </Row>
                </Column>
              </Row>
              <Divider width={"100%"} />
              <Row order={isMobile ? 5 : 4}>
                <Row margin={"3% 0 0 0"} justify={"space-between"}>
                  <BackButton
                    className="align-start"
                    onClick={() => history.goBack()}
                  >
                    Wróć
                  </BackButton>
                  <Button onClick={() => history.push("/podsumowanie")}>
                    Przejdź dalej
                  </Button>
                </Row>
              </Row>
            </>
          )}

          {/* <Row margin={"6% 0 0 0"}>
            <Row justify={"space-between"}>
              <Text>Format B</Text>
              <FormatBubble
                onClick={(e: any) => selectFormat("b4", e)}
                className="format-bubble format-bubble-b4"
              >
                B4
              </FormatBubble>
              <FormatBubble
                onClick={(e: any) => selectFormat("b3", e)}
                className="format-bubble format-bubble-b3"
              >
                B3
              </FormatBubble>
              <FormatBubble
                onClick={(e: any) => selectFormat("b2", e)}
                className="format-bubble format-bubble-b2"
              >
                B2
              </FormatBubble>
              <FormatBubble
                onClick={(e: any) => selectFormat("b1", e)}
                className="format-bubble format-bubble-b1"
              >
                B1
              </FormatBubble>
              <FormatBubble
                onClick={(e: any) => selectFormat("b0", e)}
                className="format-bubble format-bubble-b0"
              >
                B0
              </FormatBubble>
            </Row>
          </Row> */}
        </Box>

        {/* right box */}
        <Box alignSelf={"end"} padding={"0 0 0 15%"}>
          <Row>
            <Column margin={"10vh 0 0 0"} align={"flex-start"}>
              <FormatVisualization className="format-visualization format-0 format-visualization-first">
                {(selectedFormat === "a0" || selectedFormat === "b0") && (
                  <Text fWeight={600} zIndex={"3"} color={"#008cff"}>
                    {selectedFormat.toUpperCase()}
                  </Text>
                )}
                <FormatVisualization className="format-visualization format-1 format-visualization-second">
                  {(selectedFormat === "a1" || selectedFormat === "b1") && (
                    <Text fWeight={600} color={"#008cff"}>
                      {selectedFormat.toUpperCase()}
                    </Text>
                  )}
                </FormatVisualization>
                <FormatVisualization className="format-visualization format-2 format-visualization-third">
                  {(selectedFormat === "a2" || selectedFormat === "b2") && (
                    <Text fWeight={600} color={"#008cff"}>
                      {selectedFormat.toUpperCase()}
                    </Text>
                  )}
                </FormatVisualization>
                <FormatVisualization className="format-visualization format-3 format-visualization-fourth">
                  {(selectedFormat === "a3" || selectedFormat === "b3") && (
                    <Text fWeight={600} color={"#008cff"}>
                      {selectedFormat.toUpperCase()}
                    </Text>
                  )}
                </FormatVisualization>
                <FormatVisualization className="format-visualization format-4 format-visualization-fifth">
                  {(selectedFormat === "a4" || selectedFormat === "b4") && (
                    <Text fWeight={600} color={"#008cff"}>
                      {selectedFormat.toUpperCase()}
                    </Text>
                  )}
                </FormatVisualization>
              </FormatVisualization>
            </Column>
            <Column margin={"0 0 0 10%"} alignSelf={"flex-end"}>
              <Row>
                <Text fWeight={"bold"}>Wymiary:</Text>
              </Row>
              <Row margin={"25% 0 0 0"}>
                <Text fWeight={400} fSize={"0.85rem"}>
                  {`szerokość: ${
                    selectedFormat && formatDImensions[selectedFormat].width
                  } mm`}
                </Text>
              </Row>
              <Row>
                <Text fWeight={400} fSize={"0.85rem"}>
                  {`wysokość: ${
                    selectedFormat && formatDImensions[selectedFormat].height
                  } mm`}
                </Text>
              </Row>
            </Column>
          </Row>

          <Row margin={"10% 0 0 0"}>
            <Text fSize={"1.3rem"} fWeight={"600"}>
              {`Formaty ${selectedFormat.includes("a") ? "A" : "B"}`}{" "}
            </Text>
          </Row>
        </Box>

        {isMobile && (
          <>
            <Divider margin={"3% 0 0 0"} width={"94%"} />
            <Column
              margin={"0 0 0 65%"}
              width={"50%"}
              padding={isMobile ? "0 0 0 0%" : "0 0 0 15%"}
            >
              <Row padding={"5% 0"}>
                <Text fSize={".9rem"} fWeight={600}>
                  Cena:
                </Text>
                <Text fSize={"1.7rem"} fWeight={600} margin={"0 0 0 10%"}>
                  {`${
                    selectedFormat && wholeStore.formatPrices[selectedFormat]
                  } zł`}
                </Text>
              </Row>
              <Row justify="flex-end" margin="-6% 40% 10% 0" width={"120%"}>
                <Text fWeight="400">Darmowa dostawa!</Text>
              </Row>
            </Column>
            <Divider width={"94%"} />
            <Row padding="0 3%" margin={"3% 0 0 0"} justify={"space-between"}>
              <BackButton onClick={() => history.goBack()}>Wróć</BackButton>
              <Button onClick={() => history.push("/podsumowanie")}>
                Przejdź dalej
              </Button>
            </Row>
          </>
        )}
      </Ccntainer>
    </>
  );
};

export default PosterSizesStepPage;
