import styled from "styled-components";

import { XYPlot } from "react-vis";

import dotsFixedBlack from "../../../assets/dots_fixed.svg";
import dotsWhiteTheme from "../../../assets/dots-white-theme.svg";
import dotsBlackTheme from "../../../assets/dots-black-theme.svg";

export const ChartContainer = styled.div`
  width: 50%;
  height: 80vh;
  background-color: pink;
`;

export const ChartBackground = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0%;
  background-image: url(${(props) =>
    props.theme === "light" ? dotsWhiteTheme : dotsBlackTheme});
  background-size: cover;
  background-repeat: no-repeat;
  left: 0;
  overflow: hidden;
  background-position-y: 70%;
`;

export const ChartMainContainer = styled.div`
  position: relative;
  overflow: hidden;
  height: 50vh;
  width: 100%;
  left: 0%;

  cursor: pointer;
  z-index: 223;
`;

export const ChartBottomFadeInBlock = styled.div`
  width: 100%;
  height: ${(props) => props.posterHeight && props.posterHeight * 0.045 + "px"};
  position: absolute;
  bottom: -6px;
  left: 0;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 78%
  );
  z-index: 23;
`;

export const ChartLeftChartBlanket = styled.div`
  width: ${(props) => props.posterWidth && props.posterWidth * 0.4 + "px"};
  height: ${(props) => props.posterHeight && props.posterHeight + "px"};
  position: absolute;
  bottom: 0;
  left: 0;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    270deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.8872163865546218) 37%
  );
`;

export const ChartTopFixBlanket = styled.div`
  width: 100%;
  height: ${(props) => props.posterWidth && props.posterWidth * 0.055 + "px"};
  position: absolute;
  top: ${(props) => props.posterWidth && -props.posterWidth * 0.01 + "px"};

  background-color: ${(props) => (props.theme === "light" ? "white" : "black")};
`;

export const BlankWHiteFiller = styled.div`
  position: absolute;
  width: 7%;
  height: ${(props) => (props.height ? props.height + "px" : "unset")};
  background-color: ${(props) => (props.theme === "light" ? "white" : "black")};
  z-index: 2;
  top: 0;
  left: 0;

  @media (max-width: 768px) {
    width: 16%;
  }

  @media (min-width: 769px) and (max-width: 950px) {
    width: 16%;
  }

  @media (min-width: 951px) and (max-width: 1100px) {
    width: 16%;
  }
  @media (min-width: 1101px) and (max-width: 1366px) {
    width: 14%;
  }

  @media (min-width: 1366px) and (max-width: 1480px) {
    width: 11.7%;
  }
  @media (min-width: 1481px) and (max-width: 1650px) {
    width: 10.2%;
  }

  @media (min-width: 1651px) and (max-width: 2000px) {
    width: 8.5%;
  }
  @media (min-width: 2001px) and (max-width: 2600px) {
    width: 6.5%;
  }
  @media (min-width: 2601px) {
    width: 6%;
  }

  &:after {
    content: "";
    position: absolute;
    bottom: ${(props) =>
      props.posterWidth && -(props.posterWidth * 0.015) + "px"};
    left: 0;
    width: 0;
    height: 0;
    border-top: ${(props) =>
        props.posterWidth && props.posterWidth * 0.015 + "px"}
      solid ${(props) => (props.theme === "light" ? "white" : "black")};
    border-right: ${(props) =>
        props.posterWidth && props.posterWidth * 0.05 + "px"}
      solid transparent;
  }
`;

export const ChartAvarageDataLine = styled.div`
  position: absolute;
  /* top: ${(props) =>
    props.posterHeight && props.posterHeight / 2 - 3 + "px"}; */
  width: ${(props) => props.posterWidth && props.posterWidth + "px"};
  height: 1px;
  /* background-color: white; */
  border-top: ${(props) =>
    props.theme == "dark"
      ? "1px dashed rgba(255, 255, 255, 1)"
      : "1px dashed rgba(0, 0, 0, 0.6);"};
  /* border-top: 2px dashed rgba(0, 0, 0, 0.06); */
  left: -10%;
  z-index: 22;
  width: 120%;

  &.generate-page {
    border-top: 2px dashed rgba(255, 255, 255, 1);
  }
`;

export const AvaragePriceBox = styled.div`
  position: absolute;
  top: ${(props) => props.height && props.height / 14.5}px;
  /* left: ${(props) => props.chartLeftPoint && props.chartLeftPoint}px; */
  border-top: 1px solid
    ${(props) =>
      props.theme === "light"
        ? "rgba(0, 0, 0, 0.2)"
        : "rgba(255, 255, 255, 0.7)"};
  border-left: 1px solid
    ${(props) =>
      props.theme === "light"
        ? "rgba(0, 0, 0, 0.2)"
        : "rgba(255, 255, 255, 0.7)"};
  height: ${(props) => props.height && props.height - 5}px;
  width: ${(props) => props.width && props.width / 10}px;
  z-index: 28;

  .avarage-price-box__child1,
  .avarage-price-box__child2 {
    width: 200%;
    position: relative;
    left: 30%;
    line-height: 1.1rem;
    font-size: 0.45vw;
    font-weight: bolder;
    text-transform: uppercase;
    width: max-content;
    color: ${(props) => (props.theme === "light" ? "black" : "white")};

    @media (max-width: 768px) {
      font-size: 1.15vw;
    }

    &.generate {
      font-size: 1.3vw;
    }
  }
  .avarage-price-box__child1 {
    bottom: 15px;
    &.generate {
      bottom: 20px;
    }
  }
  .avarage-price-box__child2 {
    bottom: 20px;
    &.generate {
      bottom: 15px;
    }
  }

  &.generate-page {
    top: ${(props) => props.height && props.height / 15 - 10}px;

    &:after,
    &:before {
      font-size: 0.8rem;
    }

    &:after {
      top: -20px;
    }

    &:before {
      top: 2px;
    }
  }

  /* &:after {
    content: "13.APR 2021";
    position: absolute;
    top: -18px;
    font-weight: 800;
    left: 2px;
    line-height: 1.1rem;
    font-size: 0.5rem;
    width: max-content;
    color: ${(props) => (props.theme === "light" ? "black" : "white")};
  }
  &:before {
    content: "13.APR 2021";
    position: absolute;
    top: 0px;
    left: 2px;
    font-weight: 800;
    line-height: 1.1rem;
    font-size: 0.5rem;
    width: max-content;
    color: ${(props) => (props.theme === "light" ? "black" : "white")};
  } */
`;

export const AvaragePriceBall = styled.div`
  position: absolute;
  top: ${(props) => props.ballHeight / 2 - 13}px;
  /* left: 8%; */
  border-radius: 50%;
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  font-size: ${(props) => props.fSize}px;
  background-color: ${(props) => (props.bgColor ? props.bgColor : "#ff9400")};
  z-index: 28;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  /* font-size: 0.6rem; */
  color: white;

  @media (max-width: 768px) {
    top: ${(props) => props.ballHeight / 2 - 4}px;
  }

  @media (min-width: 769px) and (max-width: 950px) {
    /* font-size: 0.6rem; */
    top: ${(props) => props.ballHeight / 2 - 2}px;
  }

  @media (min-width: 951px) and (max-width: 1100px) {
    /* font-size: 0.3rem; */
    top: ${(props) => props.ballHeight / 2 - 4}px;
  }
  @media (min-width: 1101px) and (max-width: 1365px) {
    /* font-size: 0.35rem; */
    top: ${(props) => props.ballHeight / 2 - 4}px;
  }

  @media (min-width: 1366px) and (max-width: 1480px) {
    /* font-size: 0.4rem; */
    top: ${(props) => props.ballHeight / 2 - 6}px;
  }
  @media (min-width: 1481px) and (max-width: 1650px) {
    /* font-size: 0.4rem; */
    top: ${(props) => props.ballHeight / 2 - 8}px;
  }

  @media (min-width: 1651px) and (max-width: 2000px) {
    /* font-size: 0.5rem; */
    top: ${(props) => props.ballHeight / 2 - 14}px;
  }
  @media (min-width: 2001px) and (max-width: 2600px) {
    /* font-size: 0.6rem; */
  }
  @media (min-width: 2601px) {
    /* font-size: 0.7rem; */
  }
`;

export const ChartStartInvestContainer = styled.div`
  width: 35%;
  height: 150px;
  border: 1px solid white;
  position: absolute;
`;

export const XYPLOT = styled(XYPlot)`
  position: relative;
  /* a3 generate-18% */
  right: -20%;
  height: 30vh !important;

  &.generate-page {
    left: 27%;
  }

  .chartInvestContainer {
    /* border-left: 1px solid white; */
    position: absolute;
    width: 20%;
    height: 0%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    z-index: 2242;

    &.dark-theme {
      border-bottom: 1px solid white;
      z-index: 2242;
    }

    &.light-theme {
      border-bottom: 1px solid black;
      z-index: 2242;
    }

    .first-investment-date-container {
      font-size: 1.3rem;
      font-weight: bold;
      color: #ff9400;

      &.center {
        left: -75%;
        position: absolute;
        text-align: center;
        bottom: -20%;
        width: 150%;
      }

      &.right {
        position: absolute;
        text-align: center;
        bottom: 5%;
        right: 25%;
        width: 150%;
      }

      &.left {
        position: absolute;
        text-align: center;
        bottom: 5%;
        left: 25%;
        width: 150%;
      }
    }

    .first-investment-text-container {
      font-size: 1rem;
      font-weight: bold;
      color: white;

      &.center {
        left: -100%;
        position: absolute;
        text-align: center;
        bottom: -35%;
        width: 200%;
      }
      &.right {
        position: absolute;
        text-align: center;
        bottom: -25%;
        width: 200%;
        right: 0%;
      }

      &.left {
        position: absolute;
        text-align: center;
        bottom: -25%;
        width: 200%;
        left: 0%;
      }
    }

    &.border-right {
      &.dark-theme {
        border-right: 1px solid white;

        &:before {
          content: "";
          position: absolute;
          top: -0.55vw;
          right: -0.25vw;
          width: 0.6vw;
          height: 0.6vw;
          border-radius: 50%;
          background-color: black;
          border: 2px solid #ff9400;

          @media only screen and (max-width: 700px) {
            width: 1.49vh;
            height: 1.49vh;
            top: -1.55vh;
            right: -0.8vh;
          }

          @media screen and (max-width: 1400px) {
            width: 0.8vw;
            height: 0.8vw;
            top: -0.9vw;
            right: -0.4vw;
          }

          @media only screen and (max-width: 1500px) {
            width: 0.7vw;
            height: 0.7vw;
            top: -0.8vw;
            right: -0.35vw;
          }

          @media only screen and (max-width: 2000px) {
            width: 0.6vw;
            height: 0.6vw;
            top: -0.6vw;
            right: -0.3vw;
          }
        }
      }

      &.light-theme {
        border-right: 1px solid black;

        &:before {
          content: "";
          position: absolute;
          top: -0.55vw;
          right: -0.25vw;
          width: 0.49vw;
          height: 0.49vw;
          border-radius: 50%;
          background-color: #000000;
          border: 2px solid #ff9400;

          @media only screen and (max-width: 700px) {
            width: 1.49vh;
            height: 1.49vh;
            top: -1.55vh;
            right: -0.8vh;
          }
        }
      }
    }

    &.border-left {
      &.dark-theme {
        border-left: 1px solid white;
        &:before {
          content: "";
          position: absolute;
          top: -0.55vw;
          left: -0.25vw;
          width: 0.49vw;
          height: 0.49vw;
          border-radius: 50%;
          background-color: black;
          border: 2px solid #ff9400;

          @media only screen and (max-width: 700px) {
            width: 1.49vh;
            height: 1.49vh;
            top: -1.55vh;
            right: -0.8vh;
          }

          @media screen and (max-width: 1400px) {
            width: 0.8vw;
            height: 0.8vw;
            top: -0.9vw;
            right: -0.4vw;
          }

          @media only screen and (max-width: 1500px) {
            width: 0.7vw;
            height: 0.7vw;
            top: -0.8vw;
            right: -0.35vw;
          }

          @media only screen and (max-width: 2000px) {
            width: 0.6vw;
            height: 0.6vw;
            top: -0.6vw;
            right: -0.3vw;
          }
        }
      }

      &.light-theme {
        border-left: 1px solid black;
        &:before {
          content: "";
          position: absolute;
          top: -0.55vw;
          left: -0.25vw;
          width: 0.49vw;
          height: 0.49vw;
          border-radius: 50%;
          background-color: black;
          border: 2px solid #ff9400;

          @media only screen and (max-width: 700px) {
            width: 1.49vh;
            height: 1.49vh;
            top: -1.55vh;
            left: -0.8vh;
          }
        }
      }
    }

    &.no-border {
      border-left: 1px solid white;
      border-bottom: none;

      &:before {
        content: "";
        position: absolute;
        top: -22px;
        left: -10px;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: black;
        border: 4px solid #ff9400;
      }
    }
  }
`;
