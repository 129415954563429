import React, { FC, useState, useEffect } from "react";
import { isMobile } from "react-device-detect";
import { reactLocalStorage } from "reactjs-localstorage";
import cookie from "react-cookies";
import Modal from "react-modal";
import { useSelector, useDispatch } from "react-redux";

import { useLocation } from "react-router-dom";

import NavigationHeaderContainer from "./subComponents/NavigationHeader";
import Section1ChartPoster from "./subComponents/Section1ChartPoster";
import Section2CreatorProcess from "./subComponents/Section2CreatorProcess";
import Section3About from "./subComponents/Section3About";
import Section4PurchaseProcess from "./subComponents/Section4PurchaseProcess";
import Section5Testimonials from "./subComponents/Section5Testimonials";
import Section6Mods from "./subComponents/Section6Mods";
import Footer from "./subComponents/Footer";
import { FreeShippingBanner } from "../../utils/atoms";

import CartIcon from "../../assets/cart-icon.svg";
import CartWhite from "../../assets/cart-icon-white.svg";
import {
  PageContainer,
  HamburgerIcon,
  HeaderNavButton,
  HeaderMobileLogoAndButtonContainer,
  HeaderCartContainer,
  MainPageBox,
} from "./style/MainPageStyle";
import history from "../../history";
import GeneratePosterModal from "../SettingsComponent/subComponents/GeneratePosterModalContent";
import { GiReloadGunBarrel } from "react-icons/gi";
import { changeChartTheme } from "../../redux/actions";

const MainPage: FC = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isAndroidWebView, setAndroidWebView] = useState(false);
  const dispatch = useDispatch();
  const wholeStore: any = useSelector((state) => state);
  let location = useLocation();

  const handleHamburgerClick = () => {
    setMenuOpen(!isMenuOpen);
  };

  const closeGeneratePostarModal = () => {
    cookie.save("userClosedGeneratePostarModal", "true", {
      path: "/",
      maxAge: 24 * 60 * 60 * 365,
    });
    setModalOpen(false);
  };

  useEffect(() => {
    // if (navigator.userAgent.includes("wv")) {
    //   setAndroidWebView(true);
    // } else {
    //   //Not a webview
    // }

    if (location.reload) {
      window.location.reload();
    }
    Modal.setAppElement("#root");
    const chartData: any = localStorage.getItem("var");
    const parsedChartData = JSON.parse(chartData);

    if (chartData && parsedChartData.chartData == null) {
      reactLocalStorage.remove("var");
    }

    setTimeout(() => {
      if (!cookie.load("userClosedGeneratePostarModal")) {
        setModalOpen(true);
      }
    }, 4000);
  }, []);

  const selectPoster = (mode: string) => {
    cookie.save("userClosedGeneratePostarModal", "true", {
      path: "/",
      maxAge: 24 * 60 * 60 * 365,
    });
    if (mode === "light") {
      dispatch(changeChartTheme("light"));
      history.push("/kreator");
    } else {
      dispatch(changeChartTheme("dark"));
      history.push("/kreator");
    }
  };

  const customStyles = {
    content: {
      left: "15vw",
      top: "15vh",
      zIndex: "223",
      border: "none",
      backgroundColor: "black",
      overflow: "auto",
    },
    overlay: {
      backgroundColor: "rgba(0,0,0,0.85)",
      zIndex: "222222",
    },
  };

  return (
    <MainPageBox MainPageBox>
      <PageContainer>
        <FreeShippingBanner className="shipping-banner">
          Bezpłatna dostawa każdego plakatu przez cały kwiecień!
        </FreeShippingBanner>
        <HeaderMobileLogoAndButtonContainer
          width={"100vw"}
          justify="space-between"
          className="mobile-nav"
        >
          <HamburgerIcon onClick={handleHamburgerClick} />
          {isMobile && (
            <HeaderCartContainer>
              <HeaderNavButton
                className="mobile-cart-icon "
                width={"35px"}
                height={"35px"}
                image={
                  wholeStore.theme == "dark" && window.location.pathname !== "/"
                    ? CartWhite
                    : CartIcon
                }
                marginRight={"unset"}
                margin={"unset"}
                onClick={() => history.push("/podsumowanie")}
              ></HeaderNavButton>
              {cookie.load("userEnteredCheckout") &&
                localStorage.getItem("var") && (
                  <div className="cart-items">1</div>
                )}
            </HeaderCartContainer>
          )}
        </HeaderMobileLogoAndButtonContainer>

        <NavigationHeaderContainer
          isMenuOpen={isMenuOpen}
          handleHamburgerClick={handleHamburgerClick}
          position={""}
          theme={""}
        />
        <Section1ChartPoster />
        <Section2CreatorProcess />
        <Section3About />
        <Section4PurchaseProcess />
        {/* <Section5Testimonials /> */}
        <Section6Mods />
        <Modal
          className="mainpage-modal"
          closeTimeoutMS={500}
          isOpen={isModalOpen}
          // onAfterOpen={afterOpenModal}
          onRequestClose={() => setModalOpen(false)}
          style={customStyles}
        >
          <GeneratePosterModal
            selectPoster={selectPoster}
            closeAction={closeGeneratePostarModal}
          />
        </Modal>
      </PageContainer>
      <Footer />
    </MainPageBox>
  );
};

export default MainPage;
