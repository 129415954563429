import { reactLocalStorage } from "reactjs-localstorage";
import {
  CHANGE_FORMAT,
  CHANGE_MODE,
  CHANGE_CHART_RESULTS_QUANTITY,
  CHANGE_CHART_THEME,
  CHANGE_MAIN_HEADER,
  CHANGE_SUB_HEADER,
  CHANGE_PARAGRAPH,
  CHANGE_SHOW_LOGO,
  CHANGE_SHOW_DATE,
  CHANGE_SHOW_CRYPTO_PRICE,
  CHANGE_SHOW_MARKET_DOMINANCE,
  CHANGE_SHOW_MARKET_CAP,
  CHANGE_SHOW_SLOGAN,
  CHANGE_SHOW_QUOTE,
  CHANGE_SHOW_MARKET_RANK,
  CHOOSE_SLOGAN,
  CHOOSE_QUOTE,
  CHANGE_PERIOD_LABEL,
  CHANGE_START_INVEST_DATE,
  CHANGE_CURRENCY,
  SHOW_AVARAGE_PRICE,
  SHOW_CRYPTO_STATS,
  SHOW_START_INVEST,
  SHOW_TRADEGRAPHY_LOGO,
  SET_CHART_DATA,
  SET_CHOSEN_SIZE,
  CHANGE_MAIN_COLOR_LIGH_THEME,
  CHANGE_MAIN_COLOR_DARK_THEME,
  SET_CURRENCIES,
  CHANGE_CHART_PERIOD,
  SET_MAX_PRICE,
  SET_MIN_PRICE,
  SET_CHANGE,
  SET_AVARAGE_PRICE,
  CHANGE_ACCENT_COLOR,
  CHANGE_USER_QUOTE,
  CHANGE_GALLERY_QUOTE,
  CHOOSE_QUOTE_ARR,
} from "./redux/actionTypes";

const initialState = {
  chart: {
    resultsAmount: 100,
  },
  formatPrices: {
    a4: 59,
    a3: 79,
    a2: 119,
    a1: 139,
    a0: 159,
  },
  selectedFormat: Object.keys(reactLocalStorage.getObject("var")).length
    ? reactLocalStorage.getObject("var").selectedFormat
    : "a1",
  allCurrencies: null,
  chartData: null,
  format: "a1",
  chosenSize: "a1",
  mode: "",
  theme: "dark",
  currency: "Bitcoin",
  chartPeriod: "1Y",
  chosenSlogan: "DOLOR",
  mainColorLightTheme: "#ff9400",
  mainColorDarkTheme: "#ffffff",
  showCryptoStats: true,
  showStartInvest: true,
  showTradegraphy: true,
  minPrice: 0,
  maxPrice: 0,
  change: 0,
  avaragePrice: 0,
  quoteArr: Object.keys(reactLocalStorage.getObject("var")).length
    ? reactLocalStorage.getObject("var").quoteArr
    : [0, 1],
  quote: {
    quote: Object.keys(reactLocalStorage.getObject("var")).length
      ? reactLocalStorage.getObject("var").quote.quote
      : "Earn as much as you can, save as much as you can, invest as much as you can, give as much as you can.",
    userQuoteAuthorFirstLine: Object.keys(reactLocalStorage.getObject("var"))
      .length
      ? reactLocalStorage.getObject("var").quote.userQuoteAuthorFirstLine
      : "John Wesley",
  },
  userQuote: {
    quote: Object.keys(reactLocalStorage.getObject("var")).length
      ? reactLocalStorage.getObject("var").userQuote.quote
      : "",
    userQuoteAuthorFirstLine: Object.keys(reactLocalStorage.getObject("var"))
      .length
      ? reactLocalStorage.getObject("var").userQuote.userQuoteAuthorFirstLine
      : "",
  },
  galleryQuote: {
    quote: Object.keys(reactLocalStorage.getObject("var")).length
      ? reactLocalStorage.getObject("var").quote.quote
      : "Earn as much as you can, save as much as you can, invest as much as you can, give as much as you can.",
    userQuoteAuthorFirstLine: Object.keys(reactLocalStorage.getObject("var"))
      .length
      ? reactLocalStorage.getObject("var").quote.userQuoteAuthorFirstLine
      : "John Wesley",
  },
  investStartDate: Object.keys(reactLocalStorage.getObject("var")).length
    ? reactLocalStorage.getObject("var").investStartDate
    : null,
  showSettings: {
    showLogo: true,
    showDate: true,
    showCryptoPrice: true,
    showMarketDominance: true,
    showMarketCap: true,
    showQuote: true,
    showSlogan: true,
    showMarketRank: true,
    showAvaragePrice: true,
  },
  label: {
    dateFrom: new Date(Date.now() - 2000 * 24 * 60 * 60 * 1000),
    dateTo: new Date(),
  },
  accentColor: {
    dark: "white",
    light: "black",
  },
};

export default function appReducer(state = initialState, action) {
  switch (action.type) {
    case CHANGE_FORMAT:
      return {
        ...state,
        format: action.payload.format,
      };

    case CHANGE_CURRENCY:
      return {
        ...state,
        currency: action.payload.currency,
      };

    case CHANGE_MODE:
      return {
        ...state,
        mode: action.payload.mode,
      };

    case CHANGE_CHART_RESULTS_QUANTITY:
      return {
        ...state,
        chart: {
          resultsAmount: action.payload.resultsAmount,
        },
      };

    case CHANGE_CHART_THEME:
      return {
        ...state,
        theme: action.payload.theme,
      };

    case CHANGE_MAIN_HEADER:
      return {
        ...state,
        mainHeaderText: action.payload.text,
      };

    case CHANGE_SUB_HEADER:
      return {
        ...state,
        subHeaderText: action.payload.text,
      };

    case CHANGE_PARAGRAPH:
      return {
        ...state,
        paragraphText: action.payload.text,
      };

    case CHANGE_SHOW_LOGO:
      return {
        ...state,
        showSettings: {
          ...state.showSettings,
          showLogo: action.payload.showLogo,
        },
      };

    case SHOW_AVARAGE_PRICE:
      return {
        ...state,
        showSettings: {
          ...state.showSettings,
          showAvaragePrice: action.payload.showAvaragePrice,
        },
      };

    case CHANGE_SHOW_DATE:
      return {
        ...state,
        showSettings: {
          ...state.showSettings,
          showDate: action.payload.showDate,
        },
      };

    case CHANGE_SHOW_CRYPTO_PRICE:
      return {
        ...state,
        showSettings: {
          ...state.showSettings,
          showCryptoPrice: action.payload.showCryptoPrice,
        },
      };

    case CHANGE_SHOW_MARKET_DOMINANCE:
      return {
        ...state,
        showSettings: {
          ...state.showSettings,
          showMarketDominance: action.payload.showMarketDominance,
        },
      };

    case CHANGE_SHOW_MARKET_CAP:
      return {
        ...state,
        showSettings: {
          ...state.showSettings,
          showMarketCap: action.payload.showMarketCap,
        },
      };

    case CHANGE_SHOW_SLOGAN:
      return {
        ...state,
        showSettings: {
          ...state.showSettings,
          showSlogan: action.payload.showSlogan,
        },
      };

    case CHANGE_SHOW_QUOTE:
      return {
        ...state,
        showSettings: {
          ...state.showSettings,
          showQuote: action.payload.showQuote,
        },
      };

    case CHANGE_SHOW_MARKET_RANK:
      return {
        ...state,
        showSettings: {
          ...state.showSettings,
          showMarketRank: action.payload.showMarketRank,
        },
      };

    case CHOOSE_SLOGAN:
      return {
        ...state,
        chosenSlogan: action.payload.slogan,
      };

    case CHOOSE_QUOTE_ARR:
      return {
        ...state,
        quoteArr: action.payload.quoteArr,
      };
    case CHOOSE_QUOTE:
      return {
        ...state,
        quote: action.payload.quote,
      };

    case CHANGE_PERIOD_LABEL:
      return {
        ...state,
        label: action.payload.label,
      };

    case CHANGE_START_INVEST_DATE:
      return {
        ...state,
        investStartDate: action.payload.date,
      };

    case SHOW_CRYPTO_STATS:
      return {
        ...state,
        showCryptoStats: action.payload.showCryptoStats,
      };

    case SHOW_START_INVEST:
      return {
        ...state,
        showStartInvest: action.payload.showStartInvest,
      };

    case SHOW_TRADEGRAPHY_LOGO:
      return {
        ...state,
        showTradegraphy: action.payload.showTradegraphy,
      };

    case SET_CHART_DATA:
      return {
        ...state,
        chartData: action.payload.chartData,
      };
    case SET_CHOSEN_SIZE: {
      return {
        ...state,
        selectedFormat: action.payload.selectedFormat,
      };
    }
    case CHANGE_MAIN_COLOR_LIGH_THEME: {
      return {
        ...state,
        mainColorLightTheme: action.payload.mainColorLightTheme,
      };
    }

    case CHANGE_MAIN_COLOR_DARK_THEME: {
      return {
        ...state,
        mainColorDarkTheme: action.payload.mainColorDarkTheme,
      };
    }

    case SET_CURRENCIES: {
      return {
        ...state,
        allCurrencies: action.payload.allCurrencies,
      };
    }

    case CHANGE_CHART_PERIOD: {
      return {
        ...state,
        chartPeriod: action.payload.chartPeriod,
      };
    }

    case SET_MAX_PRICE: {
      return {
        ...state,
        maxPrice: action.payload.maxPrice,
      };
    }

    case SET_MIN_PRICE: {
      return {
        ...state,
        minPrice: action.payload.minPrice,
      };
    }

    case SET_CHANGE: {
      return {
        ...state,
        change: action.payload.change,
      };
    }

    case SET_AVARAGE_PRICE: {
      return {
        ...state,
        avaragePrice: action.payload.avaragePrice,
      };
    }

    case CHANGE_ACCENT_COLOR: {
      return {
        ...state,
        accentColor: action.payload.accentColor,
      };
    }

    case CHANGE_GALLERY_QUOTE: {
      return {
        ...state,
        galleryQuote: action.payload.galleryQuote,
      };
    }

    case CHANGE_USER_QUOTE: {
      return {
        ...state,
        userQuote: action.payload.userQuote,
      };
    }

    default:
      return state;
  }
}
