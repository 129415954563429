import React, { FC, useRef, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { changeChartPeriod } from "../../../redux/actions";

import {
  DateRangeContainer,
  SingleDateRangeButton,
} from "../styles/SettingsStyles";
import { iDateRange, iUserPickedLabelDateRange } from "./SettingsModalCalendar";

interface iCalendarModalDateRangeProps {
  dateRanges: Array<iDateRange>;
  setUserPickedLabelDateRange: Function;
  userPickedLabelDateRange: iUserPickedLabelDateRange | any;
}

const CalendarModalDateRange: FC<iCalendarModalDateRangeProps> = ({
  dateRanges,
  setUserPickedLabelDateRange,
  userPickedLabelDateRange,
}) => {
  const dispatch = useDispatch();
  const wholeStore: any = useSelector((state) => state);

  const handleChangeDateRange = (range: any) => {
    setUserPickedLabelDateRange(range);
    dispatch(changeChartPeriod(range.label));
  };

  return (
    <DateRangeContainer>
      {dateRanges.map((range: iDateRange, i: number) => (
        <SingleDateRangeButton
          className={
            wholeStore && wholeStore.chartPeriod === range.label ? "active" : ""
          }
          onClick={() => handleChangeDateRange(range)}
          key={i}
        >
          {range.label}
        </SingleDateRangeButton>
      ))}
    </DateRangeContainer>
  );
};

export default CalendarModalDateRange;
