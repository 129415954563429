import { FC } from "react";
import { isMobile } from "react-device-detect";

import {
  SectionContainer,
  SectionHeader,
  SectionParagraph,
  CreatorProcessContainer,
  CreatorProcessCard,
  CreatorProcessCardIcon,
  CreatorProcessCardHeader,
  CreatorProcessCardText,
  VideoContainer,
  VideoContainerIcon,
} from "../style/MainPageStyle.js";

import themeChangeCard from "../../../assets/step1.svg";
import bitcoinCard from "../../../assets/step2.svg";
import chartPeriodCard from "../../../assets/step3.svg";
import ownTextCard from "../../../assets/step4.svg";
import pickSizeCard from "../../../assets/step5.svg";
import videoPlayButton from "../../../assets/video-play.svg";

interface Section2CreatorProcessProps {}

const Section2CreatorProcess: FC<Section2CreatorProcessProps> = ({}) => {
  return (
    <SectionContainer
      className="section section2 no-margin-top"
      gridRowStart={3}
      gridColumnStart={2}
      gridColumnEnd={3}
      mTop="90.7"
    >
      <SectionHeader
        id={"proces-kreatora"}
        className="full-width center-text"
        alignSelf="center"
      >
        Proces kreatora
      </SectionHeader>
      <SectionParagraph alignSelf="center" mTop={37.5} mBottom={20}>
        To mega prosta sprawa, która zajmie Ci 3 minuty.
      </SectionParagraph>
      <CreatorProcessContainer mTop={isMobile ? 0 : 135.9}>
        <CreatorProcessCard>
          <CreatorProcessCardIcon image={themeChangeCard} />
          <CreatorProcessCardHeader>Krok 1 </CreatorProcessCardHeader>
          <CreatorProcessCardText>
            <SectionParagraph>
              Wybierasz plakat ciemny lub jasny
            </SectionParagraph>
          </CreatorProcessCardText>
        </CreatorProcessCard>

        <CreatorProcessCard>
          <CreatorProcessCardIcon
            image={bitcoinCard}
            width={110.5}
            height={91.4}
          />
          <CreatorProcessCardHeader>Krok 2</CreatorProcessCardHeader>
          <CreatorProcessCardText>
            <SectionParagraph>
              Wybierasz krypto i widzisz wykres
            </SectionParagraph>
          </CreatorProcessCardText>
        </CreatorProcessCard>

        <CreatorProcessCard>
          <CreatorProcessCardIcon
            image={chartPeriodCard}
            width={79.4}
            height={88.3}
          />
          <CreatorProcessCardHeader>Krok 3</CreatorProcessCardHeader>
          <CreatorProcessCardText>
            <SectionParagraph>Wybierasz ramy czasowe wykresu</SectionParagraph>
          </CreatorProcessCardText>
        </CreatorProcessCard>

        <CreatorProcessCard>
          <CreatorProcessCardIcon
            image={ownTextCard}
            width={85.3}
            height={85}
          />
          <CreatorProcessCardHeader>Krok 4</CreatorProcessCardHeader>
          <CreatorProcessCardText>
            <SectionParagraph>Wybierasz lub wpisujesz cytat</SectionParagraph>
          </CreatorProcessCardText>
        </CreatorProcessCard>

        <CreatorProcessCard>
          <CreatorProcessCardIcon
            image={pickSizeCard}
            width={84.1}
            height={83.6}
          />
          <CreatorProcessCardHeader>Krok 5</CreatorProcessCardHeader>
          <CreatorProcessCardText>
            <SectionParagraph>Wybierasz rozmiar plakatu</SectionParagraph>
          </CreatorProcessCardText>
        </CreatorProcessCard>
      </CreatorProcessContainer>
      {/* <VideoContainer width={930} height={523} mTop={152}>
        <VideoContainerIcon image={videoPlayButton} width={61} height={70} />
      </VideoContainer> */}
    </SectionContainer>
  );
};

export default Section2CreatorProcess;
