import { FC } from "react";
import { useHistory } from "react-router-dom";
import cookie from "react-cookies";
import { HashLink as Link } from "react-router-hash-link";
import { useDispatch, useSelector } from "react-redux";

import {
  NavigationHeaderContainer,
  Logo,
  HeaderNavButtonsContainer,
  HeaderNavButton,
  CloseIcon,
  HeaderMobileLogoAndButtonContainer,
  LinkTo,
} from "../style/MainPageStyle.js";
import LogoImage from "../../../assets/logo-dark.svg";
import LogoWhite from "../../../assets/logo-white.svg";
import CartIcon from "../../../assets/cart-icon.svg";
import CartIconWhite from "../../../assets/cart-icon-white.svg";
import { isMobile } from "react-device-detect";

interface NavigationHeaderProps {
  isMenuOpen: boolean;
  handleHamburgerClick: Function;
  position: string;
  theme: string;
}

const NavigationHeader: FC<NavigationHeaderProps> = ({
  isMenuOpen,
  handleHamburgerClick,
  position,
  theme,
}) => {
  let history = useHistory();
  const wholeStore: any = useSelector((state) => state);

  const redirectToPosterCreator = () => {
    history.push("/kreator");
  };

  const redirectToPosterCart = () => {
    history.push("/podsumowanie");
  };
  return (
    <NavigationHeaderContainer
      isMenuOpen={isMenuOpen}
      position={position}
      theme={theme}
      className={`navigation ${position === "fixed" ? "absolute-pos" : ""} ${
        window.location.href.includes("polityka-prywatnosci") ||
        window.location.href.includes("regulamin") ||
        window.location.href.includes("platnosc-zaakceptowana") ||
        window.location.href.includes("platnosc-anulowana")
          ? "full-width"
          : ""
      }`}
    >
      <LinkTo to="/">
        <Logo
          theme={theme}
          src={
            wholeStore.theme === "light" ||
            window.location.href.includes("wymiary") ||
            window.location.href.includes("podsumowanie") ||
            window.location.href.includes("polityka-prywatnosci") ||
            window.location.href.includes("regulamin") ||
            window.location.href.includes("platnosc-zaakceptowana") ||
            window.location.href.includes("platnosc-anulowana") ||
            window.location.pathname == "/"
              ? LogoImage
              : LogoWhite
          }
          className="desktop-logo"
        />
      </LinkTo>
      <HeaderMobileLogoAndButtonContainer>
        <Logo
          src={
            wholeStore.theme === "light" ||
            window.location.href.includes("wymiary") ||
            window.location.href.includes("podsumowanie") ||
            window.location.href.includes("polityka-prywatnosci") ||
            window.location.href.includes("regulamin") ||
            window.location.href.includes("platnosc-zaakceptowana") ||
            window.location.href.includes("platnosc-anulowana") ||
            window.location.pathname == "/"
              ? LogoImage
              : LogoWhite
          }
          className="logo-small"
          onClick={() => {
            handleHamburgerClick();
            history.push("/");
          }}
        />
        <CloseIcon theme={theme} onClick={handleHamburgerClick} />
      </HeaderMobileLogoAndButtonContainer>
      <HeaderNavButtonsContainer>
        <LinkTo to="/#proces-kreatora">
          <HeaderNavButton
            onClick={() => {
              handleHamburgerClick();
              history.push("/#proces-kreatora");
            }}
            margin={"5% 0"}
            width={"100% "}
            theme={
              window.location.href.includes("wymiary") ||
              window.location.href.includes("podsumowanie") ||
              window.location.href.includes("polityka-prywatnosci") ||
              window.location.href.includes("regulamin") ||
              window.location.href.includes("platnosc-zaakceptowana") ||
              window.location.href.includes("platnosc-anulowana") ||
              window.location.pathname == "/"
                ? "light"
                : wholeStore.theme
            }
            alternative={position === "fixed"}
          >
            Jak to działa
          </HeaderNavButton>
        </LinkTo>
        <LinkTo to="/#o-projekcie">
          <HeaderNavButton
            onClick={() => {
              handleHamburgerClick();
              history.push("/#o-projekcie");
            }}
            margin={"5% 0"}
            width={"100% "}
            theme={
              window.location.href.includes("wymiary") ||
              window.location.href.includes("podsumowanie") ||
              window.location.href.includes("polityka-prywatnosci") ||
              window.location.href.includes("regulamin") ||
              window.location.href.includes("platnosc-zaakceptowana") ||
              window.location.href.includes("platnosc-anulowana") ||
              window.location.pathname == "/"
                ? "light"
                : wholeStore.theme
            }
            alternative={position === "fixed"}
          >
            O projekcie
          </HeaderNavButton>
        </LinkTo>
        {position !== "fixed" && (
          <HeaderNavButton
            tAlign={"center"}
            onClick={redirectToPosterCreator}
            className="background-blue-button"
          >
            Przejdź do kreatora i zobacz swój plakat
          </HeaderNavButton>
        )}
        {/* {isMobile && <HeaderNavButton image={CartIcon}></HeaderNavButton>} */}
        <HeaderNavButton
          theme={
            window.location.href.includes("wymiary") ||
            window.location.href.includes("podsumowanie")
              ? "light"
              : wholeStore.theme
          }
          marginLeft={"35px"}
          width={"35px"}
          height={"35px"}
          onClick={redirectToPosterCart}
          image={
            position !== "fixed"
              ? CartIcon
              : theme === "dark"
              ? CartIconWhite
              : CartIcon
          }
          marginRight={"auto"}
          margin={"5% 0"}
        >
          {cookie.load("userEnteredCheckout") &&
            localStorage.getItem("var") && <div className="cart-items">1</div>}
        </HeaderNavButton>
      </HeaderNavButtonsContainer>
    </NavigationHeaderContainer>
  );
};

export default NavigationHeader;
