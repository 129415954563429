import axios from "axios";
import { apiUrl } from "./ApiUrl";

// data limit is 2000 results soo 2k days its like 5.5 years back


export const getBitcoinData = async (period, currencyType) => {
  let data;
  await axios
    .get(`${apiUrl}/data/v2/histoday?fsym=${currencyType}&tsym=USD&limit=${period}`)
    .then((response) => {
      if (response.status === 200) {
        data = response.data;
      }
    })
    .catch(({ response, error }) => {});

  return data;
};

export const getBitcoinHourData = async (limit, currencyType) => {
  let data;
  await axios
    .get(`${apiUrl}/data/v2/histohour?fsym=${currencyType}&tsym=USD&limit=${limit}`)
    .then((response) => {
      if (response.status === 200) {
        data = response.data;
      }
    })
    .catch(({ response, error }) => {});

  return data;
};

export const getBitcoinMinuteData = async (limit,currencyType) => {
  let data;
  await axios
    .get(`${apiUrl}/data/v2/histominute?fsym=${currencyType}&tsym=USD&limit=${limit}`)
    .then((response) => {
      if (response.status === 200) {
        data = response.data;
      }
    })
    .catch(({ response, error }) => {
      console.log(error);
    });

  return data;
};


export const getTopCurrencies = async (limit) => {
  let data
  
  await axios
  .get(
  `${apiUrl}/data/top/totalvolfull?limit=${limit}&tsym=USD`)
  .then((response) => {
    if (response.status === 200) {
      data = response.data;
    }
  })
  .catch(({ response, error }) => {
    console.log(error);
  });

return data;
}



export const getCurrencyData = async (currency, period) => {
  let data;
  await axios
    .get(`${apiUrl}/data/v2/histoday?fsym=${currency}&tsym=USD&limit=${period}`)
    .then((response) => {
      if (response.status === 200) {
        data = response.data;
      }
    })
    .catch(({ response, error }) => {});

  return data;
};