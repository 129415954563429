import React, { FC, useRef, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";

import {
  SettingsModalContainer,
  SettingsRow,
  SettingText,
  SettingsModalTextarea,
  ModalCloseButton,
  ModalDivider,
  QuoteRow,
  StyledRadio,
  SettingsColumn,
} from "../styles/SettingsStyles";
import { SectionButton } from "../../MainPage/style/MainPageStyle";

import {
  dimensionHeightFix,
  dimensionWidthFix,
} from "../../../utils/dimensionFixer";

interface iTempUserQuoteLength {
  userQuoteAuthorFirstLineLength: number;
  userQuoteLength: number;
}

interface iTempUserQuote {
  userQuote: string;
  userQuoteAuthorFirstLine: string;
}

interface iSettingsModalQuoteProps {
  theme: string;
  galleryTexts: Array<string>;
  handleTextAreaUserSlogan: Function;
  userSlogan: string;
  setUserSlogan: Function;
  tempUserSlogan: string;
  tempUserSloganLength: number;
  setQuoteModalOpen: Function;
  handleTextAreaUserQuoteAuthor: Function;
  handleTextAreaUserQuote: Function;
  tempUserQuoteLength: iTempUserQuoteLength;
  tempUserQuote: iTempUserQuote;
  quoteGalleryTexts: Array<string>;
  changeRadioInput: Function;
  userCheckedValues: [number];
  setQuote: Function;
  toggleQuoteModal: Function;
}

const SettingsModalQuote: FC<iSettingsModalQuoteProps> = ({
  theme,
  galleryTexts,
  handleTextAreaUserSlogan,
  userSlogan,
  setUserSlogan,
  tempUserSlogan,
  tempUserSloganLength,
  setQuoteModalOpen,
  handleTextAreaUserQuoteAuthor,
  handleTextAreaUserQuote,
  tempUserQuoteLength,
  tempUserQuote,
  quoteGalleryTexts,
  changeRadioInput,
  userCheckedValues,
  setQuote,
  toggleQuoteModal,
}) => {
  const modalRef = useRef(null);
  const [modalWidth, setModalWidth] = useState(0);
  const [modalHeight, setModalHeight] = useState(0);

  const wholeStore: any = useSelector((state: any) => state);

  useEffect(() => {
    calculateModalSizes();
  }, [modalRef]);

  const calculateModalSizes = () => {
    const element: any = modalRef.current;
    let width = element.offsetWidth;
    let height = element.offsetHeight;

    setModalWidth(width);
    setModalHeight(height);
  };

  return (
    <SettingsModalContainer ref={modalRef} theme={theme}>
      <ModalCloseButton
        width={modalWidth}
        height={modalHeight}
        onClick={() => toggleQuoteModal()}
      />
      <SettingsRow>
        <SettingText
          theme={theme}
          marginTop={77}
          fSize={33}
          fWeight={600}
          className="bigText"
        >
          Wybierz Twoj Cytat
        </SettingText>
      </SettingsRow>
      <SettingsRow>
        <SettingText
          theme={theme}
          marginTop={7}
          fSize={isMobile ? 28 : 14}
          fWeight={500}
        >
          limit: 140 znaków
        </SettingText>
      </SettingsRow>

      <SettingsRow>
        <SettingText
          theme={theme}
          marginTop={5}
          fSize={isMobile ? 30 : 16}
          fWeight={600}
        >
          Cytat
        </SettingText>
      </SettingsRow>
      <SettingsRow>
        <SettingsModalTextarea
          theme={theme}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleTextAreaUserQuote(e)
          }
          marginTop={4}
          maxLength={140}
          height={84}
          defaultValue={
            wholeStore.userQuote.quote ? wholeStore.userQuote.quote : ""
          }
        ></SettingsModalTextarea>
      </SettingsRow>
      <SettingsRow>
        <SettingText
          theme={theme}
          marginTop={6}
          fSize={isMobile ? 22 : 12}
          fWeight={600}
          opacity={0.3}
        >
          znaki: {tempUserQuoteLength.userQuoteLength} z 140
        </SettingText>
      </SettingsRow>
      <SettingsRow>
        {
          <SectionButton
            className={
              tempUserQuoteLength.userQuoteLength ? "active" : "disabled"
            }
            theme={theme}
            padding={`${dimensionHeightFix(10) + "px"} ${
              dimensionWidthFix(45) + "px"
            }`}
            onClick={
              tempUserQuoteLength.userQuoteLength
                ? () => setQuote("userQuote")
                : null
            }
          >
            Ustaw
          </SectionButton>
        }
      </SettingsRow>

      <SettingsRow>
        <SettingText
          theme={theme}
          marginTop={5}
          fSize={isMobile ? 30 : 16}
          fWeight={600}
        >
          Autor
        </SettingText>
      </SettingsRow>
      <SettingsRow>
        <SettingsModalTextarea
          theme={theme}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleTextAreaUserQuoteAuthor(e)
          }
          defaultValue={
            wholeStore.userQuote.userQuoteAuthorFirstLine
              ? wholeStore.userQuote.userQuoteAuthorFirstLine
              : ""
          }
          marginTop={4}
          maxLength={50}
          height={36}
        ></SettingsModalTextarea>
      </SettingsRow>
      <SettingsRow>
        <SettingText
          theme={theme}
          marginTop={6}
          fSize={isMobile ? 22 : 12}
          fWeight={600}
          opacity={0.3}
        >
          znaki: {tempUserQuoteLength.userQuoteAuthorFirstLineLength} z 50
        </SettingText>
      </SettingsRow>
      <SettingsRow>
        {
          <SectionButton
            className={
              tempUserQuoteLength.userQuoteAuthorFirstLineLength > 0
                ? "active"
                : "disabled"
            }
            theme={theme}
            padding={`${dimensionHeightFix(10) + "px"} ${
              dimensionWidthFix(45) + "px"
            }`}
            onClick={
              tempUserQuoteLength.userQuoteAuthorFirstLineLength > 0
                ? () => setQuote("userQuote")
                : null
            }
          >
            Ustaw
          </SectionButton>
        }
      </SettingsRow>

      <SettingsRow>
        <SettingText
          theme={theme}
          marginTop={9}
          fSize={isMobile ? 42 : 28}
          fWeight={600}
          // className="bigText"
        >
          lub wybierz z naszej galerii
        </SettingText>
      </SettingsRow>

      {quoteGalleryTexts.map((galleryText: string, i: number) => (
        <QuoteRow key={i}>
          <SettingsRow>
            <SettingsColumn width={"10%"}>
              <StyledRadio
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  changeRadioInput(e)
                }
                marginTop={6}
                checked={userCheckedValues.includes(i)}
                value={i}
              />
            </SettingsColumn>
            <SettingsColumn width={"90%"} alignItems={"start"}>
              <SettingText
                theme={theme}
                marginTop={9}
                fSize={isMobile ? 30 : 16}
                fWeight={700}
                opacity={0.5}
              >
                {galleryText}
              </SettingText>
            </SettingsColumn>
          </SettingsRow>
          {i % 2 !== 0 && (
            <SettingsRow>
              <ModalDivider />
            </SettingsRow>
          )}
        </QuoteRow>
      ))}
      <SettingsRow>
        {/* {
          <SectionButton
            className={userCheckedValues.length ? "active" : "disabled"}
            theme={theme}
            padding={`${dimensionHeightFix(10) + "px"} ${
              dimensionWidthFix(45) + "px"
            }`}
            onClick={
              userCheckedValues.length ? () => setQuote("galleryQuote") : null
            }
          >
            Ustaw
          </SectionButton>
        } */}
      </SettingsRow>
    </SettingsModalContainer>
  );
};

export default SettingsModalQuote;
