export const quoteGallery = [
  "Earn as much as you can, save as much as you can, invest as much as you can, give as much as you can.",
  "John Wesley",
  "An investment in knowledge pays the best interest.",
  "Benjamin Franklin",
  "I will tell you how to become rich. Close the doors. Be fearful when others are greedy. Be greedy when others are fearful.",
  "Warren Buffett",
  "How many millionaires do you know who have become wealthy by investing in savings accounts? I rest my case.",
  "Robert G. Allen",
  "The individual investor should act consistently as an investor and not as a speculator.",
  "Ben Graham",
  "It's not how much money you make, but how much money you keep, how hard it works for you, and how many generations you keep it for.",
  "Robert Kiyosaki",
  "Know what you own, and know why you own it.",
  "Peter Lynch",
  "Investing should be more like watching paint dry or watching grass grow. If you want excitement, take $800 and go to Las Vegas.",
  "Paul Samuelson",
  "The most contrarian thing of all is not to oppose the crowd but to think for yourself.",
  "Peter Thiel",
  "Wealthy people invest first and spend what’s left and broke people spend first and invest what’s left.",
  "Anonymous",
];
