import React from 'react'
import styled from 'styled-components'
import GridLoader from "react-spinners/GridLoader";


const LoaderLayer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,0.55);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 225;
`

const Loader = () => {
    return (
    <LoaderLayer>

        <GridLoader color={"#008cff"} loading={true} size={33} speedMultiplier={0.55} />
    </LoaderLayer>
    )

}
export default Loader