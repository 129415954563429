import styled from "styled-components";

export const Ccntainer = styled.div`
  width: 75%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 12vh 0 0 0;
  padding-bottom: 5%;

  &.cart-page__container {
    justify-content: flex-start;
  }

  @media only screen and (max-width: 600px) {
    width: 100%;
    padding: 0 0 0 0;
    padding: 0 0 20% 0;

    &.sizes-page__container {
      padding: 0 0 15% 0;
    }
  }
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 50%;
  height: ${(props) => (props.height ? props.height : "unset")};
  align-self: ${(props) => (props.alignSelf ? props.alignSelf : "unset")};
  box-sizing: border-box;
  padding: ${(props) => (props.padding ? props.padding : "unset")};

  @media only screen and (max-width: 600px) {
    width: 100%;
    padding: 0 2.5% 0 2.5%;
  }
`;

export const Row = styled.div`
  width: ${(props) => (props.width ? props.width : "100%")};
  display: flex;
  justify-content: ${(props) => (props.justify ? props.justify : "flex-start")};
  align-items: ${(props) => (props.align ? props.align : "center")};
  margin: ${(props) => (props.margin ? props.margin : "unset")};
  padding: ${(props) => (props.padding ? props.padding : "unset")};
  flex-direction: ${(props) => (props.fDir ? props.fDir : "row")};
  box-sizing: border-box;

  &.error {
    border: 1px solid rgba(255, 148, 148, 1);

    padding: 2px 0 4px 0;
  }

  a {
    display: contents;
  }

  &.header-paragraph {
    justify-content: center;
  }

  &.uppercase {
    text-transform: uppercase;
  }

  &.underline {
    text-decoration: underline;
  }

  &.font-normal {
    font-weight: normal !important;
  }
  .display-contents {
    display: contents;
  }
  .bold {
    font-weight: bold;
  }

  &.bold {
    font-weight: bold;
  }

  &.header-row {
    margin: 1.2rem 0;
  }

  &.center {
    width: 100%;
    justify-content: center;
  }

  &.header {
    margin-top: 10vh;
    margin-bottom: 3vh;
    font-size: 1.5rem;
    font-weight: 600;
  }

  span {
    display: inline;
    float: left;

    &.underlined {
      text-decoration: underline;
    }

    &.bold {
      font-weight: bold;
    }

    &.order {
      margin-right: 3%;
      font-weight: bold;
    }
    &.order-2 {
      margin-right: 2.7%;
      font-weight: bold;
    }
    &.order-3 {
      margin-right: 2.5%;
      font-weight: bold;
    }
  }
`;

export const Column = styled.div`
  width: ${(props) => (props.width ? props.width : "unset")};
  display: flex;
  flex-direction: column;
  justify-content: ${(props) => (props.justify ? props.justify : "flex-start")};
  align-items: center;
  margin: ${(props) => (props.margin ? props.margin : "unset")};
  padding: ${(props) => (props.padding ? props.padding : "unset")};
  align-items: ${(props) => (props.align ? props.align : "center")};
  box-sizing: border-box;
  align-self: ${(props) => (props.alignSelf ? props.alignSelf : "unset")};
`;

export const Text = styled.span`
  display: ${(props) => (props.display ? props.display : "inline")};
  font-size: ${(props) => (props.fSize ? props.fSize : "1rem")};
  color: ${(props) => (props.color ? props.color : "#000")};
  font-family: "Lexend";
  font-weight: ${(props) => (props.fWeight ? props.fWeight : "500")};
  z-index: ${(props) => (props.zIndex ? props.zIndex : "1")};
  margin: ${(props) => (props.margin ? props.margin : "unset")};
  line-height: ${(props) => (props.lHeight ? props.lHeight : "unset")};

  light {
    font-weight: 300;
  }

  &.center {
    width: 100%;
    text-align: center;
  }

  b {
    &.posterSize {
      text-transform: uppercase;
    }
  }
`;

export const FormatBubble = styled.div`
  width: 2.8vw;
  height: 2.8vw;
  border-radius: 50%;
  background-color: #f5f5f5;
  box-sizing: border-box;
  border: 1px solid transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-weight: bold;
  color: #008cff;
  box-shadow: 1.5px 0.6px 2.2px rgba(0, 0, 0, 0.008),
    3.5px 1.5px 5.3px rgba(0, 0, 0, 0.02), 6.6px 2.8px 10px rgba(0, 0, 0, 0.015),
    11.8px 4.9px 17.9px rgba(0, 0, 0, 0.018),
    22.1px 9.2px 33.4px rgba(0, 0, 0, 0.019), 53px 22px 80px rgba(0, 0, 0, 0.03);

  @media only screen and (max-width: 600px) {
    width: 45px;
    height: 45px;
  }

  &:hover {
    border: 1px solid #008cff;
    box-shadow: 1.5px 0.6px 2.2px rgba(0, 140, 255, 0.01),
      3.5px 1.5px 5.3px rgba(0, 140, 255, 0.05),
      6.6px 2.8px 10px rgba(0, 140, 255, 0.025),
      11.8px 4.9px 17.9px rgba(0, 140, 255, 0.056),
      22.1px 9.2px 33.4px rgba(0, 140, 255, 0.029),
      53px 22px 80px rgba(0, 140, 255, 0.04);
  }

  &.active {
    background-color: #008cff;
    box-shadow: 1.5px 0.6px 2.2px rgba(0, 140, 255, 0.01),
      3.5px 1.5px 5.3px rgba(0, 140, 255, 0.05),
      6.6px 2.8px 10px rgba(0, 140, 255, 0.055),
      11.8px 4.9px 17.9px rgba(0, 140, 255, 0.086),
      22.1px 9.2px 33.4px rgba(0, 140, 255, 0.049),
      53px 22px 80px rgba(0, 140, 255, 0.04);
    color: white;
  }
`;

export const Divider = styled.div`
  background-color: #d6e6f6;
  width: ${(props) => (props.width ? props.width : "100%")};
  height: 1px;
  margin: ${(props) => (props.margin ? props.margin : "unset")};
`;

export const FormatVisualization = styled.div`
  border: solid 3px #dbdbdb;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  &.active {
    border: solid 3px #008cff;
    z-index: 2;

    .format-visualization-first,
    .format-visualization-second,
    .format-visualization-third,
    .format-visualization-fourth,
    .format-visualization-fifth {
      border-bottom: transparent;
      bottom: 0px;
      left: 0px;
      border-left: transparent;
    }
  }

  &.format-visualization-first {
    width: 240px;
    height: 350px;
    position: relative;
  }
  &.format-visualization-second {
    position: absolute;
    bottom: -3px;
    left: -3px;
    width: 75%;
    height: 70%;
  }
  &.format-visualization-third {
    position: absolute;
    bottom: -3px;
    left: -3px;
    width: 50%;
    height: 50%;
  }
  &.format-visualization-fourth {
    position: absolute;
    bottom: -3px;
    left: -3px;
    width: 35%;
    height: 35%;
  }
  &.format-visualization-fifth {
    position: absolute;
    bottom: -3px;
    left: -3px;
    width: 25%;
    height: 25%;
  }
`;

export const Button = styled.button`
  background-color: #008cff;
  color: white;
  padding: 10px 40px;
  border: none;
  outline: none;
  font-weight: 600;
  cursor: pointer;
`;

export const BackButton = styled.button`
  background-color: transparent;
  color: #008cff;
  padding: 10px 40px;
  border: none;
  outline: none;
  font-weight: 600;
  cursor: pointer;
`;
