import { useRef, useState, useEffect } from "react";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { isMobile } from "react-device-detect";

import { setChartData } from "../../redux/actions";

import { calculateDimension, dimensions } from "../PosterPage/Dimensions";

import {
  ChartMainContainer,
  ChartAvarageDataLine,
  AvaragePriceBox,
  AvaragePriceBall,
  XYPLOT,
} from "./style/ChartStyle.jsx";

import {
  LineSeries,
  XAxis,
  YAxis,
  HorizontalGridLines,
  AreaSeries,
  MarkSeries,
} from "react-vis";

const Chart = ({
  data,
  width,
  height,
  theme,
  mode,
  showPoster,
  stateShowAvaragePrice,
}) => {
  const singleLineSvgElement = useRef();
  const dispatch = useDispatch();
  const wholeStore = useSelector((state) => state);
  const [elementHeight, setElementHeight] = useState([]);
  const [chartSizes, setChartSizes] = useState(null);
  const [chartAbsolutePositions, setChartAbsolutePositions] = useState(null);
  const [chartTopPoint, setChartTopPoint] = useState(null);
  const [chartLeftPoint, setChartLeftPoint] = useState(null);
  const [minData, setMinData] = useState(null);
  const [maxData, setMaxData] = useState(null);
  const [chartDates, setChartDates] = useState();
  const [isUpdated, setUpdate] = useState(false);
  const [startingInvestCoords, setStartingInvestCoords] = useState();

  useEffect(() => {}, []);

  useEffect(() => {
    if (startingInvestCoords && startingInvestCoords.length) {
      wholeStore.showStartInvest && manageStartInvestCoords();
    }
  }, [startingInvestCoords]);

  useEffect(() => {
    if (!wholeStore.showStartInvest) {
      disableChartInvestContainer();
    }
    // else {
    //   enableChartInvestContainer();
    // }
    getMinAndMaxData();
    setTimeout(() => {
      rebuildChartSvg();
      getAllCoords();
      getExactChartSizes();
      wholeStore.showSettings.showAvaragePrice && positionAvaragePriceBox();

      if (startingInvestCoords) {
        wholeStore.showStartInvest && manageStartInvestCoords();
      }
    }, 10);
  }, [wholeStore, showPoster]);

  useEffect(() => {
    if (singleLineSvgElement.current) {
      if (
        singleLineSvgElement.current.state.scaleMixins.xDomain[0].includes("-")
      ) {
        setChartDates(singleLineSvgElement.current.state.scaleMixins.xDomain);
      }
    }
  }, [singleLineSvgElement, data]);

  useEffect(() => {
    if (!isUpdated && data.length) {
      updateAppStoreChartData(data);
      setUpdate(true);
    }
  }, [data]);

  const enableChartInvestContainer = () => {
    if (document.querySelector(".chartInvestContainer")) {
      document.querySelector(".chartInvestContainer").style.display = "flex";
    }
  };

  const disableChartInvestContainer = () => {
    if (document.querySelector(".chartInvestContainer")) {
      document.querySelector(".chartInvestContainer").style.display = "none";
    }
  };

  const getAllCoords = () => {
    const LineSvgElement = document.querySelector(".CustomLine");
    let coords;
    if (LineSvgElement && LineSvgElement.hasAttribute("d")) {
      let path = LineSvgElement && LineSvgElement.getAttribute("d");

      coords = path.split(",");

      let chartStartsAtHeight = parseInt(coords[1].split("L")[0]);

      setElementHeight(chartStartsAtHeight - 100);
    }
  };

  const updateAppStoreChartData = (chartData) => {
    dispatch(setChartData(chartData));
  };

  const manageStartInvestCoords = () => {
    let coords = startingInvestCoords.split("L");

    const allInvestContainerElements = document.querySelectorAll(
      ".chartInvestContainer"
    );

    if (allInvestContainerElements && allInvestContainerElements.length > 0) {
      allInvestContainerElements.forEach((el) => el.remove());
    }

    document.querySelector(".rv-xy-plot");

    var domElement = document.createElement("div");

    domElement.classList.add(`chartInvestContainer`);
    domElement.classList.add(theme === "dark" ? `dark-theme` : "light-theme");

    if (mode != undefined) {
      domElement.classList.add(`generate-page`);
    }

    const widthFixer =
      window.innerWidth /
      (window.innerWidth > 2000
        ? 7500
        : window.innerWidth > 1900
        ? 7000
        : window.innerWidth >= 1600
        ? 11000
        : window.innerWidth >= 1500
        ? 9000
        : window.innerWidth >= 1400
        ? 1700
        : window.innerWidth >= 1300
        ? 7000
        : 4000);

    if (
      parseInt(coords[1]) >=
      width *
        (!isMobile && window.innerWidth < 1500 ? 0.16 : isMobile ? 0.2 : 0.2)
    ) {
      domElement.classList.add("border-right");
      if (mode != undefined) {
        domElement.style.right = `${
          width - parseInt(coords[1]) - width / 2.5
        }px`;
        domElement.style.width = `${Math.floor(
          parseInt(coords[1]) - parseInt(0.257 * width * 0.87)
        )}px`;
      } else {
        domElement.style.right = `${
          width -
          parseInt(coords[1]) -
          width /
            ((isMobile || window.innerWidth > 1500 ? 4 : 3) +
              (isMobile ? widthFixer * 5 : widthFixer))
        }px`;
        domElement.style.width = `${Math.floor(
          parseInt(coords[1]) -
            parseInt(
              (window.innerWidth < 1500 && !isMobile
                ? 0.16
                : !isMobile
                ? 0.2
                : 0.2) * width
            )
        )}px`;
      }
    } else {
      domElement.classList.add("border-left");
      if (mode != undefined) {
        domElement.style.left = `${40 + parseInt(coords[1])}px`;

        domElement.style.width = `${Math.floor(
          parseInt(width * 0.227 - coords[1])
        )}px`;
      } else {
        domElement.style.left = `${40 + parseInt(coords[1])}px`;

        domElement.style.width = `${Math.floor(
          parseFloat(
            width /
              (!isMobile && window.innerWidth < 1500 ? 6 : !isMobile ? 5 : 5) -
              parseInt(coords[1]) * 1
          )
        )}px`;
      }
    }
    if (mode != undefined) {
      domElement.style.top = `${parseInt(coords[0]) + height / 14}px`;

      domElement.style.height = `${Math.floor(
        parseInt(height - coords[0] + height / 2.3)
      )}px`;
    } else {
      domElement.style.top = `${
        parseInt(coords[0]) +
        height / (!isMobile && window.innerWidth < 1500 ? 6 : 8)
      }px`;

      domElement.style.height = `${Math.floor(
        parseInt(height - coords[0] + height / 2)
      )}px`;
    }

    document.getElementsByClassName("rv-xy-plot")[0].appendChild(domElement);
  };

  const getMinAndMaxData = () => {
    let minVal;
    let maxVal;

    if (data.length > 0) {
      minVal = data.reduce(function (prev, curr) {
        return prev.y < curr.y ? prev : curr;
      });
      maxVal = data.reduce(function (prev, curr) {
        return prev.y > curr.y ? prev : curr;
      });
      setMinData(minVal.y);
      setMaxData(maxVal.y);
    }
  };

  const getExactChartSizes = () => {
    if (document.querySelector(".chart")) {
      const chartPathSize = document.querySelector(".chart").getBBox();
      const chartPathRelative = document.querySelector(".chart").getCTM();
      const chartContainerPosition =
        document.querySelector(".poster-container").offsetTop;
      const chartElementRect = document
        .querySelector(".chart")
        .getClientRects()[0];
      const chartAbsolutePositions = document
        .querySelector(".chart")
        .getScreenCTM();
      setChartAbsolutePositions(chartAbsolutePositions);

      chartElementRect &&
        setChartTopPoint(
          chartElementRect.top -
            chartElementRect.height / 2 -
            chartContainerPosition
        );
      chartPathRelative && setChartLeftPoint(chartPathRelative.e);

      chartPathSize && setChartSizes(chartPathSize);
    }
  };

  const rebuildChartSvg = () => {
    if (document.querySelector(".created-svg")) {
      document.querySelector(".created-svg").remove();

      document.querySelectorAll(".created-svg").forEach((svg) => {
        svg.remove();
      });
    }

    setTimeout(() => {
      !wholeStore.showStartInvest && disableChartInvestContainer();
      const LineSvgElement = document.querySelector(".CustomLine");
      let index;
      if (LineSvgElement && LineSvgElement.hasAttribute("d")) {
        let path = LineSvgElement && LineSvgElement.getAttribute("d");

        const firstCoord = "M0";

        let fixedPath = [path.slice(0, 1), firstCoord, path.slice(1)].join("");
        var array = fixedPath.split(",");

        data &&
          data.forEach((data, i) => {
            // moment.unix(value).format("MM/DD/YYYY")
            if (typeof data.x == "string") {
              if (
                data.x ==
                moment(wholeStore.investStartDate).format("DD-MM-YYYY")
              ) {
                index = i;
                return;
              }
            } else if (typeof data.x == "number") {
              if (
                moment.unix(data.x).format("DD-MM-YYYY") ==
                moment(wholeStore.investStartDate).format("DD-MM-YYYY")
              ) {
                index = i;
                return;
              }
            }
          });

        if (index) {
          setStartingInvestCoords(array[index]);
          wholeStore.showStartInvest && enableChartInvestContainer();
        } else {
          !wholeStore.showStartInvest && disableChartInvestContainer();
        }
      }
    }, 50);
  };

  const positionAvaragePriceBox = () => {
    if (stateShowAvaragePrice && document.querySelector(".CustomLine")) {
      const chartWidth = document.querySelector(".CustomLine").getBBox().width;
      const chartHeight = document
        .querySelector(".CustomLine")
        .getBBox().height;

      document.querySelectorAll(".avarage-price-box__child1").length &&
        document.querySelectorAll(".avarage-price-box__child1")[0].remove();
      document.querySelectorAll(".avarage-price-box__child2").length &&
        document.querySelectorAll(".avarage-price-box__child2")[0].remove();

      const avgPriceBoxPriceBox1 = document.createElement("div");
      const avgPriceBoxPriceBox2 = document.createElement("div");
      avgPriceBoxPriceBox1.classList.add("avarage-price-box__child1");
      mode == "generate" && avgPriceBoxPriceBox1.classList.add("generate");
      avgPriceBoxPriceBox2.classList.add("avarage-price-box__child2");
      mode == "generate" && avgPriceBoxPriceBox2.classList.add("generate");

      avgPriceBoxPriceBox1.innerHTML = moment(wholeStore.label.dateFrom).format(
        "DD MMM YYYY"
      );
      avgPriceBoxPriceBox2.innerHTML = moment(wholeStore.label.dateTo).format(
        "DD MMM YYYY"
      );

      stateShowAvaragePrice &&
        document
          .querySelector(".avarage-price-box")
          .append(avgPriceBoxPriceBox1);

      stateShowAvaragePrice &&
        document
          .querySelector(".avarage-price-box")
          .append(avgPriceBoxPriceBox2);

      document.querySelector(".avarage-price-box").style.right =
        mode != undefined && mode != "cart"
          ? chartWidth - width / 10.5 + "px"
          : mode == "cart"
          ? chartWidth - width / 20 + "px"
          : chartWidth - width / 14 + "px";
      document.querySelector(".avarage-price-box").style.height =
        chartHeight / 2 + "px";
      document.querySelector(".avarage-price-ball").style.right =
        chartWidth +
        calculateDimension(dimensions.priceBall.width) / 10 -
        (mode != undefined ? 18 : 3) +
        "px";

      document.querySelector(".avarage-price-ball").style.top =
        chartHeight / 2 -
        calculateDimension(dimensions.priceBall.height) / 10 -
        (mode != undefined ? 28 : 0) +
        "px";

      document.querySelector(".chart-avarage-line").style.top =
        chartHeight / 2 +
        calculateDimension(dimensions.priceBall.width) / 3 -
        (mode == "generate" ? 15 : 0) +
        "px";
    }
  };

  return (
    <div className="cursor">
      {data && stateShowAvaragePrice && (
        <AvaragePriceBox
          height={elementHeight}
          chartLeftPoint={chartLeftPoint}
          width={width}
          className={`avarage-price-box ${
            mode != undefined && "generate-page"
          }`}
          theme={theme}
          bgColor={theme === "dark" ? "#ff9400" : "#ff9400"}
        ></AvaragePriceBox>
      )}
      <ChartMainContainer className="chart-main-container">
        {stateShowAvaragePrice && (
          <AvaragePriceBall
            bgColor={theme === "dark" ? "#ff9400" : "#ff9400"}
            className="avarage-price-ball"
            width={calculateDimension(dimensions.priceBall.width, mode)}
            height={calculateDimension(dimensions.priceBall.height, mode)}
            // ballHeight={chartSizes && chartSizes.height}
            fSize={calculateDimension(dimensions.priceBall.fSize, mode)}
          >
            {wholeStore &&
            wholeStore.avaragePrice &&
            wholeStore.avaragePrice > 1000
              ? `$${(wholeStore.avaragePrice / 1000).toFixed(1)}K`
              : `$${wholeStore.avaragePrice.toFixed(1)}`}
          </AvaragePriceBall>
        )}

        {stateShowAvaragePrice && (
          <ChartAvarageDataLine
            // posterWidth={chartWidth}
            theme={theme}
            posterHeight={chartSizes && chartSizes.height}
            className={`${
              mode !== undefined && "generate-page"
            } chart-avarage-line`}
          />
        )}
        <XYPLOT
          width={
            width * (mode == "cart" ? 0.93 : mode == "generate" ? 0.8 : 0.87)
          }
          height={mode == "cart" ? height * 1.3 : height}
          xType="ordinal"
          ref={singleLineSvgElement}
          yDomain={[minData, maxData]}
          className={`${mode !== undefined && "generate-page"}`}
        >
          <XAxis Type="time" />
          <YAxis />
          <HorizontalGridLines />
          <MarkSeries
            className="mark-series"
            data={[{ x: data && data.length ? data[0].x : 0, y: 0 }]}
          />

          <AreaSeries
            showsPagination={false}
            className="chart"
            data={data}
          ></AreaSeries>
          <LineSeries className="CustomLine" data={data}>
            <defs>
              <linearGradient id="Gradient2" x1="0" x2="0" y1="0" y2="1">
                <stop offset="0%" stopColor="red" stopOpacity={0.4} />
                <stop offset="100%" stopColor="blue" stopOpacity={0.3} />
              </linearGradient>
            </defs>
          </LineSeries>
        </XYPLOT>
      </ChartMainContainer>
    </div>
  );
};

export default Chart;
