import styled from "styled-components";

import quoteSign from "../../../assets/quote-mark-icon.svg";

import PosterBackgroundLayerDark from "../../../assets/poster-background-layer-dark.svg";
import changeMarkIcon from "../../../assets/change-mark-icon.svg";
import tradegraphyLogo from "../../../assets/tradeGraphylogo.svg";
import PosterBackgroundLayerLight from "../../../assets/white-dots-layer.svg";
import LogoLight from "../../../assets/logo-dark.svg";
export const PosterContainer = styled.div`
  flex-direction: column;

  position: relative;
  /* background-color: ${(props) =>
    props.theme === "dark" ? "black" : "white"}; */
  border-bottom-color: rgba(45, 45, 45, 0.8);
  border-left-color: rgba(125, 125, 125, 1);
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.02),
    0 6.7px 5.3px rgba(0, 0, 0, 0.028), 0 12.5px 10px rgba(0, 0, 0, 0.035),
    0 22.3px 17.9px rgba(0, 0, 0, 0.042), 0 41.8px 33.4px rgba(0, 0, 0, 0.05),
    0 100px 80px rgba(0, 0, 0, 0.07);
  align-self: center;
  z-index: 26;
  margin: auto;
  width: ${(props) => `${props.width}px`};
  height: ${(props) => `${props.height}px`};
  max-width: 50vw;
  background-image: url(${(props) =>
    props.theme === "dark"
      ? PosterBackgroundLayerDark
      : PosterBackgroundLayerLight});
  background-size: 105% 106%;
  background-repeat: no-repeat;
  background-position: center;
  transition: all 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94);

  &.visible {
    @media (max-width: 768px) {
      position: absolute;
      display: flex;
      top: 0%;
      opacity: 1;
      z-index: 12;
      left: -7px;
      /* height: ${(props) => `${props.height}px`}; */
      transition: all 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94);

      box-shadow: 0px 0px 10.4px rgba(0, 0, 0, 0.329),
        0px 0px 19.1px rgba(255, 255, 255, 0.338),
        0px 0px 26.3px rgba(0, 0, 0, 0.244),
        0px 0px 33.4px rgba(255, 255, 255, 0.348),
        0px 0px 43.8px rgba(0, 0, 0, 0.053),
        0px 0px 80px rgba(255, 255, 255, 0.17);
    }
  }

  &.invisible {
    @media (max-width: 768px) {
      /* height: 1px; */
      left: -7px;
      opacity: 1;
      position: fixed;
      top: 100%;
      /* display: none; */
    }
  }

  &.generate-page {
    box-shadow: none;
    /* transform: ${(props) =>
      `scale(${props.transformScaleWidth},${props.transformScaleHeight} )`}; */
    /* top: ${(props) =>
      `calc(${props.height}px * ${props.transformScaleHeight / 3})`}; */
    /* left: ${(props) => `${props.transformScaleWidth * 50}px`}; */
    /* left: 0; */
    max-width: 102%;
    width: 102%;
    margin: unset;
  }

  @media (max-width: 768px) {
    margin: 2% 2% 5% 2%;
    max-width: 100vw;
    width: 100vw;
  }
`;

export const PosterPageContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  height: 100vh;
  box-sizing: border-box;
  background-color: ${(props) =>
    props.theme === "dark" ? "#111111" : "#F5F5F5"};
  @media (max-width: 768px) {
    flex-direction: column-reverse;
    height: unset;
  }
`;

export const PrintableChartContainer = styled.div`
  box-sizing: border-box;
  background-color: black;
  border: ${(props) => `${props.width * 0.02}px`} inset rgba(85, 85, 85, 0.8);
  border-bottom-color: rgba(45, 45, 45, 0.8);
  border-left-color: rgba(125, 125, 125, 1);

  &.chart-final-size {
    box-sizing: border-box;
    background-color: black;
    width: ${(props) => props.width};
    height: ${(props) => props.height};
    border: ${(props) => `${props.width * 0.02}px`} inset rgba(85, 85, 85, 0.8);
    border-bottom-color: rgba(45, 45, 45, 0.8);
    border-left-color: rgba(125, 125, 125, 1);
  }
`;

export const PosterPageNavContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  height: 55px;
  width: calc(100vw - 510px);
  border: 1px solid white;
  box-sizing: border-box;
  left: 255px;
  top: 31px;
`;

export const TextBlock = styled.span`
  font-family: "Inter", sans-serif;
  font-weight: ${(props) => (props.fWeight ? props.fWeight : "unset")};
  font-size: ${(props) => (props.fSize ? `${props.fSize}px` : "unset")};
  width: ${(props) => (props.width ? props.width : "unset")};
  color: ${(props) => (props.fColor ? props.fColor : "white")};
  text-align: ${(props) => (props.tAlign ? props.tAlign : "unset")};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : "unset")};
  margin: ${(props) => (props.margin ? props.margin : "unset")};
  z-index: ${(props) => (props.index ? props.index : "unset")};
  align-self: ${(props) => (props.alignSelf ? props.alignSelf : "unset")};
  position: ${(props) => (props.position ? props.position : "unset")};
  font-style: ${(props) => (props.fVariant ? props.fVariant : "unset")};
  display: ${(props) => (props.display ? props.display : "block")};
  text-transform: ${(props) => (props.tTransform ? props.tTransform : "unset")};

  &.flex {
    display: flex;
    align-items: center;
  }

  &.poster-slogan {
    line-height: initial;
  }

  &.quote-box-author {
    margin-top: 15px;
    text-align: right;
    font-style: italic;
    font-weight: 500;
    max-width: 60%;
    float: right;
  }

  ${(props) => props.additionalStyles && props.additionalStyles};
  z-index: 25 !important;
`;

export const PositionedElement = styled.div`
 font-family: "Inter", sans-serif;
  position: absolute;
  top: ${(props) => (props.top ? `${props.top}px` : "unset")};
  right: ${(props) => (props.right ? `${props.right}px` : "unset")};
  left: ${(props) => (props.left ? `${props.left}px` : "unset")};
  width: ${(props) => (props.width ? `${props.width}px` : "unset")};
  height: ${(props) => (props.width ? `${props.height}px` : "unset")};
  line-height: ${(props) =>
    props.lineHeight ? `${props.lineHeight}px` : "unset"};
  background-color: ${(props) =>
    props.backgroundColor ? `${props.backgroundColor}` : "unset"};
  z-index: ${(props) => (props.zIndex ? `${props.zIndex}` : "2")};
  font-size: ${(props) => (props.fSize ? `${props.fSize}px` : "unset")};
  color: ${(props) => (props.color ? `${props.color}` : "unset")};

  &.cursor{
    cursor: pointer;
    z-index:222;
  }

  &.trademark-logo{

    &.dark{
      background: url(${tradegraphyLogo});
      background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    }
    &.light{
      background: url(${LogoLight});
      background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    }
    

    
  } 

  &.change-mark{
    background: url(${changeMarkIcon});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  &.change-box{
    display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;

  .value{
    color: #ff9400;
  }
  }

  &.high-price{
    display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  color: #ff9400;

  
  }

  &.low-price{
    display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  color: #ff9400;

  
  }
&.first-investment{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  .date{
    color: #ff9400;
    text-transform: uppercase;
  }

  &.generate-page{
    top: ${(props) => (props.top ? `${props.top - 60}px` : "unset")};
  }

  
}


  &.chart-container{
    width: 100%;
    height: 65%;
  }

  &.to-the-moon {
    display: flex;
    flex-direction: column;
    justify-content: center;
  
    
    span{
      word-break: break-word;
    }
  }

  &.date-box-border {
    border-top: 1px solid #555555;
    border-left: 1px solid #555555;
  }

  &.yellow-ball {
    border-radius: 50%;
    background-color: #ff9400;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-family: "Inter", sans-serif;
    color: white;
  }

  &.quote-mark {
    /* background: url(${quoteSign});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center; */

    -webkit-mask: url(${quoteSign}) no-repeat 100% 100%;
  mask: url(${quoteSign}) no-repeat 100% 100%;
  -webkit-mask-size: cover;
  mask-size: cover;
  background-color:#ff9400;
  /* background-color: ${(props) =>
    props.bgColor ? props.bgColor : "black"}; */

    /* background-color: red;
    -webkit-mask-image: url(${quoteSign});
    mask-image: url(${quoteSign}); */
  }

  &.quote-author {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    line-height: 1.2;
  }

  &.quote-box {
    line-height: 1.2;

    span{
      word-break: break-word;
}
    }
  }

  &. {
    z-index: 1;
    overflow: hidden !important;
  }

  &.bitcoin-logo {
    background-color: transparent;
    text-transform: uppercase;
    /* color: white; */
    font-weight: 900;
    transform: rotate(270deg);
    text-align: right;
    

  
  }
`;

export const ChangeBoxRowContainer = styled.div`
  display: flex;
`;

export const HidePosterMobileButton = styled.div`
  position: absolute;
  bottom: -30px;
  right: 25px;
  width: 50px;
  height: 50px;
  background-color: #008cff;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 2.8px 2.8px 2.2px rgba(0, 0, 0, 0.02),
    6.7px 6.7px 5.3px rgba(0, 0, 0, 0.028),
    12.5px 12.5px 10px rgba(0, 0, 0, 0.035),
    22.3px 22.3px 17.9px rgba(0, 0, 0, 0.042),
    41.8px 41.8px 33.4px rgba(0, 0, 0, 0.05),
    100px 100px 80px rgba(0, 0, 0, 0.07);
  z-index: 22223; ;
`;

export const PosterBox = styled.div`
  display: ${(props) => (props.generatePageMode ? "block" : "flex")};
  height: ${(props) => (props.generatePageMode ? "unset" : "fit-content%")};
  /* width: ${(props) => (props.generatePageMode ? "unset" : "50%")}; */
  justify-content: ${(props) => (props.generatePageMode ? "unset" : "center")};
  align-items: center;
  align-self: center;
  z-index: 222;
  margin-right: 15%;
  /* padding: 0 13.5%; */
`;

export const PosterLayer = styled.div`
  width: 100%;
  height: 100%;
  background: url(${(props) =>
    props.theme === "dark"
      ? PosterBackgroundLayerDark
      : PosterBackgroundLayerLight});
  background-size: 105% 106%;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 33;
`;
