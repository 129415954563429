import React from "react";
import arrowLight from "../../../assets/arrow-phase-light.svg";
import arrowDark from "../../../assets/arrow-phase-dark.svg";
import history from "../../../history";

import { PosterPageStepsContnainer, StepArrow } from "../styles/SettingsStyles";
import { isMobile } from "react-device-detect";

const PosterPageSteps = ({ theme, activeStep, mode }) => {
  const redirectUser = (step) => {
    if (window.location.pathname == "/wymiary" && step == "step1") {
      history.push("/kreator");
    }
    if (window.location.pathname == "/podsumowanie" && step == "step2") {
      history.push("/wymiary");
    }
    if (window.location.pathname == "/podsumowanie" && step == "step1") {
      history.push("/kreator");
    }
  };

  return (
    <PosterPageStepsContnainer
      className={`${mode === "cart" && "cart"} poster-page-steps`}
    >
      <StepArrow
        onClick={() => redirectUser("step1")}
        image={theme === "light" ? arrowLight : arrowDark}
        width={226.4}
        height={isMobile ? 100 : 50}
        className={activeStep === 1 ? "active" : ""}
        theme={theme}
      >
        Kreator
      </StepArrow>
      <StepArrow
        onClick={() => redirectUser("step2")}
        image={theme === "light" ? arrowLight : arrowDark}
        width={226.4}
        height={isMobile ? 100 : 50}
        className={activeStep === 2 ? "active" : ""}
        theme={theme}
      >
        Wymiary
      </StepArrow>
      <StepArrow
        onClick={() => redirectUser("step3")}
        image={theme === "light" ? arrowLight : arrowDark}
        width={226.4}
        height={isMobile ? 100 : 50}
        className={activeStep === 3 ? "active" : ""}
        theme={theme}
      >
        Finalizacja
      </StepArrow>
    </PosterPageStepsContnainer>
  );
};

export default PosterPageSteps;
