import { FC } from "react";

import {
  SectionContainer,
  SectionHeader,
  SectionParagraph,
  CreatorProcessContainer,
  CreatorProcessCard,
  CreatorProcessCardIcon,
  CreatorProcessCardHeader,
  CreatorProcessCardText,
  CardRoundedIconBackground,
} from "../style/MainPageStyle.js";

import fillAdress from "../../../assets/purchaseStep1.svg";
import choseSize from "../../../assets/purchaseStep2.svg";
import payIcon from "../../../assets/purchaseStep3.svg";
import createPosterIcon from "../../../assets/purchaseStep4.svg";
import { isMobile } from "react-device-detect";

interface Section4PurchaseProcessProps {}

const Section4PurchaseProcess: FC<Section4PurchaseProcessProps> = () => {
  return (
    <SectionContainer
      className="section section4"
      gridRowStart={5}
      gridColumnStart={2}
      gridColumnEnd={3}
      mTop={isMobile ? 40 : 123}
    >
      <SectionHeader alignSelf="center" className="full-width center-text">
        Proces zakupowy
      </SectionHeader>
      <CreatorProcessContainer mTop={isMobile ? 30 : 109}>
        <CreatorProcessCard width={300} mRight={62}>
          <CardRoundedIconBackground width={210} height={210}>
            <CreatorProcessCardIcon
              className="purchase-process-icon"
              image={createPosterIcon}
              width={112.9}
              height={112.9}
            />
          </CardRoundedIconBackground>
          <CreatorProcessCardHeader>Krok 1</CreatorProcessCardHeader>
          <CreatorProcessCardText>
            <SectionParagraph>
              Tworzysz plakat z wykresem Twojej krypto w kreatorze.
            </SectionParagraph>
          </CreatorProcessCardText>
        </CreatorProcessCard>

        <CreatorProcessCard width={300} mRight={62}>
          <CardRoundedIconBackground width={210} height={210}>
            <CreatorProcessCardIcon
              className="purchase-process-icon"
              image={choseSize}
              width={85.4}
              height={85.9}
            />
          </CardRoundedIconBackground>
          <CreatorProcessCardHeader>Krok 2</CreatorProcessCardHeader>
          <CreatorProcessCardText>
            <SectionParagraph>Wybierasz rozmiar plakatu.</SectionParagraph>
          </CreatorProcessCardText>
        </CreatorProcessCard>

        <CreatorProcessCard width={300} mRight={62}>
          <CardRoundedIconBackground width={210} height={210}>
            <CreatorProcessCardIcon
              image={fillAdress}
              className="purchase-process-icon"
              width={71.1}
              height={100}
            />
          </CardRoundedIconBackground>
          <CreatorProcessCardHeader>Krok 3</CreatorProcessCardHeader>
          <CreatorProcessCardText>
            <SectionParagraph>Wpisujesz dane adresowe.</SectionParagraph>
          </CreatorProcessCardText>
        </CreatorProcessCard>

        <CreatorProcessCard width={300} mRight={62}>
          <CardRoundedIconBackground width={210} height={210}>
            <CreatorProcessCardIcon image={payIcon} width={89.3} height={78} />
          </CardRoundedIconBackground>
          <CreatorProcessCardHeader>Krok 4</CreatorProcessCardHeader>
          <CreatorProcessCardText>
            <SectionParagraph>
              Płacisz i masz plakat w ciągu 48h.{" "}
            </SectionParagraph>
          </CreatorProcessCardText>
        </CreatorProcessCard>
      </CreatorProcessContainer>
    </SectionContainer>
  );
};

export default Section4PurchaseProcess;
