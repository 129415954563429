import styled from "styled-components";

export const CookieBannerContainer = styled.div`
  width: 100%;
  padding: 10px;
  position: fixed;
  bottom: 0;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1111;
  background-color: rgba(75, 75, 75, 0.95);

  svg {
    transform: scale(1.35);
    cursor: pointer;
    stroke-width: 0.75px;
    fill: white;
    color: white;

    @media screen and (max-width: 768px) {
      transform: scale(2.35);
    }
    margin-left: auto;
  }
`;

export const CookieBannerElement = styled.div`
  font-family: "Lexend";
  color: white;

  a {
    margin-left: 5px;
    margin-right: 2px;
  }
`;

export const AgreeButton = styled.button`
  border: none;
  background-color: rgb(0, 140, 255);
  color: white;
  padding: 2px 20px;
  box-sizing: border-box;
  margin-left: 20px;
  cursor: pointer;
`;

export const FreeShippingBanner = styled.div`
  width: 100%;
  background: black;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 22;
  color: white;
  font-family: "Lexend";
  padding: 5px;
  font-size: 1.1rem;
  text-align: center;
`;
