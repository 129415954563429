export const dimensions = {
  posterContainer: {
    width: 1920,
    height: 2678,
  },
  bitcoinLogo: {
    top: 960,
    left: -650,
    width: 254,
    height: 1770,
    fSize: 212,
  },
  toTheMoonBox: {
    left: 744,
    top: 134,
    width: 859,
    height: 782,
    fSize: 269,
    lineHeight: 230,
  },
  upperDate: {
    top: 893,
    left: 202,
    width: 264,
    height: 47,
    fSize: 40,
    lineHeight: 49,
  },
  bottomDate: {
    top: 972.1,
    left: 202,
    width: 264,
    height: 47,
    fSize: 40,
  },
  dateBoxBorder: {
    width: 154.4,
    height: 245,
    left: 186,
    top: 954,
  },
  yellowBall: {
    width: 125,
    height: 125,
    top: 1181,
    left: 186,
    fSize: 20,
  },
  chartContainer: {
    right: 0,
    top: 919,
    width: 1773,
    height: 636.9,
  },
  chartBackgroundDotted: {
    right: 0,
    top: 1150,
    width: 1773,
    height: 436.9,
  },
  chart: {
    left: 236,
    top: 1048,
    width: 1537,
    height: 261.7,
  },
  binanceBitcoinPriceBox: {
    left: 187,
    top: window.innerWidth > 1500 ? 1417 : 1390,
    width: 253,
    height: 31,
    fSize: 26,
  },
  binanceBitcoinPriceValueBox: {
    left: 187,
    top: 1458,
    width: 439,
    height: 129,
    fSize: 108,
  },
  marketDominanceBox: {
    left: 187,
    top: 1616,
    width: 234,
    height: 31,
    fSize: 26,
  },
  marketDominanceValueBox: {
    left: 186,
    top: 1676,
    width: 192,
    height: 78,
    fSize: 56,
  },
  marketCapBox: {
    left: 187,
    top: 1742,
    width: 148,
    height: 31,
    fSize: 26,
  },
  marketCapValueBox: {
    left: 187,
    top: 1803,
    width: 627,
    height: 78,
    fSize: 56,
  },
  marketRankBox: {
    left: 195,
    top: 1980,
    width: 286,
    height: 54,
    fSize: 45,
  },
  marketRankValueBox: {
    left: 502,
    top: 1954,
    width: 121,
    height: 115,
    fSize: 96,
  },
  quoteMark: {
    width: 257,
    height: 183.5,
    top: 203,
    left: 666,
  },
  quoteBox: {
    width: 681,
    height: 614,
    top: 203,
    right: window.innerWidth < 768 ? 179 : 279,
    fSize: 60,
    lineHeight: 72,
  },
  quoteBoxAuthor: {
    width: 454,
    height: 74,
    top: 1974,
    left: 1128,
    fSize: 31,
  },
  footerLogo: {
    width: 127.3,
    height: 31.8,
    top: 2265.2,
    left: 194,
  },
  footerTradeRights: {
    width: 197,
    height: 12,
    top: 2269,
    left: 1385,
    fSize: 10,
  },
  firstInvestmentBox: {
    height: 153,
    width: 525,
    top: 2046,
    left: 698,
    firstInvestmentBoxFirstLineFSize: 55,
    secondInvestmentBoxFirstLineFSize: 45,
  },
  lowPriceBox: {
    top: 2278,
    left: 203,
    width: 349,
    height: 192,
    lowPriceBoxPriceFSize: 81,
    lowPriceBoxTextFSize: 45,
  },
  highPriceBox: {
    top: 2278,
    left: 735,
    width: 452,
    height: 192,
    lowPriceBoxPriceFSize: 81,
    lowPriceBoxTextFSize: 45,
  },
  changeBox: {
    top: 2278,
    left: 1473,
    height: 192,
    width: 317,
    lowPriceBoxPriceFSize: 81,
    lowPriceBoxTextFSize: 45,
  },
  priceBall: {
    width: 100.6,
    height: 100.6,
    fSize: 20,
  },
  changeMark: {
    width: 55,
    height: 48,
    left: 1406,
    top: 2320,
  },
  tradeGraphyUrl: {
    top: 2556,
    left: 203,
    width: 174,
    height: 19,
    fSize: 16,
  },
  tradeGraphyLogo: {
    top: 2535,
    left: 841,
    width: 237,
    height: 60,
  },
  tradeGraphyRights: {
    width: 330,
    height: 19,
    left: 1436,
    top: 2556,
    fSize: 16,
  },
};

export const calculateDimension = (dimension, mode, format, fixer) => {
  let resolutionFixer;
  let calculatedDimension;

  if (window.innerWidth > 768 && mode !== "generate") {
    if (mode == "cart") {
      resolutionFixer = 0.1;
    } else {
      if (window.innerWidth > 3840) {
        resolutionFixer = 1;
      } else if (window.innerWidth == 3840) {
        resolutionFixer = 0.54;
      } else if (window.innerWidth < 3840 && window.innerWidth >= 2560) {
        resolutionFixer = 0.3;
      } else if (window.innerWidth < 2560 && window.innerWidth >= 1920) {
        resolutionFixer = 0.22;
      } else if (window.innerWidth < 1920 && window.innerWidth >= 1600) {
        resolutionFixer = 0.21;
      } else if (window.innerWidth < 1600 && window.innerWidth >= 1536) {
        resolutionFixer = 0.2;
      } else if (window.innerWidth < 1536 && window.innerWidth >= 1440) {
        resolutionFixer = 0.18;
      } else if (window.innerWidth < 1440 && window.innerWidth >= 1366) {
        resolutionFixer = 0.16;
      } else if (window.innerWidth < 1366 && window.innerWidth >= 1280) {
        resolutionFixer = 0.12;
      } else if (window.innerWidth < 1280 && window.innerWidth >= 1152) {
        resolutionFixer = 0.12;
      } else {
        resolutionFixer = 0.2;
      }
    }
  } else {
    resolutionFixer = 0.2;
    mode == "generate" ? (resolutionFixer = 0.41) : (resolutionFixer = 0.2);
  }

  calculatedDimension = fixer
    ? `${
        dimension * resolutionFixer -
        (fixer > 11 && fixer <= 20
          ? fixer - fixer / 3
          : fixer > 20
          ? fixer - fixer / 7
          : 0)
      }`
    : dimension * resolutionFixer;

  return calculatedDimension;
};

export const CalculateMainSloganFontSize = (slogan, fontSize) => {
  let calculatedFontSize;
  let wordsAmount = slogan.split(" ").length;
  const words = slogan.split(" ");
  let maxWordLength;

  const longer = (champ, contender) => {
    return contender.length > champ.length ? contender : champ;
  };

  if (wordsAmount > 1) {
    maxWordLength = words.reduce(longer).length;
  } else {
    maxWordLength = words[0].length;
  }

  if (slogan.length >= 30) {
    if (maxWordLength === 30) {
      calculatedFontSize = fontSize / 6;
    } else {
      calculatedFontSize = fontSize / 2;
    }
  }

  if (slogan.length < 30 && slogan.length >= 20) {
    calculatedFontSize = fontSize / 1.6;

    if (maxWordLength >= 7 && maxWordLength <= 10) {
      calculatedFontSize = fontSize / 1.9;
    } else if (maxWordLength >= 11 && maxWordLength <= 15) {
      calculatedFontSize = fontSize / 2.7;
    } else if (maxWordLength > 15 && maxWordLength < 20) {
      calculatedFontSize = fontSize / 3.5;
    } else if (maxWordLength >= 20 && maxWordLength < 30) {
      calculatedFontSize = fontSize / 5.5;
    } else {
      calculatedFontSize = fontSize / 1.6;
    }
  }

  if (slogan.length > 15 && slogan.length < 20) {
    if (maxWordLength >= 7 && maxWordLength <= 10) {
      calculatedFontSize = fontSize / 1.9;
    } else if (maxWordLength >= 11 && maxWordLength <= 15) {
      calculatedFontSize = fontSize / 2.7;
    } else if (maxWordLength > 15 && maxWordLength < 20) {
      calculatedFontSize = fontSize / 3.5;
    } else {
      calculatedFontSize = fontSize / 1.5;
    }
  }

  if (slogan.length >= 11 && slogan.length <= 15) {
    if (maxWordLength >= 7 && maxWordLength <= 10) {
      calculatedFontSize = fontSize / 1.9;
    } else if (maxWordLength >= 11 && maxWordLength <= 15) {
      calculatedFontSize = fontSize / 2.7;
    } else {
      calculatedFontSize = fontSize / 1.3;
    }
  }

  if (slogan.length > 6 && slogan.length <= 10) {
    if (maxWordLength >= 7) {
      calculatedFontSize = fontSize / 1.9;
    } else {
      calculatedFontSize = fontSize / 1.3;
    }
  }

  if (slogan.length <= 6) {
    calculatedFontSize = fontSize / 1.2;
  }

  return calculatedFontSize;
};
