import styled from "styled-components";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { HashLink as Link } from "react-router-hash-link";

import mainTheme from "../../../theme/mainTheme.jsx";
import {
  dimensionWidthFix,
  dimensionHeightFix,
} from "../../../utils/dimensionFixer";
import { GiHamburgerMenu } from "react-icons/gi";
import { IoCloseSharp } from "react-icons/io5";

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;

  @media (max-width: 768px) {
    grid-template-columns: 5vw auto 5vw auto;
  }

  @media (min-width: 2000px) {
    width: 80vw;
    margin: 0 auto;
  }
`;

// ***** *** 1. SHARED styles ***** ***

export const SectionHeader = styled.h1`
  padding: "unset";
  font-family: ${mainTheme.font.headerFont};
  color: ${mainTheme.colors.textMainBlack};
  font-size: ${mainTheme.font.size.bigHeader}px;
  font-weight: ${mainTheme.font.weight.normal};
  width: 100%;
  align-self: ${(props) => (props.alignSelf ? props.alignSelf : "unset")};
  z-index: 1;
  margin-block-start: 0em;
  margin-block-end: 0em;
  box-sizing: border-box;
  line-height: normal;
  margin-top: ${(props) =>
    props.mTop ? dimensionHeightFix(props.mTop) + "px" : "unset"};

  max-width: 50%;

  @media (max-width: 768px) {
    font-size: 3rem;
  }

  @media (min-width: 769px) and (max-width: 950px) {
    font-size: 2.5rem;
  }

  @media (min-width: 951px) and (max-width: 1100px) {
    font-size: 2.4rem;
  }
  @media (min-width: 1101px) and (max-width: 1366px) {
    font-size: 2.9rem;
  }

  @media (min-width: 1366px) and (max-width: 1480px) {
    font-size: 3.6rem;
  }
  @media (min-width: 1481px) and (max-width: 1650px) {
    font-size: 3.8rem;
  }

  @media (min-width: 1651px) and (max-width: 2000px) {
    font-size: 4rem;
  }
  @media (min-width: 2001px) and (max-width: 2600px) {
    font-size: 5.6rem;
  }
  @media (min-width: 2601px) {
    font-size: 8rem;
  }

  &.full-width {
    width: 100%;
    max-width: unset;
  }

  &.center-text {
    text-align: center;
  }

  @media (max-width: 768px) {
    width: 100%;

    &.section3-header {
      margin-top: 30px;
    }
  }
`;

export const SectionParagraph = styled.p`
  font-family: ${mainTheme.font.paragraphFont};
  font-size: ${mainTheme.font.size.paragraph}px;
  font-weight: ${mainTheme.font.weight.normal};
  max-width: ${(props) =>
    props.maxWidth ? dimensionWidthFix(props.maxWidth) + "px" : "unset"};
  height: ${(props) =>
    props.height ? dimensionHeightFix(props.height) + "px" : "unset"};
  font-stretch: normal;
  font-style: normal;
  line-height: 2.2rem;
  z-index: 1;
  margin-top: ${(props) =>
    props.mTop ? dimensionHeightFix(props.mTop) + "px" : "unset"};
  margin-left: ${(props) =>
    props.mLeft ? dimensionWidthFix(props.mLeft) + "px" : "unset"};
  margin-right: ${(props) =>
    props.mRight ? dimensionWidthFix(props.mRight) + "px" : "unset"};
  align-self: ${(props) => (props.alignSelf ? props.alignSelf : "unset")};
  /* line-height: initial; */
  @media (max-width: 768px) {
    font-size: 1.15rem;
    line-height: 1.65rem;
    width: 100%;
  }

  /* @media (max-width: 769px) and (max-width: 950px) {
    font-size: 0.9rem;
  } */

  @media (min-width: 951px) and (max-width: 1100px) {
    font-size: 1.1rem;
  }
  @media (min-width: 1101px) and (max-width: 1366px) {
    font-size: 1.2rem;
  }

  @media (min-width: 1366px) and (max-width: 1480px) {
    font-size: 1.3rem;
  }
  @media (min-width: 1481px) and (max-width: 1650px) {
    font-size: 1.4rem;
  }

  @media (min-width: 1651px) and (max-width: 2000px) {
    font-size: 1.5rem;
  }
  @media (min-width: 2001px) and (max-width: 2600px) {
    font-size: 1.65rem;
  }
  @media (min-width: 2601px) {
    font-size: 2.1rem;
  }

  &.first-section-paragraph {
    max-width: 50%;
  }

  &.paragraph-1-section-3 {
    max-width: 80%;
    @media screen and (max-width: 1490px) {
      max-width: 80%;
    }
  }

  @media (max-width: 768px) {
    &.first-section-paragraph {
      max-width: 100%;
    }

    &.paragraph-1-section-3,
    &.paragraph-2-section-3 {
      width: 45%;
      max-width: 45%;
      margin-top: unset;
      height: unset;

      @media (max-width: 768px) {
        width: 100% !important;
        max-width: 100%;
      }
    }
    &.paragraph-2-section-3 {
      margin-top: 30px;
    }

    &.testimonial-paragraph {
      width: 90%;
    }
  }
`;

export const SectionBackground = styled.img`
  position: absolute;
  width: ${(props) =>
    props.width ? dimensionWidthFix(props.width) + "px" : "unset"};
  height: ${(props) =>
    props.height ? dimensionHeightFix(props.height) + "px" : "unset"};
  z-index: 0;
  right: ${(props) =>
    props.right ? dimensionWidthFix(props.right) + "px" : "unset"};
  left: ${(props) =>
    props.left ? dimensionWidthFix(props.left) + "px" : "unset"};
  top: ${(props) =>
    props.top ? dimensionHeightFix(props.top) + "px" : "unset"};
  bottom: ${(props) =>
    props.bottom ? dimensionHeightFix(props.bottom) + "px" : "unset"};
  z-index: 0;

  @media (max-width: 768px) {
    height: auto;
    width: ${(props) =>
      props.width ? dimensionWidthFix(props.width) * 1.2 + "px" : "unset"};

    &.section-1-img-1 {
      right: 0 !important;
    }
  }

  &.section-1-img-1 {
    width: 48%;
    height: auto;
    right: 13%;
  }
`;

export const SectionButton = styled.button`
  font-family: "Lexend";
  font-size: ${mainTheme.font.size.button}px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: ${mainTheme.colors.textMainWhite};
  background-color: ${(props) =>
    props.noBackground ? "transparent" : mainTheme.colors.accentBlue};
  border: none;
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.39, 0.575, 0.565, 1);
  padding: ${(props) =>
    props.noPadding ? "0px" : props.padding ? props.padding : "26px 39px"};
  width: ${(props) =>
    props.width ? dimensionWidthFix(props.width) + "px" : "fit-content"};
  height: ${(props) =>
    props.height ? dimensionHeightFix(props.height) + "px" : "fit-content"};
  margin-top: ${(props) =>
    props.mTop ? dimensionHeightFix(props.mTop) + "px" : "unset"};

  background-image: ${(props) =>
    props.image ? `url(${props.image})` : "unset"};
  background-size: ${(props) => (props.image ? "contain" : "unset")};
  background-repeat: ${(props) => (props.image ? "no-repeat" : "unset")};
  background-position: ${(props) => (props.image ? "center" : "unset")};
  align-self: ${(props) => (props.alignSelf ? props.alignSelf : "unset")};
  /* width: ${(props) => (props.image ? "37.8px" : "unset")};
  height: ${(props) => (props.image ? "35.7px" : "unset")}; */
  text-align: center;

  &.reveal-poster {
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      margin-right: 20px;
    }
    svg {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
  &.mobile-button {
    width: 90%;
    font-size: 1rem;
    margin-top: 3%;
    margin-left: unset;
  }
  &.disabled {
    opacity: 0.3;
    &:hover {
      transform: scale(1);
      cursor: not-allowed;
    }
  }

  &:hover {
    transform: scale(1.05);
  }

  @media (max-width: 768px) {
    margin-top: 30px;
    width: 100%;

    text-align: center;
    &.full-width {
      width: 100%;
      text-align: center;
    }

    &.mt-40 {
      margin-top: 60px;
    }
  }
`;

export const SectionContainer = styled.section`
  display: flex;
  flex-direction: ${(props) => (props.flexDir ? props.flexDir : "column")};
  grid-row-start: ${(props) =>
    props.gridRowStart ? props.gridRowStart : "unset"};
  grid-column-start: ${(props) =>
    props.gridColumnStart ? props.gridColumnStart : "1"};
  grid-column-end: ${(props) =>
    props.gridColumnEnd ? props.gridColumnEnd : "3"};
  position: relative;
  margin-top: ${(props) =>
    props.mTop ? dimensionHeightFix(props.mTop) + "px" : "unset"};
  /* width: max-content; */
  /* padding: ${(props) => (props.padding ? props.padding : "unset")}; */
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : "unset"};
  width: 100%;
  max-width: 100%;
  padding: 0 13%;

  &.section5 {
    max-width: 100vw;
  }

  @media (max-width: 768px) {
    max-width: 100vw;
    padding: 0 6%;

    &.no-margin-top {
      margin-top: 0;
    }

    &.flex-column {
      flex-direction: column;
    }
    &.small-padding {
      padding: 0 5vw 0 5vw;
    }

    &.section4 {
      h1 {
        max-width: 100%;
      }
    }

    &.section1 {
      margin-bottom: 40px;

      h1 {
        order: 0;
        width: 100%;
        max-width: 100%;
        margin-bottom: 20px;
      }

      img {
        order: 1;
        width: 100%;
        position: relative;
      }

      .first-section-paragraph {
        order: 2;
      }

      button {
        display: none;

        &.full-width {
          display: block;
          order: 3;
        }
      }
    }

    &.section3 {
      div {
        &:nth-of-type(1) {
          img {
            width: 100%;
          }
          .flex-row {
            flex-direction: column;
          }
          .paragraph-1-section-3 {
            width: 100%;
            max-width: 100%;
            margin: 20px 0;
          }
        }

        &:nth-of-type(2) {
          img {
            width: 100%;
          }
          .flex-row {
            flex-direction: column;
          }
          .paragraph-2-section-3 {
            width: 100%;
            max-width: 100%;
            margin: 20px 0 0 0;
          }
        }
      }
    }
  }
`;

export const ContainerColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: ${(props) => (props.width ? dimensionWidthFix(props.width) : "50%")};

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const SectionImage = styled.img`
  width: ${(props) =>
    props.width ? dimensionWidthFix(props.width) + "px" : "fit-content"};
  height: ${(props) =>
    props.height ? dimensionHeightFix(props.height) + "px" : "fit-content"};
  align-self: ${(props) => (props.alignSelf ? props.alignSelf : "unset")};
  margin-top: ${(props) =>
    props.mTop ? dimensionHeightFix(props.mTop) + "px" : "unset"};
  position: ${(props) => (props.position ? props.position : "unset")};
  left: ${(props) =>
    props.left ? dimensionWidthFix(props.left) + "px" : "unset"};

  &.bottom-dots-image {
    width: 100vw;
  }

  &.image-1-section-3,
  &.image-2-section-2 {
    width: 85%;
    height: auto;
    align-self: center;
  }
  &.section-6-image {
    width: 100%;
  }
  &.footer-image {
    width: 150px;
  }

  @media (max-width: 768px) {
    height: auto !important;

    &.image-1-section-3,
    &.image-2-section-2 {
      width: 45%;
      height: auto;
      align-self: center;
    }
    &.section-6-image {
      width: 100%;
      height: 300px !important;
    }
    &.footer-image {
      width: 150px;
    }
  }

  @media (min-width: 769px) {
    height: auto !important;
  }
`;

export const MobileContainer = styled.div`
  @media (max-width: 768px) {
    &.flex-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    &.paragraph-1-section-3 {
      width: 50%;
    }
  }
`;

// ***** *** 1. NavigationHeader styles ***** ***

export const NavigationHeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 1;
  margin-top: ${dimensionHeightFix(31)}px;
  margin-bottom: ${dimensionHeightFix(95)}px;
  transition: all 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
  position: ${(props) => (props.position ? props.position : "unset")};
  left: ${(props) =>
    props.position === "fixed" ? dimensionWidthFix(255) + "px" : "unset"};

  position: relative;

  &.full-width {
    @media (min-width: 769px) {
      left: 0;
      top: -5vh;
      width: 100%;
    }
  }

  &.absolute-pos {
    position: absolute;
    top: 0px;
    z-index: 3;

    @media (max-width: 768px) {
      z-index: 2225;
    }
  }

  @media (max-width: 768px) {
    position: relative;
    flex-direction: column;
    height: 100vh;
    width: 100vw;
    position: fixed;
    left: ${(props) => (props.isMenuOpen ? "0vw" : "-100vw")};
    z-index: 22222;
    background-color: ${(props) =>
      props.theme && props.theme === "dark" ? "black" : "white"};
    /* background-color: ${mainTheme.colors.white}; */
    margin-top: 0;
    top: 0;
    justify-content: flex-start;
  }

  @media (min-width: 769px) {
    left: 13%;
    top: 4vh;
    width: 74%;
  }
`;

export const Logo = styled.img`
  width: 11vw;
  /* height: ${dimensionHeightFix(55.1)}px; */
  align-self: center;

  @media (max-width: 769px) {
    width: 30vw;
  }

  &.desktop-logo {
    @media (max-width: 768px) {
      display: none;
    }
  }
`;

export const HeaderNavButtonsContainer = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
export const HeaderNavButton = styled.button`
  font-family: "Lexend";
  font-size: ${mainTheme.font.size.button}px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  position: relative;
  letter-spacing: normal;
  text-align: ${(props) => (props.tAlign ? props.tAlign : `left`)};
  color: ${(props) =>
    props.theme === "light"
      ? mainTheme.colors.textMainBlack
      : mainTheme.colors.textMainWhite};
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin: ${(props) => (props.margin ? props.margin : `0 3%`)};
  transition: all 0.5s cubic-bezier(0.39, 0.575, 0.565, 1);

  background-image: ${(props) =>
    props.image ? `url(${props.image})` : "unset"};

  background-size: ${(props) => (props.image ? "contain" : "unset")};
  background-repeat: ${(props) => (props.image ? "no-repeat" : "unset")};
  background-position: ${(props) => (props.image ? "center" : "unset")};
  width: ${(props) =>
    props.image && !props.width
      ? `${dimensionWidthFix(37.8)}px`
      : props.width
      ? props.width
      : "unset"};
  height: ${(props) =>
    props.image && !props.height
      ? `${dimensionHeightFix(35.7)}px`
      : props.height
      ? props.height
      : "unset"};
  margin-right: ${(props) => (props.marginRight ? props.marginRight : `1vw`)};
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : "auto")};

  @media (max-width: 768px) {
    font-size: 1.2rem;
    margin: ${(props) => (props.margin ? props.margin : `0 3%`)};
  }

  @media (min-width: 769px) and (max-width: 1365px) {
    font-size: 0.8rem;
  }

  @media (min-width: 1366px) and (max-width: 1480px) {
    font-size: 0.9rem;
  }
  @media (min-width: 1481px) and (max-width: 1650px) {
    font-size: 1rem;
  }

  @media (min-width: 1651px) and (max-width: 2000px) {
    font-size: 1.2rem;
  }
  @media (min-width: 2001px) and (max-width: 2600px) {
    font-size: 1.4rem;
  }
  @media (min-width: 2601px) {
    font-size: 1.6rem;
  }

  .cart-items {
    position: absolute;
    right: -1rem;
    top: -1rem;
    color: #008cff;
    font-size: 1.5rem;
  }

  &.background-blue-button {
    background-color: ${mainTheme.colors.accentBlue};
    padding: 26px 39px;
    color: ${mainTheme.colors.textMainWhite};

    &:hover {
      transform: scale(1.05);
    }
  }

  @media (max-width: 768px) {
    margin: 30px 0;
    margin: ${(props) => (props.margin ? props.margin : `0 3%`)};
  }
`;

export const HeaderMobileLogoAndButtonContainer = styled.div`
  display: flex;
  width: ${(props) => (props.width ? props.width : "100%")};
  padding: 10px;
  margin-top: 7%;
  box-sizing: border-box;
  justify-content: ${(props) =>
    props.justify ? props.justify : "space-between"};
  align-items: center;
  position: relative;

  &.left-pos {
    left: -10%;
  }

  @media (min-width: 769px) {
    display: none;
  }
`;

// ***** *** 1. SECTION 1 CHART POSTER styles ***** ***

// ***** *** 2. SECTION 2 PROCESS styles ***** ***

export const CreatorProcessContainer = styled.div`
  width: 100%;
  display: flex;
  margin-top: ${(props) =>
    props.mTop ? dimensionHeightFix(props.mTop) + "px" : "unset"};
  justify-content: space-around;
  align-items: baseline;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const CreatorProcessCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 12vw;

  &:last-of-type {
    margin-right: 0px;
  }

  @media (max-width: 768px) {
    width: 100%;
    align-items: center;
    margin-right: 0;
  }
`;

export const CreatorProcessCardIcon = styled.div`
  background-image: ${(props) =>
    props.image ? `url(${props.image})` : "unset"};
  background-size: ${(props) => (props.image ? "contain" : "unset")};
  background-repeat: ${(props) => (props.image ? "no-repeat" : "unset")};
  background-position: ${(props) => (props.image ? "center" : "unset")};
  align-self: ${(props) => (props.alignSelf ? props.alignSelf : "unset")};
  width: ${(props) =>
    props.width ? dimensionWidthFix(props.width) + "px" : "fit-content"};
  height: ${(props) =>
    props.height ? dimensionHeightFix(props.height) + "px" : "fit-content"};
  margin-top: ${(props) =>
    props.mTop ? dimensionHeightFix(props.mTop) + "px" : "unset"};

  width: 7vw;
  height: 7vw;

  @media (min-width: 768px) and (max-width: 1100px) {
    width: 70%;
  }

  @media (max-width: 768px) {
    width: 55px;
    height: 55px;

    &.purchase-process-icon {
      width: 35%;
    }
  }
`;

export const CreatorProcessCardHeader = styled.div`
  font-family: ${mainTheme.font.headerFont};
  font-size: ${mainTheme.font.size.smallHeader}px;
  color: ${mainTheme.colors.textMainBlack};
  font-weight: ${mainTheme.font.weight.semiBold};
  margin-top: 2vw;

  @media (max-width: 768px) {
    font-size: 1.2rem;
  }

  @media (min-width: 769px) and (max-width: 1365px) {
    font-size: 1.4rem;
  }

  @media (min-width: 1366px) and (max-width: 1480px) {
    font-size: 1.6rem;
  }
  @media (min-width: 1481px) and (max-width: 1650px) {
    font-size: 1.8rem;
  }

  @media (min-width: 1651px) and (max-width: 2000px) {
    font-size: 1.9rem;
  }
  @media (min-width: 2001px) and (max-width: 2600px) {
    font-size: 2.1rem;
  }
  @media (min-width: 2601px) {
    font-size: 2.3rem;
  }

  @media (max-width: 768px) {
    text-align: center;
  }
`;

export const CreatorProcessCardText = styled.div`
  margin-top: ${(props) =>
    props.mTop
      ? dimensionHeightFix(props.mTop) + "px"
      : dimensionHeightFix(13) + "px"};

  @media (max-width: 768px) {
    text-align: center;
  }
`;

export const VideoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  width: 80%;
  height: 44vh;
  margin-top: 6vw;
  background-color: ${mainTheme.colors.placeholderColor};

  @media (max-width: 768px) {
    width: 100%;
    height: calc(100vw / 2);
    margin-top: 50px;
  }
`;

export const VideoContainerIcon = styled.div`
  background-image: ${(props) =>
    props.image ? `url(${props.image})` : "unset"};
  background-size: ${(props) => (props.image ? "contain" : "unset")};
  background-repeat: ${(props) => (props.image ? "no-repeat" : "unset")};
  background-position: ${(props) => (props.image ? "center" : "unset")};
  align-self: ${(props) => (props.alignSelf ? props.alignSelf : "unset")};
  width: ${(props) =>
    props.width ? dimensionWidthFix(props.width) + "px" : "fit-content"};
  height: ${(props) =>
    props.height ? dimensionHeightFix(props.height) + "px" : "fit-content"};
`;

// ***** *** 3. SECTION 3 ABOUT styles ***** ***

export const CardRoundedIconBackground = styled.div`
  width: 12vw;
  height: 12vw;
  background-color: ${mainTheme.colors.testimonialBackgroundCOlor};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    width: 150px;
    height: 150px;
  }
`;

// ***** *** 5. SECTION 5 TESTIMONIALSstyles ***** ***

export const TestimonialContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: ${dimensionHeightFix(60)}px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const TestimonialCard = styled.div`
  background-color: ${mainTheme.colors.white};
  width: 30%;
  margin-right: ${dimensionWidthFix(30)}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: 2%;
  height: fit-content;

  &:last-of-type {
    margin-right: 0px;
  }

  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 10px;
  }
`;

export const TestimonialText = styled.div`
  width: 100%;
  margin-top: 20px;
  display: flex;
  text-align: left;

  @media (max-width: 768px) {
    width: 90%;
  }
`;

export const TestimoniaAuthorInfoContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  text-align: left;
  padding: 2%;
  box-sizing: border-box;

  @media (max-width: 768px) {
    width: 90%;
  }
`;
export const TestimonialAuthorImage = styled.div`
  background-image: ${(props) =>
    props.image ? `url(${props.image})` : "unset"};
  background-size: ${(props) => (props.image ? "contain" : "unset")};
  background-repeat: ${(props) => (props.image ? "no-repeat" : "unset")};
  background-position: ${(props) => (props.image ? "center" : "unset")};
  align-self: ${(props) => (props.alignSelf ? props.alignSelf : "unset")};
  width: ${(props) =>
    props.width ? dimensionWidthFix(props.width) + "px" : "fit-content"};
  height: ${(props) =>
    props.height ? dimensionHeightFix(props.height) + "px" : "fit-content"};
  border-radius: 50%;

  @media (max-width: 768px) {
    width: 40px;
    height: 40px;
  }
`;

export const TestimonialAuthorNameAndPositionContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-direction: column;
  height: 100%;
  margin-left: ${dimensionWidthFix(14)}px;
  text-align: left;
`;

export const TestimonialAuthorName = styled.div`
  font-family: ${mainTheme.font.paragraphFont};
  font-weight: ${mainTheme.font.weight.bold};
  font-size: ${mainTheme.font.size.paragraph}px;
  color: ${mainTheme.colors.textMainBlack};
  line-height: ${dimensionHeightFix(27)}px;
`;

export const TestimonialAuthorPosition = styled.div`
  font-family: ${mainTheme.font.paragraphFont};
  font-weight: ${mainTheme.font.weight.bold};
  font-size: ${mainTheme.font.size.paragraph}px;
  color: ${mainTheme.colors.accentBlue};
  line-height: ${dimensionHeightFix(27)}px;
`;

export const TestiCarousel = styled(Carousel)`
  display: flex;
  flex-direction: column;

  .carousel-slider {
    display: flex;
    flex-direction: column;
    .control-dots {
      position: relative;
      width: 100%;
      left: 0%;
      margin-top: ${dimensionHeightFix(60)}px;
      order: 2;

      .dot {
        width: 1vw;
        height: 1vw;
        background-color: ${mainTheme.colors.dotDarkGrey};

        @media (max-width: 768px) {
          width: 15px;
          height: 15px;
        }
        &.selected {
          background-color: ${mainTheme.colors.textMainBlack};
        }
      }
    }
  }
`;

// ***** *** 6. SECTION 6 MODS styles ***** ***

export const ImagesRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: ${(props) =>
    props.mTop ? dimensionHeightFix(props.mTop) + "px" : "unset"};
`;

export const MediumHeader = styled.div`
  font-family: ${mainTheme.font.headerFont};
  font-size: ${mainTheme.font.size.mediumHeader}px;
  font-weight: ${mainTheme.font.weight.normal};
  color: ${mainTheme.colors.textMainBlack};
  max-width: ${(props) =>
    props.maxWidth ? dimensionWidthFix(props.maxWidth) + "px" : "unset"};
  align-self: ${(props) => (props.alignSelf ? props.alignSelf : "unset")};
  z-index: 1;
  margin-block-start: 0em;
  margin-block-end: 0em;
  box-sizing: border-box;
  margin-top: ${(props) =>
    props.mTop ? dimensionHeightFix(props.mTop) + "px" : "unset"};
  width: ${(props) =>
    props.customWidth ? dimensionWidthFix(props.customWidth) + "px" : "unset"};
  text-align: ${(props) => (props.textAlign ? props.textAlign : "unset")};

  &.mobile {
    width: 88%;
    max-width: 88%;
    margin-bottom: 20px;
  }
`;

// ***** *** 7.FOOTER styles ***** ***

export const FooterContainer = styled.footer`
  display: flex;
  flex-direction: ${(props) => (props.flexDir ? props.flexDir : "row")};
  grid-row-start: ${(props) =>
    props.gridRowStart ? props.gridRowStart : "unset"};
  grid-column-start: ${(props) =>
    props.gridColumnStart ? props.gridColumnStart : "unset"};
  grid-column-end: ${(props) =>
    props.gridColumnEnd ? props.gridColumnEnd : "unset"};
  position: relative;
  margin-top: ${(props) =>
    props.mTop ? dimensionHeightFix(props.mTop) + "px" : "unset"};
  /* width: max-content; */
  padding: 0 13%;
  max-width: 100vw;
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : "unset"};

  @media (max-width: 768px) {
    padding: 0 6% 5% 6%;
  }

  @media (min-width: 2000px) {
    width: 100vw;
  }
`;

export const FooterColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${(props) =>
    props.mTop ? dimensionHeightFix(props.mTop) + "px" : "unset"};
  margin-left: ${(props) =>
    props.mLeft
      ? dimensionWidthFix(props.mLeft) + "px"
      : props.mLeftAuto
      ? "auto"
      : "unset"};
  margin-right: ${(props) =>
    props.mRight ? dimensionWidthFix(props.mRight) + "px" : "unset"};
  margin-bottom: ${(props) =>
    props.mBottom ? dimensionHeightFix(props.mBottom) + "px" : "unset"};
  justify-content: ${(props) => (props.justify ? props.justify : "unset")};
  align-items: ${(props) => (props.alignItems ? props.alignItems : "unset")};
  max-width: ${(props) =>
    props.maxWidth ? dimensionWidthFix(props.maxWidth) + "px" : "unset"};
`;

export const FooterRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  flex-direction: row;
  margin-top: ${(props) =>
    props.mTop ? dimensionHeightFix(props.mTop) + "px" : "unset"};
  margin-left: ${(props) =>
    props.mLeft
      ? dimensionWidthFix(props.mLeft) + "px"
      : props.mLeftAuto
      ? "auto"
      : "unset"};
  margin-right: ${(props) =>
    props.mRight ? dimensionWidthFix(props.mRight) + "px" : "unset"};
  margin-bottom: ${(props) =>
    props.mBottom ? dimensionHeightFix(props.mBottom) + "px" : "unset"};
  justify-content: ${(props) => (props.justify ? props.justify : "unset")};
  align-items: ${(props) => (props.alignItems ? props.alignItems : "unset")};
  justify-content: space-around;

  padding-top: 2%;
  &.footer-last-row {
    justify-content: space-evenly !important;
    width: 100%;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
    margin-top: 30px;

    &.flex-row {
      flex-direction: row;
      margin-left: unset;
    }
    &.mobile-footer-icons {
      justify-content: space-evenly;
      margin-bottom: 10px;

      .social-icon {
        width: 25px;
        height: 25px;

        @media (max-width: 768px) {
          margin: 0 auto;
          &.insta {
            width: 45px;
            height: 45px;
          }
        }
      }
    }
  }
`;

export const FooterColumnTitle = styled.div`
  margin-top: ${(props) =>
    props.mTop ? dimensionHeightFix(props.mTop) + "px" : "unset"};
  font-family: ${mainTheme.font.headerFont};
  font-size: ${mainTheme.font.size.button}px;
  font-weight: ${mainTheme.font.weight.normal};
  line-height: 1.31;
  color: ${mainTheme.colors.textMainWhite};
`;

export const FooterText = styled.div`
  margin-top: ${(props) =>
    props.mTop ? dimensionHeightFix(props.mTop) + "px" : "unset"};
  font-family: ${mainTheme.font.headerFont};
  font-size: ${mainTheme.font.size.footer}px;
  font-weight: ${mainTheme.font.weight.normal};

  color: ${mainTheme.colors.textMainWhite};
  color: rgba(255, 255, 255, 0.5);
  line-height: 1.5;

  a {
    color: rgba(255, 255, 255, 0.5);

    &:hover {
      color: rgba(255, 255, 255, 0.7);
    }
  }
`;

export const NewsletterInput = styled.input`
  color: ${mainTheme.colors.textMainWhite};
  background-color: ${mainTheme.colors.footerBackgroundColor};
  border: 1px solid rgba(255, 255, 255, 0.5);
  margin-top: 1px;
  height: ${dimensionHeightFix(64)}px;
  width: ${dimensionWidthFix(231)}px;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  font-size: ${mainTheme.font.size.footer}px;
  font-weight: ${mainTheme.font.weight.normal};
  font-family: ${mainTheme.font.headerFont};
  line-height: 0.86;
  color: rgba(255, 255, 255, 0.5);
  border-radius: 0;
  border-right: none;
  outline: none;
  padding-left: ${dimensionWidthFix(17)}px;

  @media (max-width: 768px) {
    width: 70%;
  }
`;

export const SubscribeButton = styled.button`
  background-color: white;
  height: ${dimensionHeightFix(66)}px;
  box-sizing: border-box;
  width: ${dimensionWidthFix(127)}px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  font-size: ${mainTheme.font.size.footer}px;
  font-weight: ${mainTheme.font.weight.normal};
  font-family: ${mainTheme.font.headerFont};
  border: 1px solid black;
  cursor: pointer;

  @media (max-width: 768px) {
    width: 30%;
  }
`;

export const FooterSocialIcon = styled.div`
  background-image: ${(props) =>
    props.image ? `url(${props.image})` : "unset"};
  background-size: ${(props) => (props.image ? "contain" : "unset")};
  background-repeat: ${(props) => (props.image ? "no-repeat" : "unset")};
  background-position: ${(props) => (props.image ? "center" : "unset")};
  align-self: ${(props) => (props.alignSelf ? props.alignSelf : "unset")};
  width: ${(props) =>
    props.width ? dimensionWidthFix(props.width) + "px" : "fit-content"};
  height: ${(props) =>
    props.height ? dimensionHeightFix(props.height) + "px" : "fit-content"};
  margin-right: ${(props) =>
    props.mRight ? dimensionWidthFix(props.mRight) + "px" : "unset"};
  cursor: pointer;
  &.insta {
    opacity: 0.67;
  }
`;

export const HamburgerIcon = styled(GiHamburgerMenu)`
  width: 35px;
  height: 35px;
  margin: 10px;
  fill: ${(props) =>
    props.theme && props.theme == "dark" ? "white" : "black"};

  @media (min-width: 769px) {
    display: none;
  }
`;

export const CloseIcon = styled(IoCloseSharp)`
  width: 35px;
  height: 35px;
  fill: ${(props) =>
    props.theme && props.theme == "dark" ? "white" : "black"};

  @media (min-width: 769px) {
    display: none;
  }
`;

export const PosterPageMobileNavContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 10vh;
  box-sizing: border-box;
  padding: 2%;
  background-color: ${(props) =>
    props.theme && props.theme == "dark" ? "black" : "white"};
  @media (min-width: 769px) {
    display: none;
  }
`;

export const LinkTo = styled(Link)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const HeaderCartContainer = styled.div`
  font-family: "Lexend";
  .cart-items {
    position: absolute;
    right: 5px;
    top: -5px;
    color: #008cff;
    font-size: 1.5rem;
    font-weight: bold;
  }
`;

export const PolicyContainer = styled.div`
  font-family: "Lexend";
  padding: 2% 8%;
  line-height: 1.6rem;

  ol {
    li {
      &.bold {
        font-weight: bold;
      }
    }
  }
`;
export const MainPageBox = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;
