import styled from "styled-components";
import { Switch, Checkbox } from "antd";
import "antd/dist/antd.css";
import Calendar from "react-calendar";
import DatePicker from "react-date-picker";
import { isMobile } from "react-device-detect";
import { FiX } from "react-icons/fi";
import ChangeMark from "../../../assets/change-mark-icon.svg";

import {
  dimensionWidthFix,
  dimensionHeightFix,
  fontSizeFixer,
} from "../../../utils/dimensionFixer";
import closeButtonIcon from "../../../assets/closeModalIcon.svg";
import rightArrow from "../../../assets/right-arrow.png";
import leftArrow from "../../../assets/left-arrow.png";
import darkImage from "../../../assets/dark-poster-image.png";
import lightImage from "../../../assets/light-poster-image.png";

export const settingsModalStyles = {
  content: {
    border: "none",
    top: "auto",
    left: "0",
    right: "auto",
    bottom: "0",
    marginRight: "-50%",
    padding: "0",
    width: isMobile ? "90%" : "unset",
    height: isMobile ? "90%" : "unset",
    boxShadow: `2px 0px 2.2px rgba(0, 0, 0, 0.011),
  4.8px 0px 5.3px rgba(0, 0, 0, 0.019),
  9px 0px 10px rgba(0, 0, 0, 0.027),
  16.1px 0px 17.9px rgba(0, 0, 0, 0.036),
  30.1px 0px 33.4px rgba(0, 0, 0, 0.048),
  72px 0px 80px rgba(0, 0, 0, 0.07)`,
  },
  overlay: {
    backgroundColor: `rgba(225, 225, 225, 0.9)`,
    zIndex: "25",
  },
};

export const SettingsContainer = styled.div`
  width: 50%;
  position: relative;
  margin-right: auto;

  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: ${(props) =>
    props.theme && props.theme === "dark" ? "black" : "white"};

  @media (max-width: 768px) {
    width: 100%;
    max-width: 100vw;
    box-sizing: border-box;
    padding: 5%;
    height: 90vh;
  }
`;

export const SettingsRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${(props) => (props.justify ? props.justify : "unset")};
  align-items: ${(props) => (props.alignItems ? props.alignItems : "unset")};
  align-self: ${(props) => (props.alignSelf ? props.alignSelf : "unset")};
  width: ${(props) =>
    props.width
      ? dimensionWidthFix(props.width) + "px"
      : props.widthPercentage
      ? props.widthPercentage
      : "100%"};

  margin-right: ${(props) =>
    props.marginRight && props.staticMargin
      ? props.staticMargin
      : props.marginRight === "auto"
      ? "auto"
      : dimensionWidthFix(props.marginRight) + "px"};
  margin-top: ${(props) =>
    props.marginTop ? dimensionHeightFix(props.marginTop) + "px" : "unset"};
  margin-bottom: ${(props) =>
    props.marginBottom
      ? dimensionHeightFix(props.marginBottom) + "px"
      : "unset"};
  border-top: ${(props) =>
    props.showTopBorder && props.theme == "light"
      ? `1px solid #d6e6f6`
      : props.showTopBorder && props.theme == "dark"
      ? "1px solid rgba(255,255,255,0.2)"
      : "unset"};
  padding: ${(props) => (props.padding ? props.padding : "unset")};
  margin: ${(props) => (props.margin ? props.margin : "unset")};
  margin-left: ${(props) =>
    props.marginLeft && props.staticMargin
      ? props.staticMargin
      : props.marginLeft === "auto"
      ? "auto"
      : dimensionWidthFix(props.marginLeft) + "px"};

  text-align: ${(props) => (props.textAlign ? props.textAlign : "unset")};
  flex-wrap: ${(props) => (props.flexWrap ? props.flexWrap : "unset")};
  text-align: ${(props) => (props.tAlign ? props.tAlign : "unset")};

  @media (max-width: 768px) {
    .settings__header-column {
      width: 100%;
      flex-wrap: wrap;
    }
  }

  &.first-switch {
    @media (max-width: 768px) {
      margin-left: 20px;
    }

    @media (min-width: 769px) and (max-width: 1365px) {
      margin-left: 18px;
    }

    @media (min-width: 1366px) and (max-width: 1480px) {
      margin-left: 20px;
    }
    @media (min-width: 1481px) and (max-width: 1650px) {
      margin-left: 23px;
    }

    @media (min-width: 1651px) and (max-width: 2000px) {
      margin-left: 25px;
    }
    @media (min-width: 2001px) and (max-width: 2600px) {
      margin-left: 30px;
    }
    @media (min-width: 2601px) {
      margin-left: 30px;
    }
  }
`;

export const SettingsColumn = styled.div`
  position: relative;
  width: ${(props) => (props.width ? props.width : "100%")};
  height: ${(props) =>
    props.height ? dimensionHeightFix(props.height) + "px" : "unset"};
  display: flex;
  flex-wrap: ${(props) => (props.fWrap ? props.fWrap : "unset")};
  flex-direction: ${(props) => (props.fDir ? props.fDir : "column")};
  justify-content: ${(props) => (props.justify ? props.justify : "center")};
  align-items: ${(props) => (props.alignItems ? props.alignItems : "center")};
  align-self: ${(props) => (props.alignSelf ? props.alignSelf : "center")};
  margin-left: ${(props) =>
    props.marginLeft ? dimensionWidthFix(props.marginLeft) + "px" : "unset"};
  margin-right: ${(props) =>
    props.marginRight ? dimensionWidthFix(props.marginRight) + "px" : "unset"};
  margin-top: ${(props) =>
    props.marginTop ? dimensionHeightFix(props.marginTop) + "px" : "unset"};
  margin-bottom: ${(props) =>
    props.marginBottom
      ? dimensionHeightFix(props.marginBottom) + "px"
      : "unset"};
  padding: ${(props) => (props.padding ? props.padding : "unset")};
  margin: ${(props) => (props.margin ? props.margin : "unset")};
`;

export const SettingsBox = styled.div`
  margin-top: ${dimensionHeightFix(125)}px;
  margin-right: ${dimensionWidthFix(67)}px;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    margin-left: 0px;
    margin-right: unset;
    margin-top: 0px;
  }

  @media (min-width: 769px) and (max-width: 1365px) {
    margin-left: 150px;
  }

  @media (min-width: 1366px) and (max-width: 1480px) {
    margin-left: 190px;
  }
  @media (min-width: 1481px) and (max-width: 1650px) {
    margin-left: 215px;
  }

  @media (min-width: 1651px) and (max-width: 2000px) {
    margin-left: 225px;
  }
  @media (min-width: 2001px) and (max-width: 2600px) {
    margin-left: 265px;
  }
  @media (min-width: 2601px) {
    margin-left: 285px;
  }
`;

export const MainHeader = styled.h1`
  font-family: "Lexend";

  line-height: 2.49;
  color: ${(props) =>
    props.theme && props.theme === "light" ? "#000000" : "#ffffff"};
  font-weight: 500;

  @media (max-width: 768px) {
    font-size: 2.2rem;
    line-height: 2.2rem;
  }

  @media (min-width: 769px) and (max-width: 1365px) {
    font-size: 2.3rem;
    line-height: 2.3rem;
    margin-top: 2.4vh;
  }

  @media (min-width: 1366px) and (max-width: 1480px) {
    font-size: 2.4rem;
    line-height: 2.4rem;
    margin-top: 2.6vh;
  }
  @media (min-width: 1481px) and (max-width: 1650px) {
    font-size: 2.8rem;
    line-height: 2.8rem;
    margin-top: 2.8vh;
  }

  @media (min-width: 1651px) and (max-width: 2000px) {
    font-size: 3.3rem;
    line-height: 3.3rem;
    margin-top: 3vh;
  }
  @media (min-width: 2001px) and (max-width: 2600px) {
    font-size: 3.4rem;
    line-height: 3.4rem;
    margin-top: 3.2vh;
  }
  @media (min-width: 2601px) {
    font-size: 3.6rem;
    line-height: 3.6rem;
    margin-top: 3.4vh;
  }

  @media (max-width: 768px) {
    &.mobile-disable {
      display: none;
    }
  }
`;
export const RowHeader = styled.h2`
  font-family: "Lexend";
  font-weight: 600;

  color: ${(props) =>
    props.theme && props.theme === "light" ? "#000000" : "#ffffff"};
  margin-left: ${(props) =>
    props.marginLeft ? dimensionWidthFix(props.marginLeft) + "px" : "unset"};
  margin-right: ${(props) =>
    props.marginRight ? dimensionWidthFix(props.marginRight) + "px" : "unset"};
  margin-top: ${(props) =>
    props.marginTop ? dimensionHeightFix(props.marginTop) + "px" : "unset"};
  margin-bottom: ${(props) =>
    props.marginBottom
      ? dimensionHeightFix(props.marginBottom) + "px"
      : "unset"};

  &.absolute-pos {
    position: absolute;
    max-width: 30%;
    top: -1.5vh;
  }

  @media (max-width: 768px) {
    font-size: 0.85rem;
    line-height: 0.85rem;
  }

  @media (min-width: 769px) and (max-width: 1365px) {
    font-size: 1.2rem;
    line-height: 1.2rem;
  }

  @media (min-width: 1366px) and (max-width: 1480px) {
    font-size: 1.2rem;
    line-height: 1.2rem;
  }
  @media (min-width: 1481px) and (max-width: 1650px) {
    font-size: 1.4rem;
    line-height: 1.4rem;
  }

  @media (min-width: 1651px) and (max-width: 2000px) {
    font-size: 1.6rem;
    line-height: 1.6rem;
  }
  @media (min-width: 2001px) and (max-width: 2600px) {
    font-size: 1.9rem;
    line-height: 1.9rem;
  }
  @media (min-width: 2601px) {
    font-size: 2rem;
    line-height: 2rem;
  }
`;

export const RowTextBig = styled.span`
  font-family: "Lexend";
  font-size: 1rem;
  font-weight: bold;
  font-stretch: normal;
  white-space: pre;
  font-style: normal;
  letter-spacing: normal;
  color: ${(props) =>
    props.theme && props.theme === "light" ? "#000000" : "#ffffff"};
  text-align: ${(props) => (props.tAlign ? props.tAlign : "unset")};
  z-index: 23;

  @media (max-width: 768px) {
    font-size: 0.7rem;
  }

  @media (min-width: 769px) and (max-width: 1365px) {
    font-size: 0.7rem;
  }

  @media (min-width: 1366px) and (max-width: 1480px) {
    font-size: 0.75rem;
  }
  @media (min-width: 1481px) and (max-width: 1650px) {
    font-size: 0.8rem;
  }

  @media (min-width: 1651px) and (max-width: 2000px) {
    font-size: 0.9rem;
  }
  @media (min-width: 2001px) and (max-width: 2600px) {
    font-size: 1.1rem;
  }
  @media (min-width: 2601px) {
    font-size: 1.2rem;
  }
`;

export const RowTextSmall = styled.span`
  font-family: "Lexend";
  font-size: ${fontSizeFixer(12)}px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  /* line-height: 4.25; */
  letter-spacing: normal;
  text-align: left;
  color: ${(props) =>
    props.theme && props.theme === "light" ? "#000000" : "#ffffff"};
  margin-left: ${(props) =>
    props.marginLeft ? dimensionWidthFix(props.marginLeft) + "px" : "unset"};
  margin-right: ${(props) =>
    props.marginRight ? dimensionWidthFix(props.marginRight) + "px" : "unset"};
  margin-top: ${(props) =>
    props.marginTop ? dimensionHeightFix(props.marginTop) + "px" : "unset"};
  margin-bottom: ${(props) =>
    props.marginBottom
      ? dimensionHeightFix(props.marginBottom) + "px"
      : "unset"};
  z-index: 23;
`;

export const SettingText = styled.span`
  font-family: "Lexend";
  font-size: ${(props) =>
    props.fSize ? fontSizeFixer(props.fSize) + "px" : "1rem"};
  font-weight: ${(props) => (props.fWeight ? props.fWeight : "normal")};
  text-align: ${(props) => (props.textAlign ? props.textAlign : "unset")};
  color: ${(props) =>
    props.color
      ? props.color
      : props.theme && props.theme === "light"
      ? "#000000"
      : "#ffffff"};
  margin-left: ${(props) =>
    props.marginLeft ? dimensionWidthFix(props.marginLeft) + "px" : "unset"};
  margin-right: ${(props) =>
    props.marginRight ? dimensionWidthFix(props.marginRight) + "px" : "unset"};
  margin-top: ${(props) =>
    props.marginTop ? dimensionHeightFix(props.marginTop) + "px" : "unset"};
  margin-bottom: ${(props) =>
    props.marginBottom
      ? dimensionHeightFix(props.marginBottom) + "px"
      : "unset"};
  opacity: ${(props) => (props.opacity ? props.opacity : "unset")};
  transition: all 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  box-sizing: border-box;

  &.switch-text {
    @media (max-width: 768px) {
      font-size: 0.5rem;
    }

    @media (min-width: 769px) and (max-width: 1365px) {
      font-size: 0.5rem;
    }

    @media (min-width: 1366px) and (max-width: 1480px) {
      font-size: 0.6rem;
    }
    @media (min-width: 1481px) and (max-width: 1650px) {
      font-size: 0.7rem;
    }

    @media (min-width: 1651px) and (max-width: 2000px) {
      font-size: 0.8rem;
    }
    @media (min-width: 2001px) and (max-width: 2600px) {
      font-size: 1rem;
    }
    @media (min-width: 2601px) {
      font-size: 1.2rem;
    }
  }

  &.bigText {
    font-size: 1.6rem;
  }

  &.gallery-slogan-single-text {
    &:hover {
      opacity: 1;
      transform: scale(1.2);
      cursor: pointer;
      margin-top: ${dimensionHeightFix(12) + "px"};
    }
  }
`;

export const ChoseButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Lexend";
  font-size: ${fontSizeFixer(12)}px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: ${(props) => (props.closed ? "black" : "#008cff")};
  background-color: transparent;
  border: none;
  text-decoration: underline;
  opacity: ${(props) => (props.closed ? "0" : "1")};
  cursor: pointer;

  @media (max-width: 768px) {
    font-size: 0.5rem;
  }

  @media (min-width: 769px) and (max-width: 1365px) {
    font-size: 0.5rem;
  }

  @media (min-width: 1366px) and (max-width: 1480px) {
    font-size: 0.6rem;
  }
  @media (min-width: 1481px) and (max-width: 1650px) {
    font-size: 0.7rem;
  }

  @media (min-width: 1651px) and (max-width: 2000px) {
    font-size: 0.8rem;
  }
  @media (min-width: 2001px) and (max-width: 2600px) {
    font-size: 0.9rem;
  }
  @media (min-width: 2601px) {
    font-size: 1rem;
  }
`;

export const StyledSwitch = styled(Switch)`
  background-color: #1890ff;
`;

export const StyledCheckbox = styled(Checkbox)`
  background-color: transparent;

  .ant-checkbox-inner {
    background-color: transparent;
    border: 2px solid #008cff;
    border-radius: 2px;
    width: ${dimensionWidthFix(20) + "px"};
    height: ${dimensionHeightFix(20) + "px"};
  }

  .ant-checkbox-checked {
    background-color: #008cff;

    .ant-checkbox-inner {
      background-color: #008cff;
    }
  }
`;

export const PosterPageStepsContnainer = styled.div`
  display: flex;
  width: 100%;
  cursor: pointer;

  @media (max-width: 768px) {
    justify-content: space-between;
    width: 100%;
    &.cart {
      width: 90%;
    }
  }
`;

export const StepArrow = styled.div`
  background-image: ${(props) =>
    props.image ? `url(${props.image})` : "unset"};
  background-size: ${(props) => (props.image ? "contain" : "unset")};
  background-repeat: ${(props) => (props.image ? "no-repeat" : "unset")};
  background-position: ${(props) => (props.image ? "center" : "unset")};
  align-self: ${(props) => (props.alignSelf ? props.alignSelf : "unset")};
  width: 33%;
  height: 6vh;
  margin-right: ${(props) =>
    props.mRight ? dimensionWidthFix(props.mRight) + "px" : "unset"};
  font-size: 1.4rem;
  display: flex;
  justify-content: flex-start;
  padding-left: 5%;
  box-sizing: border-box;
  align-items: center;
  font-family: "Lexend";
  opacity: 0.55;
  font-weight: bold;
  color: ${(props) => (props.theme === "light" ? "black" : "white")};

  @media (max-width: 768px) {
    font-size: 0.7rem;
  }
  @media (min-width: 769px) and (max-width: 1365px) {
    font-size: 0.6rem;
  }

  @media (min-width: 1366px) and (max-width: 1480px) {
    font-size: 0.6rem;
  }
  @media (min-width: 1481px) and (max-width: 1650px) {
    font-size: 0.7rem;
  }

  @media (min-width: 1651px) and (max-width: 2000px) {
    font-size: 0.8rem;
  }
  @media (min-width: 2001px) and (max-width: 2600px) {
    font-size: 1rem;
  }
  @media (min-width: 2601px) {
    font-size: 1.1rem;
  }

  &.active {
    opacity: 1;
    filter: contrast(0.5);
  }

  @media (max-width: 768px) {
    width: 30%;
    height: 50px;
    background-size: contain;
    transform: scale(1.1, 1.4);
  }
`;

export const SettingsModalContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  box-sizing: border-box;
  width: ${isMobile ? "100%" : dimensionWidthFix(794) + "px"};
  height: ${isMobile ? "100%" : dimensionHeightFix(772) + "px"};
  padding: 0 ${isMobile ? "2%" : dimensionHeightFix(90) + "px"};
  background-color: ${(props) => (props.theme === "dark" ? "black" : "white")};
  padding-bottom: 5%;

  overflow: ${isMobile ? "scroll" : "unset"};
  /* height: ${isMobile ? "inherit" : "unset"};  */
`;

export const SettingsModalTextarea = styled.textarea`
  width: 100%;
  padding: 5px;
  box-sizing: content-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  height: ${(props) =>
    props.height
      ? dimensionHeightFix(props.height) + "px"
      : dimensionHeightFix(122) + "px"};
  margin-top: ${(props) =>
    props.marginTop ? dimensionHeightFix(props.marginTop) + "px" : "unset"};
  color: ${(props) => (props.theme === "dark" ? "white" : "black")};
  background-color: ${(props) =>
    props.theme === "dark" ? "#1F1F1F" : "white"};
`;

export const ModalCloseButton = styled.button`
  background-image: url(${closeButtonIcon});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  padding: 0px;
  width: ${isMobile ? "30px" : dimensionWidthFix(36) + "px"};
  height: ${isMobile ? "30px" : dimensionWidthFix(36) + "px"};
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
  position: fixed;
  left: ${(props) =>
    props.width ? `${props.width + (!isMobile ? 10 : -40)}px` : "unset"};
  bottom: ${(props) =>
    props.height ? `${props.height - (isMobile ? 40 : 0)}px` : "unset"};

  &.generateModal {
    right: 10px;
    top: 10px;
    z-index: 5;
    position: absolute;

    @media (max-width: 768px) {
      right: 10px;
      top: 10px;
      z-index: 5;
      position: absolute;
      width: 1.5rem;
      height: 1.5rem;
      z-index: 5;
    }
  }
`;

export const ModalDivider = styled.div`
  width: 100%;
  height: 1px;
  margin: ${dimensionHeightFix(15)}px 0;
  background-color: rgba(0, 0, 0, 0.1);
`;

export const QuoteRow = styled.div`
  width: 100%;
`;

export const StyledRadio = styled(Checkbox)`
  margin-top: ${(props) =>
    props.marginTop ? dimensionHeightFix(props.marginTop) + "px" : "unset"};

  transform: scale(1.2);
`;

export const DateRangeContainer = styled.div`
  width: 100%;
  /* height: ${isMobile ? "50%" : "4%"}; */
  display: flex;
  flex-direction: ${isMobile ? "column" : "row"};
  align-items: center;
  justify-content: ${isMobile ? "center" : "space-between"};
  margin-top: 5%;
`;

export const SingleDateRangeButton = styled.button`
  width: ${isMobile ? "80%" : "9%"};
  height: 100%;
  padding: ${isMobile ? "1.5% 0" : "unset"};
  margin: ${isMobile ? "2% 0" : "unset"};
  background-color: #f5f5f5;
  border-radius: 15px;
  border: none;
  outline: none;
  font-family: "Lexend";
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &.active {
    background-color: #008cff;
    color: white;
  }

  &:hover {
    background-color: #008cff;
    color: white;
  }

  @media (max-width: 768px) {
    font-size: 1.1rem;
  }
  @media (min-width: 769px) and (max-width: 1365px) {
    font-size: 0.7rem;
  }

  @media (min-width: 1366px) and (max-width: 1480px) {
    font-size: 0.7rem;
  }
  @media (min-width: 1481px) and (max-width: 1650px) {
    font-size: 0.8rem;
  }

  @media (min-width: 1651px) and (max-width: 2000px) {
    font-size: 0.9rem;
  }
  @media (min-width: 2001px) and (max-width: 2600px) {
    font-size: 1rem;
  }
  @media (min-width: 2601px) {
    font-size: 1.1rem;
  }
`;

export const CalendarComponent = styled(Calendar)`
  width: 65%;
  border: none;
  margin-top: 6%;

  .react-calendar__tile {
    border-radius: 50%;
    width: 30px !important;
    height: 30px !important;
    flex-basis: unset !important;
    max-width: unset !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .react-calendar__tile--active {
    color: black;
    background-color: #f5f5f5;
    border-radius: 50%;
    width: 30px !important;
    height: 30px !important;
    flex-basis: unset !important;
    max-width: unset !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .react-calendar__navigation > button:enabled:hover,
  .react-calendar__navigation > button:enabled:focus {
    background-color: transparent;
  }
  .react-calendar__month-view__days__day--weekend {
    color: black;
  }
  .react-calendar__tile--now {
    background-color: transparent;

    &:hover {
      background-color: #008cff !important;
      color: white;
    }
  }

  .react-calendar__month-view__days__day {
    &::first-of-type {
      margin-left: 16.2857%;
    }

    &:hover {
      background-color: #008cff;
      color: white;
    }
  }
  .react-calendar__month-view__weekdays__weekday > abbr {
    text-decoration: none;

    color: #000000;
    font-family: "Lexend";
    font-weight: bold;
    opacity: 0.5;

    @media (max-width: 768px) {
      font-size: 0.55rem;
    }
    @media (min-width: 769px) and (max-width: 1365px) {
      font-size: 0.55rem;
    }

    @media (min-width: 1366px) and (max-width: 1480px) {
      font-size: 0.6rem;
    }
    @media (min-width: 1481px) and (max-width: 1650px) {
      font-size: 0.7rem;
    }

    @media (min-width: 1651px) and (max-width: 2000px) {
      font-size: 0.9rem;
    }
    @media (min-width: 2001px) and (max-width: 2600px) {
      font-size: 0.9rem;
    }
    @media (min-width: 2601px) {
      font-size: 1rem;
    }
  }

  .react-calendar__month-view__days__day {
    margin: 5px;
    color: #000000;
    font-family: "Lexend";
    font-weight: bold;

    @media (max-width: 768px) {
      font-size: 0.55rem;
    }
    @media (min-width: 769px) and (max-width: 1365px) {
      font-size: 0.55rem;
    }

    @media (min-width: 1366px) and (max-width: 1480px) {
      font-size: 0.6rem;
    }
    @media (min-width: 1481px) and (max-width: 1650px) {
      font-size: 0.7rem;
    }

    @media (min-width: 1651px) and (max-width: 2000px) {
      font-size: 0.9rem;
    }
    @media (min-width: 2001px) and (max-width: 2600px) {
      font-size: 0.9rem;
    }
    @media (min-width: 2601px) {
      font-size: 1rem;
    }
  }

  .react-calendar__navigation > button[disabled] {
    background-color: transparent;
    font-family: "Lexend";
    font-weight: bold;

    @media (max-width: 768px) {
      font-size: 0.8rem;
    }
    @media (min-width: 769px) and (max-width: 1365px) {
      font-size: 0.8rem;
    }

    @media (min-width: 1366px) and (max-width: 1480px) {
      font-size: 0.9rem;
    }
    @media (min-width: 1481px) and (max-width: 1650px) {
      font-size: 1rem;
    }

    @media (min-width: 1651px) and (max-width: 2000px) {
      font-size: 1.1rem;
    }
    @media (min-width: 2001px) and (max-width: 2600px) {
      font-size: 1.2rem;
    }
    @media (min-width: 2601px) {
      font-size: 1.3rem;
    }
  }
`;

export const LeftArrow = styled.div`
  background-image: url(${rightArrow});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  height: 50%;
  width: 50%;
`;

export const RightArrow = styled.div`
  background-image: url(${leftArrow});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  height: 50%;
  width: 50%;
`;

// #008cff;

// Datepicker styles
export const Datepicker = styled(DatePicker)`
  width: 100%;

  /* Input */
  .react-date-picker__inputGroup {
    display: none;
  }
  .react-date-picker__wrapper {
    display: none;
  }

  .react-date-picker__calendar {
    width: 100%;

    .react-calendar {
      width: 100%;
      background-color: transparent;
      border: none;

      /* navigation */

      .react-calendar__navigation {
        color: #f5f5f5;
        font-family: "Lexend";
        width: 90%;
        left: 5%;
        position: relative;

        button:enabled:hover {
          background-color: transparent;
        }

        &:hover {
          background-color: transparent;
        }
      }

      /* Weekdays names */
      .react-calendar__month-view__weekdays {
        display: none !important;
      }
      /* Single month container */
      .react-calendar__month-view__days {
      }

      /* Single day tile */
      .react-calendar__tile {
        background-color: #f5f5f5;
        margin: 3px;
        border-radius: 15px;
        font-family: "Lexend";
      }

      /* selected day */
      .react-calendar__tile--active {
        color: #ff9400;
        background-color: rgba(0, 0, 0, 0.4);
        box-sizing: border-box;
        border: 2px solid rgba(255, 255, 255, 0.7);
      }
    }
  }
`;

export const CurrenciesBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 10%;
  /* height: 100%; */
  box-sizing: border-box;

  @media (max-width: 768px) {
    margin-top: 20%;
  }
`;

export const CurrencyRow = styled.div`
  color: ${(props) => (props.active ? "white" : "black")};
  /* padding: 3% 5%; */
  font-size: 1.1rem;
  font-weight: bold;
  width: ${isMobile ? "50%" : "25%"};

  margin: ${isMobile ? "4% 0" : "3% 2%"};
  font-size: 1rem;

  /* width: ${isMobile ? "80%" : "30%"}; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;

  .container {
    font-size: 1rem;
    line-height: 1rem;
    color: ${(props) =>
      props.theme && props.theme === "light" ? "black" : "white"};

    .checkmark {
      top: 0;

      &:after {
        background-color: ${(props) =>
          props.theme && props.theme === "light" ? "#2196F3" : "#2196F3"};
        border: ${(props) =>
          props.theme && props.theme === "light"
            ? "2px solid white"
            : "2px solid black"};
      }
    }
  }
`;

export const ChangeMarkElement = styled.div`
  width: ${(props) => (props.width ? `12px` : "50%")};
  height: ${(props) => (props.height ? `12px` : "90%")};
  position: relative;
  top: 1px;
  right: 4px;

  background: url(${ChangeMark});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  transform: ${(props) =>
    props.version == "plus"
      ? "rotate(0deg)"
      : props.version == "minus"
      ? "rotate(180deg)"
      : "unset"};
`;

export const GeneratePosterModalContainer = styled.div`
  width: 70vw;
  /* height: 60vh; */
  padding: 5% 10%;
  background-color: black;
  position: relative;

  /* z-index: 224; */

  @media screen and (max-width: 768px) {
    width: 90vw;
    padding: 2%;
  }
`;

export const GeneratePosterModalCard = styled.div`
  width: 100%;
  height: 50vh;
  cursor: pointer;
  margin-top: 15%;
  transition: all 0.65s ease-in-out;
  &:hover {
    transform: scale(1.05);
    box-shadow: 0.1px 0.2px 2.2px rgba(255, 255, 255, 0.018),
      0.1px 0.6px 5.3px rgba(255, 255, 255, 0.022),
      0.3px 1.1px 10px rgba(255, 255, 255, 0.035),
      0.4px 2px 17.9px rgba(255, 255, 255, 0.028),
      0.8px 3.8px 33.4px rgba(255, 255, 255, 0.062),
      2px 9px 80px rgba(255, 255, 255, 0.06);
  }

  @media screen and (max-width: 768px) {
    height: 18vh;
  }

  &.dark {
    background: url(${darkImage});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  &.light {
    background: url(${lightImage});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
`;
