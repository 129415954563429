import styled from "styled-components";
import { Checkbox, Radio, Switch } from "pretty-checkbox-react";

export const CartTextArea = styled.textarea`
  width: 100%;
  border: solid 1px #dbdbdb;
  margin: 10px 0;
  padding: 5px;
  font-family: "Lexend";
  font-size: 1rem;
  padding: 5px 10px;
  box-sizing: border-box;
  font-weight: 400;
  font-size: 0.9rem;
  font-size: 0.6vw;
  min-height: 80px;

  ::placeholder {
    font-family: "Lexend";
    font-size: 0.6vw;
    opacity: 0.6;

    @media only screen and (max-width: 768px) {
      font-size: 0.7rem;
      line-height: 0.7rem !important;
      padding-top: 5px;
    }
  }
`;

export const CartInput = styled.input`
  width: 100%;
  border: solid 1px #dbdbdb;
  background-color: #fff;
  margin: 10px 0;
  padding: 5px;
  font-family: "Lexend";
  font-size: 1rem;
  padding: 5px 10px;
  box-sizing: border-box;
  font-weight: 400;
  font-size: 0.9rem;
  font-size: 0.6vw;

  &.no-margin {
    margin: 0;
  }

  &.error {
    background-color: #ffdddd;
    border: 1px solid rgba(255, 148, 148, 0.7);
    /* box-shadow: 0.1px 0.6px 1.3px rgba(0, 0, 0, 0.014),
      0.2px 1.4px 3.2px rgba(0, 0, 0, 0.02),
      0.4px 2.6px 6px rgba(0, 0, 0, 0.025),
      0.7px 4.7px 10.7px rgba(0, 0, 0, 0.03),
      1.3px 8.8px 20.1px rgba(0, 0, 0, 0.036), 3px 21px 48px rgba(0, 0, 0, 0.05); */
  }

  @media only screen and (max-width: 768px) {
    font-size: 1rem;
  }

  &.disabled {
    cursor: not-allowed;
    opacity: 0.4;
    filter: brightness(0.55);
  }

  ::placeholder {
    font-family: "Lexend";
    font-size: 0.6vw;
    opacity: 0.6;

    @media only screen and (max-width: 768px) {
      font-size: 0.7rem;
      line-height: 0.7rem !important;
    }
  }
`;

export const PaymentColumnContainer = styled.div`
  width: 100%;
  background-color: #f5f5f5;
  padding: 5%;
`;

export const CartButton = styled.button`
  background-color: transparent;
  border: none;
  font-family: "Lexend";
  padding: 0;
  cursor: pointer;

  &.change-size-button {
    color: #008cff;
  }
`;

export const CartImage = styled.div`
  width: 15px;
  height: 15px;
  background: url(${(props) => (props.src ? props.src : null)});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  margin: ${(props) => (props.margin ? props.margin : "unset")};
`;

export const StyledCheckbox = styled(Checkbox)`
  &:checked {
    .state {
      label {
        /* &:after {
        background-color: red;
      } */

        &:before {
          border-color: #008cff !important;
        }

        &:after {
          background-color: #008cff !important;
        }
      }
    }
  }
  .state {
    label {
      /* &:after {
        background-color: red;
      } */

      &:before {
        border-color: #008cff !important;
      }

      &:after {
        background-color: transparent !important;
      }
    }
  }
`;
export const PosterImage = styled.img`
  width: 180px;
  height: 250px;
  // background: url(${(props) => (props.src ? props.src : null)});
  // background-size: contain;
  // background-repeat: no-repeat;
  // background-position: center;
  box-shadow: 1.3px 1.4px 2.2px rgba(0, 0, 0, 0.017),
    3.1px 3.3px 5.3px rgba(0, 0, 0, 0.024), 5.8px 6.3px 10px rgba(0, 0, 0, 0.03),
    10.3px 11.2px 17.9px rgba(0, 0, 0, 0.036),
    19.2px 20.9px 33.4px rgba(0, 0, 0, 0.043),
    46px 50px 80px rgba(0, 0, 0, 0.06);
`;

export const PayButton = styled.button`
  padding: 10px 40px;
  border: none;
  width: 60%;
  background: #008cff;
  font-family: "Lexend";
  font-size: 1rem;
  color: white;
  text-align: center;
  cursor: pointer;
`;
export const CheckboxContainer = styled.div`
  input[type="checkbox"] {
    display: none;
  }
  input[type="checkbox"] + span {
    display: inline-block;
    border-radius: 50%;
    position: relative;
    top: -1px;
    width: 33px;
    height: 33px;
    margin: -1px 0px 0 0;
    vertical-align: middle;
    background: white left top no-repeat;
    border: 1px solid black;

    cursor: pointer;
  }
  input[type="checkbox"]:checked + span {
    background: #d9534f -11px top no-repeat;
  }

  input[type="checkbox"] + span {
    margin-right: 4px;
  }
`;
