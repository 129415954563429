import { Router, Route, Switch } from "react-router-dom";

import history from "../history";
import {
  MAIN_PAGE,
  POSTER_PAGE,
  GENERATE_PAGE,
  POSTER_SIZE,
  CART_PAGE,
  REGULATIONS,
  PRIVACY_POLICY,
  THANKS_PAGE,
  FAIL_PAYMENT,
} from "./constants";
import MainPage from "../Components/MainPage/MainPage";
import PosterPage from "../Components/PosterPage/PosterPage";
import PosterSizesStepPage from "../Components/PosterSizesStepPage/PosterSizesStepPage";
import CartPage from "../Components/CartPage/CartPage.jsx";
import Regulations from "../Components/MainPage/subComponents/Regulations.jsx";
import PrivacyPolicy from "../Components/MainPage/subComponents/PrivacyPolicy";
import Thankspage from "../Components/MainPage/subComponents/Thankspage";
import FailPaymentPage from "../Components/MainPage/subComponents/FailPaymentpage";

const reload = () => window.location.reload();

const AppRouter = () => {
  return (
    <Router history={history}>
      <Switch>
        <Route exact path={POSTER_SIZE} component={PosterSizesStepPage} />
        <Route exact path={MAIN_PAGE} component={MainPage} />
        <Route exact path={POSTER_PAGE} component={PosterPage} />
        <Route exact path={CART_PAGE} component={CartPage} />
        <Route exact path={REGULATIONS} component={Regulations} />
        <Route exact path={PRIVACY_POLICY} component={PrivacyPolicy} />
        <Route exact path={THANKS_PAGE} component={Thankspage} />
        <Route exact path={FAIL_PAYMENT} component={FailPaymentPage} />
      </Switch>
    </Router>
  );
};

export default AppRouter;
