import React, { FC, useRef, useEffect, useState } from "react";
import DatePicker from "react-date-picker";
import { ToastContainer, toast } from "react-toastify";

import {
  SettingsModalContainer,
  SettingsRow,
  SettingText,
  SettingsModalTextarea,
  ModalCloseButton,
  Datepicker,
} from "../styles/SettingsStyles";
import { SectionButton } from "../../MainPage/style/MainPageStyle";

import {
  dimensionHeightFix,
  dimensionWidthFix,
} from "../../../utils/dimensionFixer";

interface StartInvestmentModalProps {
  theme: string;
  setStartInvestmentModalOpen: Function;
  handleChangeInvestStartDate: any;
  investStartDate: any;
  isStartInvestValidationModalOpen: boolean;
  setStartInvestValidationModalOpen: Function;
}

const StartInvestmentModal: FC<StartInvestmentModalProps> = ({
  theme,
  setStartInvestmentModalOpen,
  handleChangeInvestStartDate,
  investStartDate,
  isStartInvestValidationModalOpen,
  setStartInvestValidationModalOpen,
}) => {
  const modalRef = useRef(null);
  const [modalWidth, setModalWidth] = useState(0);
  const [modalHeight, setModalHeight] = useState(0);

  useEffect(() => {
    calculateModalSizes();
  }, [modalRef]);

  useEffect(() => {
    if (isStartInvestValidationModalOpen) {
      toast.error("Proszę wybrać datę w przedziale czasowym wykresu.");
      setStartInvestValidationModalOpen(false);
    }
  }, [isStartInvestValidationModalOpen]);

  const calculateModalSizes = () => {
    const element: any = modalRef.current;
    let width = element.offsetWidth;
    let height = element.offsetHeight;

    setModalWidth(width);
    setModalHeight(height);
  };
  return (
    <SettingsModalContainer ref={modalRef} theme={theme}>
      <ModalCloseButton
        width={modalWidth}
        height={modalHeight}
        onClick={() => setStartInvestmentModalOpen()}
      />

      <SettingsRow>
        <SettingText
          theme={theme}
          marginTop={77}
          fSize={33}
          fWeight={600}
          className="bigText"
        >
          Wybierz dzień startu inwestycji
        </SettingText>
      </SettingsRow>
      <Datepicker
        isOpen={true}
        format="y-MM-dd"
        onChange={handleChangeInvestStartDate}
        value={investStartDate}
      />
      <ToastContainer
        position="top-center"
        autoClose={2500}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </SettingsModalContainer>
  );
};

export default StartInvestmentModal;
