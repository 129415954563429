import React from "react";
import { isMobile } from "react-device-detect";

import {
  Column,
  Row,
  Text,
} from "../../PosterSizesStepPage/style/PosterSizesStepPageStyle";

import {
  GeneratePosterModalContainer,
  GeneratePosterModalCard,
  ModalCloseButton,
} from "../styles/SettingsStyles";

const GeneratePosterModal = ({ closeAction, selectPoster }) => {
  return (
    <GeneratePosterModalContainer>
      <ModalCloseButton onClick={closeAction} className="generateModal" />
      <Row width="100%">
        <Text
          className="center"
          fSize={isMobile ? "1.3rem" : "2.2rem"}
          color="white"
        >
          Zaprojektuj swój plakat
        </Text>
      </Row>
      <Row
        justify="space-around"
        className="cards-container"
        fDir={isMobile ? "column" : "row"}
      >
        <Column width="33%">
          <GeneratePosterModalCard
            onClick={() => selectPoster("light")}
            className="light"
          ></GeneratePosterModalCard>
          {!isMobile && (
            <Text
              margin="2% 0 0 0"
              className="center"
              fSize={isMobile ? "1.2rem" : "1.6rem"}
              color="white"
            >
              Jasny motyw?
            </Text>
          )}
        </Column>
        {isMobile && (
          <Text
            margin="2% 0 0 0"
            className="center"
            fSize={isMobile ? "1.2rem" : "1.6rem"}
            color="white"
          >
            Jasny motyw?
          </Text>
        )}

        <Column width="33%">
          <GeneratePosterModalCard
            onClick={() => selectPoster("dark")}
            className="dark"
          ></GeneratePosterModalCard>
          {!isMobile && (
            <Text
              margin="2% 0 0 0"
              className="center"
              fSize={isMobile ? "1.2rem" : "1.6rem"}
              color="white"
            >
              Ciemny motyw?
            </Text>
          )}
        </Column>
        {isMobile && (
          <Text
            margin="2% 0 0 0"
            className="center"
            fSize={isMobile ? "1.2rem" : "1.6rem"}
            color="white"
          >
            Ciemny motyw?
          </Text>
        )}
      </Row>
    </GeneratePosterModalContainer>
  );
};

export default GeneratePosterModal;
