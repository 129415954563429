import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

import {
  Button,
  Row,
} from "../../PosterSizesStepPage/style/PosterSizesStepPageStyle";
import { PolicyContainer } from "../style/MainPageStyle";
import {
  HeaderMobileLogoAndButtonContainer,
  HamburgerIcon,
  HeaderCartContainer,
  HeaderNavButton,
} from "../style/MainPageStyle";
import { Text } from "../../PosterSizesStepPage/style/PosterSizesStepPageStyle";
import { isMobile } from "react-device-detect";
import CartIcon from "../../../assets/cart-icon.svg";
import history from "../../../history";
import cookie from "react-cookies";
import NavigationHeaderContainer from "./NavigationHeader.tsx";

const Thankspage = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);

  const handleHamburgerClick = () => {
    setMenuOpen(!isMenuOpen);
  };

  return (
    <PolicyContainer>
      <HeaderMobileLogoAndButtonContainer
        width={"100vw"}
        className="left-pos"
        justify="space-between"
      >
        <HamburgerIcon onClick={handleHamburgerClick} />
        {isMobile && (
          <HeaderCartContainer>
            <HeaderNavButton
              className="mobile-cart-icon"
              width={"35px"}
              height={"35px"}
              image={CartIcon}
              marginRight={"unset"}
              margin={"unset"}
              onClick={() => history.push("/podsumowanie")}
            ></HeaderNavButton>
            {cookie.load("userEnteredCheckout") &&
              localStorage.getItem("var") && (
                <div className="cart-items">1</div>
              )}
          </HeaderCartContainer>
        )}
      </HeaderMobileLogoAndButtonContainer>
      <NavigationHeaderContainer
        isMenuOpen={isMenuOpen}
        handleHamburgerClick={handleHamburgerClick}
        position={""}
        theme={""}
      />
      <Helmet>
        <title>Trade Graphy - Dzięki!</title>
        <meta name="description" content="noindex" />
      </Helmet>
      <Row margin={isMobile ? "10% 0 0 0" : "0"}>
        <Text
          className="center"
          fSize={isMobile ? "3rem" : "4rem"}
          fWeight="bold"
        >
          Dziękujemy!
        </Text>
      </Row>
      <Row margin={isMobile ? "20% 0 0 0" : "5% 0 0 0"}>
        <Text className="center" fSize="1.3rem" fWeight="400">
          Twoje zamówienie właśnie trafiło do realizacji.
        </Text>
      </Row>
      <Row margin={isMobile ? "5% 0 0 0" : "0.5% 0 0 0"}>
        <Text className="center" fSize="1.3rem" fWeight="400">
          Na Twoją skrzynkę właśnie powędrował e-mail z podsumowaniem
          zamówienia.
        </Text>
      </Row>
      <Row margin="10% 0 0 0">
        <Text className="center" fSize="1.4rem">
          Pozdrawiamy Cię,
        </Text>
      </Row>
      <Row margin="0.2% 0 0 0">
        <Text className="center" fSize="1.4rem">
          Ekipa TradeGraphy
        </Text>
      </Row>
      <Row margin="5% 0 0 0" justify="center">
        <Button onClick={() => history.push("/")}>Wróć</Button>
      </Row>
    </PolicyContainer>
  );
};

export default Thankspage;
