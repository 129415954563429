export const dimensionWidthFix = (dimension) => {
  const originalDimensionsScreenSize = 1920;
  const userScreen = window.innerWidth;

  const ratioFixer = userScreen / originalDimensionsScreenSize;

  return dimension * ratioFixer;
};

export const dimensionHeightFix = (dimension) => {
  const originalDimensionsScreenSize = 1080;
  const userScreen = window.innerHeight;

  const ratioFixer = userScreen / originalDimensionsScreenSize;

  return dimension * ratioFixer;
};

export const fontSizeFixer = (fontSize) => {
  const userScreen = window.innerWidth;
  let destinatedSize;

  if (userScreen < 600) {
    destinatedSize = fontSize * 0.35;
  } else if (userScreen > 600 && userScreen < 1200) {
    destinatedSize = fontSize * 0.5;
  } else if (userScreen > 1200 && userScreen < 1500) {
    destinatedSize = fontSize * 0.72;
  } else if (userScreen > 1501 && userScreen < 1721) {
    destinatedSize = fontSize * 0.86;
  } else if (userScreen > 1722 && userScreen < 1921) {
    destinatedSize = fontSize * 1;
  } else if (userScreen >= 1921 && userScreen < 2600) {
    destinatedSize = fontSize * 1.35;
  } else if (userScreen >= 2600 && userScreen < 3800) {
    destinatedSize = fontSize * 1.6;
  } else if (userScreen >= 3800 && userScreen < 5000) {
    destinatedSize = fontSize * 1.8;
  } else {
    destinatedSize = fontSize * 2;
  }

  return destinatedSize;
};
