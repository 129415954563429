import React, { FC, useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import {
  SettingsModalContainer,
  SettingsRow,
  SettingText,
  SettingsModalTextarea,
  ModalCloseButton,
  ModalDivider,
  QuoteRow,
  StyledRadio,
  SettingsColumn,
  CalendarComponent,
  LeftArrow,
  RightArrow,
} from "../styles/SettingsStyles";

import CalendarModalDateRange from "./CalendarModalDateRange";

export interface iDateRange {
  label: string;
  value: number;
}

export interface iUserPickedDateRange {
  dateFrom: Date | any;
  dateTo: Date | any;
}

export interface iUserPickedLabelDateRange {
  label: string;
  value: number;
}

export interface iSettingsModalQuoteProps {
  theme: string;
  setCalendarModalOpen: Function;
  dateRanges: Array<iDateRange>;
  userPickedDateRange: iUserPickedDateRange;
  setUserPickedDateRange: Function;
  userDateRangeDays: number;
  setUserCheckedValues: Function;
  setUserPickedLabelDateRange: Function;
  userPickedLabelDateRange: iUserPickedLabelDateRange | null;
}

const SettingsModalQuote: FC<iSettingsModalQuoteProps> = ({
  theme,
  setCalendarModalOpen,
  dateRanges,
  userPickedDateRange,
  setUserPickedDateRange,
  userDateRangeDays,
  setUserCheckedValues,
  setUserPickedLabelDateRange,
  userPickedLabelDateRange,
}) => {
  const [value, onChange] = useState([new Date()]);
  const [isAfterInitialRender, setAfterInitialRender] = useState(false);
  const store = useSelector((state: any) => state);
  const [afterFirstRender, setAfterFisrtstRender] = useState(false);
  const modalRef = useRef(null);
  const [modalWidth, setModalWidth] = useState(0);
  const [modalHeight, setModalHeight] = useState(0);

  useEffect(() => {
    calculateModalSizes();
  }, [modalRef]);

  const calculateModalSizes = () => {
    const element: any = modalRef.current;
    let width = element.offsetWidth;
    let height = element.offsetHeight;

    setModalWidth(width);
    setModalHeight(height);
  };

  const prepareValues = () => {
    const values: any = [
      userPickedDateRange.dateFrom,
      userPickedDateRange.dateTo,
    ];
    onChange(values);
  };

  // when user change period by clicking button, then change selected values

  useEffect(() => {
    if (afterFirstRender && store.label) {
      if (
        (store.label && store.label.dateFrom != value[0]) ||
        (store.label.dateTo && store.label.dateTo != value[1])
      ) {
        const values: any = [store.label.dateFrom, store.label.dateTo];
        onChange(values);
      }
    }
  }, [store]);

  useEffect(() => {
    if (value.length > 1) {
      setUserPickedDateRange({ dateFrom: value[0], dateTo: value[1] });
    }
  }, [value]);

  //   Initial setup default chosen period (2000 days)
  useEffect(() => {
    if (!isAfterInitialRender) {
      prepareValues();
      setAfterInitialRender(true);
    }
  }, [userPickedDateRange]);

  useEffect(() => {
    setAfterFisrtstRender(true);
  }, []);

  //   2000 days ago
  const earliestPossibleDateForTake = new Date(
    Date.now() - 2000 * 24 * 60 * 60 * 1000
  );
  const lastPossibleDateForTake = new Date();

  return (
    <SettingsModalContainer ref={modalRef} theme={theme}>
      <ModalCloseButton
        onClick={() => setCalendarModalOpen()}
        width={modalWidth}
        height={modalHeight}
      />
      <SettingsRow>
        <SettingText
          theme={theme}
          marginTop={77}
          fSize={33}
          fWeight={600}
          className="bigText"
        >
          Wybierz okres czasu wykresu
        </SettingText>
      </SettingsRow>
      <CalendarModalDateRange
        dateRanges={dateRanges}
        setUserPickedLabelDateRange={setUserPickedLabelDateRange}
        userPickedLabelDateRange={userPickedLabelDateRange}
      />
    </SettingsModalContainer>
  );
};

export default SettingsModalQuote;
