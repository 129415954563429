import { PureComponent } from "react";
import moment from "moment";
import { connect } from "react-redux";
import { isMobile } from "react-device-detect";
import { reactLocalStorage } from "reactjs-localstorage";
import cookie from "react-cookies";

import Modal from "react-modal";

import domtoimage from "dom-to-image";

import "react-vis/dist/style.css";

import Settings from "../SettingsComponent/Settings.jsx";
import {
  getBitcoinData,
  getBitcoinMinuteData,
  getBitcoinHourData,
} from "../../Api/Api";
import {
  setChartData,
  setMaxPrice,
  setMinPrice,
  setChange,
  setAvaragePrice,
  changeCurrency,
  setChosenQuote,
  changeChartTheme,
  changeChartPeriod,
  changePeriodLabel,
} from "../../redux/actions";
import { PosterPageContainer } from "./style/PosterPageStyle.js";

import NavigationHeader from "../MainPage/subComponents/NavigationHeader";

import PosterPageMobileNav from "./PosterPageComponents/PosterPageMobileNav.jsx";
import Poster from "../SettingsComponent/subComponents/Poster.tsx";
import Loader from "../../GenericComponents/Loader.jsx";
import GeneratePosterModal from "../SettingsComponent/subComponents/GeneratePosterModalContent.jsx";

class PosterPage extends PureComponent {
  state = {
    currencyModalOpen: false,
    isCalendarModalOpen: false,
    isStartInvestmentModalOpen: false,
    isCreateImageDone: false,
    isQuoteModalOpen: false,
    isLoading: false,
    isMenuOpen: false,
    bitcoinData: null,
    isModalOpen: false,
    minPrice: 0,
    maxPrice: 0,
    theme: "light",
    isMobilePosterOpen: false,
    change: 0,
    selectedOrientation: this.props.state.mode
      ? this.props.state.mode
      : "pionowo",
    selectedFormat: this.props.state.format ? this.props.state.format : "A4",
    currentCurrency: "Bitcoin",
    formats: {
      A5: {
        poziomo: {
          width: 2480,
          height: 1748,
        },
        pionowo: {
          width: 1748,
          height: 2480,
        },
      },
      A4: {
        poziomo: {
          width: 3508,
          height: 2480,
        },
        pionowo: {
          width: 2480,
          height: 3508,
        },
      },
      A3: {
        poziomo: {
          width: 4961,
          height: 3508,
        },
        pionowo: {
          width: 3508,
          height: 4961,
        },
      },
      A2: {
        poziomo: {
          width: 7016,
          height: 4961,
        },
        pionowo: {
          width: 4961,
          height: 7016,
        },
      },
    },
    // stateShowTradegraphy:
    //   reactLocalStorage.getObject("var").showTradegraphy || true,
    // stateShowQuote: reactLocalStorage.getObject("var")
    //   ? reactLocalStorage.getObject("var").showSettings.showQuote
    //   : true,
    // stateShowCryptoStats:
    //   reactLocalStorage.getObject("var").showCryptoStats || true,
    // stateShowCryptoName:
    //   reactLocalStorage.getObject("var").showSettings.showLogo || true,
    // stateShowAvaragePrice:
    //   reactLocalStorage.getObject("var").showSettings.showAvaragePrice || true,
    // stateShowStartInvest:
    //   reactLocalStorage.getObject("var").showStartInvest || true,
    stateShowTradegraphy: Object.keys(reactLocalStorage.getObject("var")).length
      ? reactLocalStorage.getObject("var").showTradegraphy
      : true,
    stateShowQuote: Object.keys(reactLocalStorage.getObject("var")).length
      ? reactLocalStorage.getObject("var").showSettings.showQuote
      : true,
    stateShowCryptoStats: Object.keys(reactLocalStorage.getObject("var")).length
      ? reactLocalStorage.getObject("var").showCryptoStats
      : true,
    stateShowCryptoName: Object.keys(reactLocalStorage.getObject("var")).length
      ? reactLocalStorage.getObject("var").showSettings.showLogo
      : true,
    stateShowAvaragePrice: Object.keys(reactLocalStorage.getObject("var"))
      .length
      ? reactLocalStorage.getObject("var").showSettings.showAvaragePrice
      : true,
    stateShowStartInvest: Object.keys(reactLocalStorage.getObject("var")).length
      ? reactLocalStorage.getObject("var").showStartInvest
      : true,
  };

  componentDidMount = async () => {
    // this.toggleLoader()

    if (localStorage.getItem("var")) {
      const chartSavedData = JSON.parse(localStorage.getItem("var"));
      setTimeout(() => {
        this.props.dispatch(changeChartTheme(chartSavedData.theme));
      }, 100);
      this.setState({
        formattedData: chartSavedData.chartData,
      });

      this.props.dispatch(setChartData(chartSavedData.chartData));
      this.props.dispatch(changeCurrency(chartSavedData.currency));
      this.props.dispatch(changeChartPeriod(chartSavedData.chartPeriod));
      this.props.dispatch(setChosenQuote(chartSavedData.quote));
      this.props.dispatch(changePeriodLabel(chartSavedData.label));
      this.setState({
        currentCurrency: chartSavedData.currency,
      });

      // this.props.dispatch(changeCurrency(chartSavedData.currency));

      this.getAvaragePrice(chartSavedData.chartData);
      this.getHighestAndLowestPrice(chartSavedData.chartData);

      // await this.fetchData();
      // reactLocalStorage.remove("posterImage");
      // cookie.remove("userEnteredCheckout", { path: "/" });
      // this.getHighestAndLowestPrice();
    } else {
      reactLocalStorage.remove("posterImage");
      cookie.remove("userEnteredCheckout", { path: "/" });
      await this.fetchData();
      // await this.formatData();
    }
    await this.appendCorrectStyle();
    await Modal.setAppElement("body");
    // await this.toggleLoader()
  };

  componentDidUpdate = async (prevProps, prevState) => {
    if (this.props.state.theme !== this.state.theme) {
      // await this.toggleLoader()
      await this.appendCorrectStyle();
      await this.setState({
        theme: this.props.state.theme,
      });
      // await this.toggleLoader()
    }

    if (
      this.state.currentCurrency !== this.props.state.currency &&
      this.props.state.currency.FullName !== null
    ) {
      this.setState({
        currentCurrency: this.props.state.currency,
      });
    }
  };

  changeGlobalShowTradeGraphy = (state) => {
    this.setState({
      stateShowTradegraphy: state,
    });
  };
  changeGlobalShowCryptoStats = (state) => {
    this.setState({
      stateShowCryptoStats: state,
    });
  };
  changeGlobalShowCryptoName = (state) => {
    this.setState({
      stateShowCryptoName: state,
    });
  };
  changeGlobalShowAvaragePrice = (state) => {
    this.setState({
      stateShowAvaragePrice: state,
    });
  };
  changeGlobalShowQuote = (state) => {
    this.setState({
      stateShowQuote: state,
    });
  };
  changeGlobalShowStartInvest = (state) => {
    this.setState({
      stateShowStartInvest: state,
    });
  };

  closeAllModals = () => {
    this.setState({
      currencyModalOpen: false,
      isCalendarModalOpen: false,
      isStartInvestmentModalOpen: false,
      isQuoteModalOpen: false,
    });
  };
  changeMainHeaderState = () => {
    this.setState({
      mainHeaderText: this.props.state.mainHeaderText,
    });
  };
  changeSubHeaderState = () => {
    this.setState({
      subHeaderText: this.props.state.subHeaderText,
    });
  };
  changeParagraphState = () => {
    this.setState({
      paragraphText: this.props.state.paragraphText,
    });
  };

  changeFormat = () => {
    this.setState({
      selectedFormat: this.props.state.format,
    });
  };

  changeOrientation = () => {
    this.setState({
      selectedOrientation: this.props.state.mode,
    });
  };

  fetchData = async (mode, amount, currencyType) => {
    let data;

    if (!mode && !amount && !currencyType) {
      const data = await getBitcoinData(365, "BTC");

      if (data.Data) {
        this.setState({
          bitcoinData: data.Data,
          formattedData: null,
        });
        this.formatData("day");
      }

      this.props.dispatch(changeCurrency("Bitcoin"));
    } else {
      switch (mode) {
        case "day":
          data = await getBitcoinData(
            amount,
            currencyType ? currencyType : "BTC"
          );

          this.setState({
            bitcoinData: data.Data,
            formattedData: null,
          });

          this.formatData("day");

          break;

        case "hour":
          data = await getBitcoinHourData(
            amount,
            currencyType ? currencyType : "BTC"
          );

          this.setState({
            bitcoinData: data.Data,
            formattedData: null,
          });

          this.formatData("hour");
          break;

        case "minute":
          data = await getBitcoinMinuteData(
            amount,
            currencyType ? currencyType : "BTC"
          );

          this.setState({
            bitcoinData: data.Data,
            formattedData: null,
          });

          this.formatData("minute");
          break;

        default:
          break;
      }
    }
    this.getAvaragePrice();
  };

  toggleCurrencyModal = () => {
    this.setState({
      currencyModalOpen: !this.state.currencyModalOpen,
    });
  };
  toggleChartPeriodModal = () => {
    this.setState({
      isCalendarModalOpen: !this.state.isCalendarModalOpen,
    });
  };
  toggleQuoteModal = () => {
    this.setState({
      isQuoteModalOpen: !this.state.isQuoteModalOpen,
    });
  };
  toggleStartInvestmentModal = () => {
    this.setState({
      isStartInvestmentModalOpen: !this.state.isStartInvestmentModalOpen,
    });
  };

  formatData = (mode) => {
    if (this.state.bitcoinData) {
      const data = this.state.bitcoinData;

      this.setState({
        formattedData: Object.keys(data.Data).map((k) => {
          return {
            x:
              mode == "day"
                ? moment(data.Data[k].time * 1000).format("DD-MM-YYYY")
                : data.Data[k].time,
            id: parseInt(k),
            y: (data.Data[k].open + data.Data[k].close) / 2,
          };
        }),
      });

      this.props.dispatch(
        setChartData(
          Object.keys(data.Data).map((k) => {
            return {
              x:
                mode == "day"
                  ? moment(data.Data[k].time * 1000).format("DD-MM-YYYY")
                  : data.Data[k].time,
              id: parseInt(k),
              y: (data.Data[k].open + data.Data[k].close) / 2,
            };
          })
        )
      );
    }

    this.getHighestAndLowestPrice();
  };

  getHighestAndLowestPrice = (data) => {
    const { formattedData } = this.state;

    const chartData = data ? data : formattedData ? formattedData : null;

    const max =
      chartData &&
      chartData.reduce((prev, current) => {
        return prev.y > current.y ? prev : current;
      }); //returns object

    const endValue = chartData && chartData[chartData.length - 1];
    // let startValue = chartData && chartData[0];

    let startValue = chartData && chartData.filter((value) => value.y > 0);

    this.props.dispatch(setMaxPrice(max.y));

    const min =
      chartData &&
      chartData.reduce((prev, current) => {
        return prev.y < current.y && current.y > 0 && prev.y > 0
          ? prev
          : current;
      }); //returns object

    this.setState({
      minPrice: min.y,
      maxPrice: max.y,
      change:
        (endValue.y / startValue.y) * 100 < 200
          ? (endValue.y / startValue[0].y) * 100 - 100
          : (endValue.y / startValue[0].y) * 100,
    });
    this.props.dispatch(setMinPrice(min.y));
    this.props.dispatch(
      setChange(
        (endValue.y / startValue[0].y) * 100 < 200
          ? (endValue.y / startValue[0].y) * 100 - 100
          : (endValue.y / startValue[0].y) * 100
      )
    );
  };

  getAvaragePrice = (data) => {
    const { formattedData } = this.state;
    let totalValue = 0;

    const chartData = data ? data : formattedData ? formattedData : null;

    chartData &&
      chartData.forEach((singleData) => (totalValue += singleData.y));

    this.props.dispatch(setAvaragePrice(totalValue / chartData.length));
  };

  registerThemeChange = (theme) => {
    this.setState({
      theme: theme,
    });
  };

  appendCorrectStyle = () => {
    const styleVariant = this.props.state.theme;

    const appendedStyle = document.querySelectorAll("#themeStyle");
    const headElement = document.querySelector("head");

    let styleFilePath;

    appendedStyle &&
      Array.from(appendedStyle).forEach((style) => {
        if (style.id === "themeStyle") {
          headElement.removeChild(style);
        }
      });

    if (styleVariant === "dark") {
      styleFilePath = "../../additionalStyles/darkStyles.css";
    } else {
      styleFilePath = "../../additionalStyles/lightStyles.css";
    }

    var head = document.head;
    var link = document.createElement("link");

    link.rel = "stylesheet";
    link.crossOrigin = "anonymous";
    link.href = styleFilePath;
    link.id = "themeStyle";

    head.appendChild(link);
  };

  changeStyle = () => {
    const appendedStyle = document.querySelectorAll("#themeStyle");
    const headElement = document.querySelector("head");
    const styleVariant = this.props.state.theme;
    let styleFilePath;

    appendedStyle &&
      Array.from(appendedStyle).forEach((style) => {
        if (style.id === "themeStyle") {
          headElement.removeChild(style);
        }
      });

    if (styleVariant === "dark") {
      this.registerThemeChange("dark");
      styleFilePath = "../../additionalStyles/darkStyles.css";
    } else {
      this.registerThemeChange("light");
      styleFilePath = "../../additionalStyles/lightStyles.css";
    }

    var head = document.head;
    var link = document.createElement("link");

    link.type = "text/css";
    link.rel = "stylesheet";
    link.href = styleFilePath;
    link.id = "themeStyle";

    head.appendChild(link);
  };
  toggleCreateCartImage = () => {
    this.setState({
      isCreateImageDone: false,
    });
  };

  getBase64Image = (img) => {
    var canvas = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;

    var ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0);

    var dataURL = canvas.toDataURL("image/png");

    return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
  };

  toggleLoader = () => {
    this.setState({
      isLoading: !this.state.isLoading,
    });
  };

  createImage = async () => {
    this.toggleLoader();
    const posterNode = document.querySelector("#posterLayer");
    const posterPageContainer = document.querySelector("#posterLayer");
    let isDone = false;

    if (document.querySelector("#hideButton")) {
      document.querySelector("#hideButton").style.display = "none";
    }

    if (isMobile) {
      posterPageContainer.style.flexDirection = "column";
      posterNode.style.top = "0px";
    }

    const settings = {
      width: document.getElementById("posterLayer").offsetWidth,
      height: document.getElementById("posterLayer").offsetHeight,
    };
    setTimeout(() => {
      domtoimage.toJpeg(posterNode).then((dataUrl) => {
        //download(dataUrl, 'myDiv.png');
        this.setState({ isCreateImageDone: true });
        isDone = true;
        this.toggleLoader();
        localStorage.setItem("posterImage", dataUrl);
        return isDone;
      });
    }, 1200);

    if (isMobile) {
      posterPageContainer.style.flexDirection = "";
      posterNode.style.top = "0px";
    }

    // reactLocalStorage.setObject("posterImage", blob);
    // document.querySelector("#hideButton").style.display="flex"
    return isDone;

    // posterNode.style.transform = `scale(${100 / widthModifiers},${
    //   100 / heightModifiers
    // })`;
  };

  openModal = () => {
    this.setState({
      isModalOpen: true,
    });

    // setTimeout(() => {
    //   this.generateImage();
    // }, 50);
  };

  closeModal = () => {
    this.setState({
      isModalOpen: false,
    });
  };

  handleHamburgerClick = () => {
    this.setState({
      isMenuOpen: !this.state.isMenuOpen,
    });
  };

  toggleShowMobilePoster = () => {
    this.setState({
      isMobilePosterOpen: !this.state.isMobilePosterOpen,
    });
  };

  render() {
    return (
      <div>
        <PosterPageMobileNav
          theme={this.state.theme}
          handleHamburgerClick={this.handleHamburgerClick}
        />
        <PosterPageContainer
          id={"posterPageContainer"}
          theme={this.state.theme}
        >
          <NavigationHeader
            isMenuOpen={this.state.isMenuOpen}
            handleHamburgerClick={this.handleHamburgerClick}
            position={"fixed"}
            className="navigation absolute-pos"
            theme={this.state.theme}
          />
          {/* {((isMobile && this.state.isMobilePosterOpen) || !isMobile) && ( */}

          <Poster
            theme={this.state.theme}
            showSettings={this.props.state.showSettings}
            chosenSlogan={this.props.state.chosenSlogan}
            avarageBitcoinPrice={this.state.avarageBitcoinPrice}
            formattedData={this.state.formattedData}
            quote={this.props.state.quote}
            showPoster={
              (isMobile && this.state.isMobilePosterOpen) || !isMobile
            }
            toggleShowMobilePoster={this.toggleShowMobilePoster}
            toggleLoader={this.toggleLoader}
            minPrice={this.state.minPrice}
            maxPrice={this.state.maxPrice}
            change={this.state.change}
            toggleCurrencyModal={this.toggleCurrencyModal}
            toggleChartPeriodModal={this.toggleChartPeriodModal}
            toggleQuoteModal={this.toggleQuoteModal}
            toggleStartInvestmentModal={this.toggleStartInvestmentModal}
            stateShowTradegraphy={this.state.stateShowTradegraphy}
            stateShowQuote={this.state.stateShowQuote}
            stateShowCryptoStats={this.state.stateShowCryptoStats}
            stateShowCryptoName={this.state.stateShowCryptoName}
            stateShowAvaragePrice={this.state.stateShowAvaragePrice}
            stateShowStartInvest={this.state.stateShowStartInvest}
          />

          {/* )} */}

          <Settings
            formattedData={this.state.formattedData}
            theme={this.state.theme}
            generate={this.openModal}
            fetchData={this.fetchData}
            createImage={this.createImage}
            showSettings={this.props.state.showSettings}
            chosenSlogan={this.props.state.chosenSlogan}
            avarageBitcoinPrice={this.state.avarageBitcoinPrice}
            quote={this.props.state.quote}
            toggleShowMobilePoster={this.toggleShowMobilePoster}
            toggleLoader={this.toggleLoader}
            isCreateImageDone={this.state.isCreateImageDone}
            toggleCreateCartImage={this.toggleCreateCartImage}
            currentCurrency={this.state.currentCurrency}
            toggleCurrencyModal={this.toggleCurrencyModal}
            isCurrencyModalOpen={this.state.currencyModalOpen}
            isCalendarModalOpen={this.state.isCalendarModalOpen}
            toggleChartPeriodModal={this.toggleChartPeriodModal}
            isQuoteModalOpen={this.state.isQuoteModalOpen}
            toggleQuoteModal={this.toggleQuoteModal}
            toggleStartInvestmentModal={this.toggleStartInvestmentModal}
            isStartInvestmentModalOpen={this.state.isStartInvestmentModalOpen}
            closeAllModals={this.closeAllModals}
            changeGlobalShowTradeGraphy={this.changeGlobalShowTradeGraphy}
            changeGlobalShowCryptoStats={this.changeGlobalShowCryptoStats}
            changeGlobalShowCryptoName={this.changeGlobalShowCryptoName}
            changeGlobalShowAvaragePrice={this.changeGlobalShowAvaragePrice}
            changeGlobalShowQuote={this.changeGlobalShowQuote}
            changeGlobalShowStartInvest={this.changeGlobalShowStartInvest}
          />
          {/* <button onClick={() => this.createImage()}>create image</button> */}

          {this.state.isLoading && <Loader />}
          {/* <Modal
            isOpen={this.state.isModalOpen}
            // onAfterOpen={afterOpenModal}
            onRequestClose={this.closeModal}
            style={this.customStyles}
            contentLabel="Example Modal"
          >
            <GeneratePosterModal />
          </Modal> */}
        </PosterPageContainer>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  state,
});

const mapDispatchToProps = (dispatch) => ({
  // setChartData: (data) => setChartData(data),
  // setMaxPrice: (maxPrice) => dispatch(setMaxPrice(maxPrice)),
  // setMinPrice: (minPrice) => dispatch(setMinPrice(minPrice)),
  // setChange: (change) => dispatch(setChange(change)),
  // setAvaragePrice: (avaragePrice) => dispatch(setAvaragePrice(avaragePrice)),
  // changeCurrency: (currency) => dispatch(changeCurrency(currency)),
});

export default connect(mapStateToProps)(PosterPage);
