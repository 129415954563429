import React, { useState, useEffect, useLayoutEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-modal";
import moment from "moment";
import { useHistory } from "react-router-dom";
import DatePicker from "react-date-picker";
import { isMobile, browserName } from "react-device-detect";
import { reactLocalStorage } from "reactjs-localstorage";
import { Text } from "../PosterSizesStepPage/style/PosterSizesStepPageStyle";

import { getTopCurrencies } from "../../Api/Api";
import { AiOutlineEye } from "react-icons/ai";
import {
  changeChartTheme,
  changeShowMarketCapAction,
  changeShowMarketDominanceAction,
  changeShowCryptoPriceAction,
  changeShowDateAction,
  changeShowLogoAction,
  changeShowQuote,
  changeShowSlogan,
  changeShowMArketRank,
  setChosenSlogan,
  setChosenQuote,
  changePeriodLabel,
  changeStartIvestDate,
  changeFormat,
  changeCurrency,
  changeShowAvaragePrice,
  changeShowCryptoStats,
  changeShowStartInvest,
  changeShowTradegraphyLogo,
  setAllCurrencies,
  changeAccentColor,
  changeGalleryQuote,
  changeUserQuote,
  changeQuoteArr,
} from "../../redux/actions";
import {
  SettingsContainer,
  SettingsRow,
  SettingsBox,
  MainHeader,
  RowHeader,
  SettingsColumn,
  RowTextBig,
  ChoseButton,
  StyledSwitch,
  settingsModalStyles,
  SettingText,
} from "./styles/SettingsStyles";
import {
  dimensionWidthFix,
  dimensionHeightFix,
} from "../../utils/dimensionFixer";
import PosterPageSteps from "./subComponents/PosterPageSteps.jsx";
import { SectionButton } from "../MainPage/style/MainPageStyle";
import SettingsModalSlogan from "./subComponents/SettingsModalSlogan";
import SettingsModalQuote from "./subComponents/SettingsModalQuote";
import SettingsModalCalendar from "./subComponents/SettingsModalCalendar";
import StartInvestmentModal from "./subComponents/StartInvestmentModal";
import CurrencyModal from "./subComponents/CurrencyModal";
import { coinColors } from "../../utils/coinColors";

import { quoteGallery } from "../../utils/galleryTexts";

export const Settings = ({
  theme,
  formattedData,
  fetchData,
  createImage,
  chosenSlogan,
  avarageBitcoinPrice,
  quote,
  toggleShowMobilePoster,
  toggleLoader,
  isCreateImageDone,
  toggleCreateCartImage,
  currentCurrency,
  toggleCurrencyModal,
  isCurrencyModalOpen,
  isCalendarModalOpen,
  toggleChartPeriodModal,
  isQuoteModalOpen,
  toggleQuoteModal,
  toggleStartInvestmentModal,
  isStartInvestmentModalOpen,
  closeAllModals,
  changeGlobalShowTradeGraphy,
  changeGlobalShowCryptoStats,
  changeGlobalShowCryptoName,
  changeGlobalShowAvaragePrice,
  changeGlobalShowQuote,
  changeGlobalShowStartInvest,
}) => {
  const themes = [
    { value: "light", label: "Jasny" },
    { value: "dark", label: "Ciemny" },
  ];
  const formatModes = [
    { value: "a4", label: "a4" },
    { value: "a3", label: "a3" },
    { value: "a2", label: "a2" },
    { value: "a1", label: "a1" },
  ];

  const currencies = [
    "BITCOIN",
    "ETHEREUM",
    "BIANCE COIN",
    "TETHER",
    "CARDANO",
    "SOLANA",
    "XRP",
    "POLKADOT",
    "DOGECOIN",
    "SHIBA INU",
  ];
  const dispatch = useDispatch();
  const wholeStore = useSelector((state) => state);
  const settings = useSelector((state) => state.showSettings);

  const posterSettings =
    reactLocalStorage.getObject("var") &&
    Object.keys(reactLocalStorage.getObject("var")).length !== 0
      ? reactLocalStorage.getObject("var")
      : wholeStore;

  const [chosenTheme, setTheme] = useState(
    posterSettings.theme ? posterSettings.theme : "light"
  );
  const [showSettings, setShowSettings] = useState(null);
  const [isSloganModalOpen, setSloganModalOpen] = React.useState(false);
  // const [isQuoteModalOpen, setQuoteModalOpen] = React.useState(false);
  // const [isCalendarModalOpen, setCalendarModalOpen] = React.useState(false);
  const [userSlogan, setUserSlogan] = useState("DOLOR");
  const [tempUserSlogan, setTempUserSlogan] = useState("");
  // const [currencyModalOpen, setCurrencyModalOpen] = useState(false);
  const [tempUserSloganLength, setTempUserSloganLength] = useState(
    tempUserSlogan.length
  );
  // const [isStartInvestmentModalOpen, setStartInvestmentModalOpen] =
  //   React.useState(false);
  const [investStartDate, setInvestStartDate] = useState(new Date());
  const [allCurrencies, setCurrencies] = useState(null);
  const [isStartInvestValidationModalOpen, setStartInvestValidationModalOpen] =
    useState(false);

  const [showTradegraphy, setShowTradegraphy] = useState(
    posterSettings.showTradegraphy
  );
  const [showCryptoStats, setShowCryptoStats] = useState(
    posterSettings.showCryptoStats
  );
  const [showCryptoName, setShowCryptoName] = useState(
    posterSettings.showSettings.showLogo
  );
  const [showAvaragePrice, setShowAvaragePrice] = useState(
    posterSettings.showSettings.showAvaragePrice
  );
  const [globalShowQuote, setGlobalShowQuote] = useState(
    posterSettings.showSettings.showQuote
  );
  const [showStartInvest, setShowStartInvest] = useState(
    posterSettings.showStartInvest
  );

  const handleChangeInvestStartDate = (event) => {
    if (
      !moment(event).isBetween(
        wholeStore.label.dateFrom,
        wholeStore.label.dateTo
      )
    ) {
      setStartInvestValidationModalOpen(true);
    } else {
      dispatch(changeStartIvestDate(event));
      setInvestStartDate(event);
    }
  };

  const handleChangeCurrency = (currency) => {
    let startColor = {
      light: "#ff9400",
      dark: "#ff9400",
    };
    dispatch(changeCurrency(currency));

    // coinColors.forEach((color) => {
    //   if (currency.FullName ? currency.FullName : currency == color.name) {
    //     startColor = {
    //       light: color.light,
    //       dark: color.dark,
    //     };
    //     dispatch(changeAccentColor(startColor));
    //     return;
    //   } else {
    //     dispatch(changeAccentColor(startColor));
    //   }
    // });

    // dispatch(changeAccentColor())
  };

  const [userQuote, setUserQuote] = useState({
    quote:
      "The bigger thing with bitcoin is not bitcoin itself, but what does that decentralized technology really do? ",
    authorFirstLine: "Ashton Kutcher, American actor and producer",
  });
  const [tempUserQuote, setTempUserQuote] = useState({
    userQuote: "",
    userQuoteAuthorFirstLine: "",
  });
  const [tempUserQuoteLength, setTempUserQuoteLength] = useState({
    userQuoteLength: tempUserQuote.userQuote.length,
    userQuoteAuthorFirstLineLength:
      tempUserQuote.userQuoteAuthorFirstLine.length,
  });

  const [userCheckedValues, setUserCheckedValues] = useState(
    posterSettings.quoteArr ? posterSettings.quoteArr : [0, 1]
  );
  // Default - picked 1000 days (1000 results from api)
  const [userPickedDateRange, setUserPickedDateRange] = useState({
    dateFrom: new Date(Date.now() - 2000 * 24 * 60 * 60 * 1000),
    dateTo: new Date(),
  });

  const [userPickedLabelDateRange, setUserPickedLabelDateRange] = useState({});

  const [userDateRangeDays, setUserDateRangeDays] = useState(0);
  const [afterInitialRender, setAfterInitialRender] = useState(false);
  const history = useHistory();

  Modal.setAppElement("#root");
  const galleryTexts = [
    "Crypto millionaire comming soon",
    "DOLOR",
    "Where lambo",
    "Keep calm and hold",
    "Buy low, sell high? Nah, I buy high, sell low",
    "Loyal to BITCOIN",
  ];

  const quoteGalleryTexts = quoteGallery;

  const dateRanges = [
    { label: "1D", value: 1 },
    { label: "7D", value: 7 },
    { label: "1M", value: 30 },
    { label: "3M", value: 90 },
    { label: "1Y", value: 365 },
    { label: "YTD", value: moment().dayOfYear() - 1 },
    { label: "ALL", value: 1000 },
  ];

  const fetchTopCurrencies = async () => {
    const data = await getTopCurrencies(50);
    data && dispatch(setAllCurrencies(data.Data));
  };

  // dispatch to redux all things if there is store in lcoalstorage
  useEffect(() => {
    if (Object.keys(reactLocalStorage.getObject("var")).length) {
      dispatch(changeShowStartInvest(showStartInvest));
      dispatch(changeShowCryptoStats(showCryptoStats));
      dispatch(changeShowAvaragePrice(showAvaragePrice));
      dispatch(changeShowTradegraphyLogo(showTradegraphy));
      dispatch(changeShowQuote(globalShowQuote));
    }
  }, []);

  // every time when setUserPickedLabelDateRange variable changes, change userPickedDateRange and rest of flow

  const handleChangeShowAvaragePrice = () => {
    dispatch(changeShowAvaragePrice(!showAvaragePrice));
    setShowAvaragePrice(!showAvaragePrice);
    changeGlobalShowAvaragePrice(!showAvaragePrice);
  };

  const handleChangeShowCryptoStats = () => {
    dispatch(changeShowCryptoStats(!showCryptoStats));
    setShowCryptoStats(!showCryptoStats);
    changeGlobalShowCryptoStats(!showCryptoStats);
  };

  const handleChangeShowStartInvest = () => {
    dispatch(changeShowStartInvest(!showStartInvest));
    changeGlobalShowStartInvest(!showStartInvest);
    setShowStartInvest(!showStartInvest);
  };

  const handleChangeShowTradeGraphyLogo = () => {
    dispatch(changeShowTradegraphyLogo(!showTradegraphy));
    setShowTradegraphy(!showTradegraphy);
    changeGlobalShowTradeGraphy(!showTradegraphy);
  };
  useEffect(() => {
    switch (
      (userPickedLabelDateRange && userPickedLabelDateRange.label) ||
      wholeStore.chartPeriod
    ) {
      case "ALL":
        setUserPickedDateRange({
          dateFrom: new Date(Date.now() - 2000 * 24 * 60 * 60 * 1000),
          dateTo: new Date(),
        });
        dispatch(
          changePeriodLabel({
            dateFrom: new Date(Date.now() - 2000 * 24 * 60 * 60 * 1000),
            dateTo: new Date(),
          })
        );
        break;
      case "YTD":
        setUserPickedDateRange({
          dateFrom: new Date(
            Date.now() - (moment().dayOfYear() - 1) * 24 * 60 * 60 * 1000
          ),
          dateTo: new Date(),
        });
        dispatch(
          changePeriodLabel({
            dateFrom: new Date(
              Date.now() - (moment().dayOfYear() - 1) * 24 * 60 * 60 * 1000
            ),
            dateTo: new Date(),
          })
        );
        break;
      case "1Y":
        setUserPickedDateRange({
          dateFrom: new Date(Date.now() - 365 * 24 * 60 * 60 * 1000),
          dateTo: new Date(),
        });
        dispatch(
          changePeriodLabel({
            dateFrom: new Date(Date.now() - 365 * 24 * 60 * 60 * 1000),
            dateTo: new Date(),
          })
        );
        break;
      case "3M":
        setUserPickedDateRange({
          dateFrom: new Date(Date.now() - 91 * 24 * 60 * 60 * 1000),
          dateTo: new Date(),
        });
        dispatch(
          changePeriodLabel({
            dateFrom: new Date(Date.now() - 91 * 24 * 60 * 60 * 1000),
            dateTo: new Date(),
          })
        );
        break;
      case "1M":
        setUserPickedDateRange({
          dateFrom: new Date(Date.now() - 30 * 24 * 60 * 60 * 1000),
          dateTo: new Date(),
        });
        dispatch(
          changePeriodLabel({
            dateFrom: new Date(Date.now() - 30 * 24 * 60 * 60 * 1000),
            dateTo: new Date(),
          })
        );
        break;
      case "7D":
        setUserPickedDateRange({
          dateFrom: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000),
          dateTo: new Date(),
        });
        dispatch(
          changePeriodLabel({
            dateFrom: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000),
            dateTo: new Date(),
          })
        );
        break;
      case "1D":
        setUserPickedDateRange({
          dateFrom: new Date(Date.now() - 1 * 24 * 60 * 60 * 1000),
          dateTo: new Date(),
        });
        dispatch(
          changePeriodLabel({
            dateFrom: new Date(Date.now() - 1 * 24 * 60 * 60 * 1000),
            dateTo: new Date(),
          })
        );
        break;
      default:
        break;
    }
  }, [userPickedLabelDateRange]);

  useEffect(() => {
    setTimeout(() => {
      toggleCreateCartImage();
      if (isCreateImageDone) {
        history.push("/wymiary");
      }
    }, 150);
  }, [isCreateImageDone]);

  useEffect(() => {
    if (afterInitialRender) {
      const posterSettings = wholeStore
        ? wholeStore
        : reactLocalStorage.getObject("var") &&
          reactLocalStorage.getObject("var");

      const dateRange = posterSettings.chartPeriod;

      switch (dateRange) {
        case "":
        case undefined:
          fetchData(
            "day",
            366,
            currentCurrency.Internal ? currentCurrency.Internal : "BTC"
          );
          break;
        case "1D":
          fetchData(
            "minute",
            1000,
            currentCurrency.Internal ? currentCurrency.Internal : "BTC"
          );
          break;
        case "7D":
          fetchData(
            "hour",
            168,
            currentCurrency.Internal ? currentCurrency.Internal : "BTC"
          );
          break;
        case "1M":
          fetchData(
            "hour",
            720,
            currentCurrency.Internal ? currentCurrency.Internal : "BTC"
          );
          break;
        case "3M":
          fetchData(
            "hour",
            browserName === "Firefox" ? 999 : 2000,
            currentCurrency.Internal ? currentCurrency.Internal : "BTC"
          );
          break;
        case "1Y":
          fetchData(
            "day",
            365,
            currentCurrency.Internal ? currentCurrency.Internal : "BTC"
          );
          break;
        case "YTD":
          if (moment().dayOfYear() - 1 < 83) {
            fetchData(
              "hour",
              (moment().dayOfYear() - 1) * 24,
              currentCurrency.Internal ? currentCurrency.Internal : "BTC"
            );
          } else {
            fetchData(
              "day",
              moment().dayOfYear() - 1,
              currentCurrency.Internal ? currentCurrency.Internal : "BTC"
            );
          }
          break;
        case "ALL":
          fetchData(
            "day",
            browserName === "Firefox" ? 999 : 2000,
            currentCurrency.Internal ? currentCurrency.Internal : "BTC"
          );
          break;
        default:
          break;
      }
    }
  }, [userPickedLabelDateRange, currentCurrency, wholeStore.chartPeriod]);

  useEffect(() => {
    wholeStore &&
      wholeStore.allCurrencies &&
      wholeStore.allCurrencies.length &&
      setCurrencies(wholeStore.allCurrencies);
  }, [wholeStore]);

  useEffect(() => {
    dispatch(setChosenSlogan(userSlogan));
  }, [userSlogan]);

  useEffect(() => {
    setShowSettings(settings);
  }, [settings]);

  useEffect(() => {
    let quote = {
      quote: userCheckedValues.includes(0)
        ? quoteGalleryTexts[0]
        : userCheckedValues.includes(2)
        ? quoteGalleryTexts[2]
        : userCheckedValues.includes(4)
        ? quoteGalleryTexts[4]
        : userCheckedValues.includes(6)
        ? quoteGalleryTexts[6]
        : userCheckedValues.includes(8)
        ? quoteGalleryTexts[8]
        : userCheckedValues.includes(10)
        ? quoteGalleryTexts[10]
        : userCheckedValues.includes(12)
        ? quoteGalleryTexts[12]
        : userCheckedValues.includes(14)
        ? quoteGalleryTexts[14]
        : userCheckedValues.includes(16)
        ? quoteGalleryTexts[16]
        : userCheckedValues.includes(18)
        ? quoteGalleryTexts[18]
        : userCheckedValues.includes(20)
        ? quoteGalleryTexts[20]
        : "",
      userQuoteAuthorFirstLine: userCheckedValues.includes(1)
        ? quoteGalleryTexts[1]
        : userCheckedValues.includes(3)
        ? quoteGalleryTexts[3]
        : userCheckedValues.includes(5)
        ? quoteGalleryTexts[5]
        : userCheckedValues.includes(7)
        ? quoteGalleryTexts[7]
        : userCheckedValues.includes(9)
        ? quoteGalleryTexts[9]
        : userCheckedValues.includes(11)
        ? quoteGalleryTexts[11]
        : userCheckedValues.includes(13)
        ? quoteGalleryTexts[13]
        : userCheckedValues.includes(15)
        ? quoteGalleryTexts[15]
        : userCheckedValues.includes(17)
        ? quoteGalleryTexts[17]
        : userCheckedValues.includes(19)
        ? quoteGalleryTexts[19]
        : "",
    };

    if (
      posterSettings &&
      posterSettings.quoteArr &&
      posterSettings.quoteArr.length &&
      posterSettings.quoteArr != userCheckedValues
    ) {
      dispatch(changeQuoteArr(userCheckedValues));
      dispatch(changeGalleryQuote(quote));
      quote.quote !== "" && dispatch(setChosenQuote(quote));
    }
  }, [userCheckedValues]);

  useEffect(() => {
    fetchTopCurrencies();
    setAfterInitialRender(true);
    // handleChangeCurrency(
    //   posterSettings.currency == "Bitcoin"
    //     ? "Bitcoin"
    //     : posterSettings.currency.FullName
    //     ? posterSettings.currency.FullName
    //     : null
    // );
  }, []);

  const setChartTheme = (theme) => {
    dispatch(changeChartTheme(theme));
    setTheme(theme);
  };

  // SHOW FUNCTIONS
  const setChangeShowLogo = () => {
    dispatch(changeShowLogoAction(!showCryptoName));
    setShowCryptoName(!showCryptoName);
    changeGlobalShowCryptoName(!showCryptoName);
  };

  const setChangeShowDate = () => {
    dispatch(changeShowDateAction(!showSettings.showDate));
  };

  const setShowCryptoPrice = () => {
    dispatch(changeShowCryptoPriceAction(!showSettings.showCryptoPrice));
  };

  const setShowMarketDominance = () => {
    dispatch(
      changeShowMarketDominanceAction(!showSettings.showMarketDominance)
    );
  };

  const setShowMarketCap = () => {
    dispatch(changeShowMarketCapAction(!showSettings.showMarketCap));
  };

  const setShowQuote = () => {
    dispatch(changeShowQuote(!globalShowQuote));
    setGlobalShowQuote(!globalShowQuote);
    changeGlobalShowQuote(!globalShowQuote);
  };

  const setShowSlogan = () => {
    dispatch(changeShowSlogan(!showSettings.showSlogan));
  };

  const setShowMarketRank = () => {
    dispatch(changeShowMArketRank(!showSettings.showMarketRank));
  };

  const handleTextAreaUserSlogan = (e) => {
    if (e.target.value.length > 30) {
      return;
    } else {
      setTempUserSlogan(e.target.value);
      setTempUserSloganLength(e.target.value.length);
    }
  };

  const handleTextAreaUserQuote = (e) => {
    if (e.target.value.length > 140) {
      return;
    } else {
      setTempUserQuote({
        userQuote: e.target.value,
        userQuoteAuthorFirstLine: tempUserQuote.userQuoteAuthorFirstLine,
      });
      setTempUserQuoteLength({
        userQuoteLength: e.target.value.length,
        userQuoteAuthorFirstLineLength:
          tempUserQuote.userQuoteAuthorFirstLine.length,
      });
    }
  };
  const pushUserToNextStep = async () => {
    toggleLoader();
    createImage();
  };

  const handleTextAreaUserQuoteAuthor = (e) => {
    if (e.target.value.length > 50) {
      return;
    } else {
      setTempUserQuote({
        userQuote: tempUserQuote.userQuote,
        userQuoteAuthorFirstLine: e.target.value,
      });
      setTempUserQuoteLength({
        userQuoteLength: tempUserQuote.userQuote.length,
        userQuoteAuthorFirstLineLength: e.target.value.length,
      });
    }
  };

  const changeRadioInput = async (e) => {
    setUserCheckedValues([]);

    if (e.target.value % 2 == 0) {
      setUserCheckedValues([e.target.value, e.target.value + 1]);
    } else {
      setUserCheckedValues([e.target.value]);
    }

    if (!userCheckedValues.includes(e.target.value)) {
      if (
        e.target.value % 2 != 0 &&
        userCheckedValues[0] + 1 == e.target.value
      ) {
        setUserCheckedValues([...userCheckedValues, e.target.value]);
      }
    } else {
      let newValues = userCheckedValues.filter(
        (value) => value != e.target.value
      );

      setUserCheckedValues(newValues);
    }

    // setQuote("galleryQuote");
  };

  const setQuote = (mode) => {
    if (mode === "galleryQuote") {
      let quote = {
        quote: userCheckedValues.includes(0)
          ? quoteGalleryTexts[0]
          : userCheckedValues.includes(2)
          ? quoteGalleryTexts[2]
          : userCheckedValues.includes(4)
          ? quoteGalleryTexts[4]
          : userCheckedValues.includes(6)
          ? quoteGalleryTexts[6]
          : userCheckedValues.includes(8)
          ? quoteGalleryTexts[8]
          : userCheckedValues.includes(10)
          ? quoteGalleryTexts[10]
          : "",
        userQuoteAuthorFirstLine: userCheckedValues.includes(1)
          ? quoteGalleryTexts[1]
          : userCheckedValues.includes(3)
          ? quoteGalleryTexts[3]
          : "",
      };
      setUserQuote(quote);
    } else {
      let quote = {
        quote: tempUserQuote.userQuote,
        userQuoteAuthorFirstLine: tempUserQuote.userQuoteAuthorFirstLine,
      };
      setUserQuote({
        quote: tempUserQuote.userQuote,
        userQuoteAuthorFirstLine: tempUserQuote.userQuoteAuthorFirstLine,
      });
      dispatch(changeUserQuote(quote));

      dispatch(setChosenQuote(quote));
      setUserCheckedValues([]);
    }
  };

  return (
    <SettingsContainer theme={theme} className="settings-container">
      <SettingsBox className="setting-box">
        <PosterPageSteps
          className="poster-page-steps"
          theme={theme}
          activeStep={1}
        />

        {/* Crypto Row */}
        <SettingsRow>
          <MainHeader className="mobile-disable" theme={theme}>
            Kreator
          </MainHeader>
        </SettingsRow>

        <SettingsRow
          padding={`${dimensionHeightFix(30)}px ${0}px ${dimensionHeightFix(
            5
          )}px ${0}px`}
          className="crypto-row"
        >
          <SettingsColumn
            className="crypto-row__header-column"
            fDir={"row"}
            width={"33%"}
            justify="space-between"
          >
            <RowHeader theme={theme}>Wykres</RowHeader>
          </SettingsColumn>

          <SettingsColumn
            fDir={"row"}
            width={"33%"}
            justify="flex-end"
            alignItems="flex-start"
            className="crypto-row__settings-column"
          >
            <SettingsRow
              className="crypto-row__settings-column__currency-row"
              widthPercentage={"70%"}
              justify="flex-end"
            >
              <RowTextBig theme={theme}>Wybierz krypto</RowTextBig>
            </SettingsRow>
            <SettingsRow
              className="crypto-row__settings-column__currency-choose-btn-row"
              widthPercentage={"30%"}
            >
              <ChoseButton onClick={() => toggleCurrencyModal()} theme={theme}>
                wybierz
              </ChoseButton>
            </SettingsRow>
          </SettingsColumn>

          <SettingsColumn fDir={"row"} width={"33%"} />
        </SettingsRow>

        {/* <SettingsRow
          flexWrap={"wrap"}
          padding={`${dimensionHeightFix(5)}px ${0}px`}
        >
          <SettingsColumn
            className="style-row__style-header"
            width={"33%"}
            alignItems="flex-start"
          ></SettingsColumn>
          <SettingsColumn
            className="style-row__style-setting-column1"
            width={"33%"}
            fDir="row"
          >
            <SettingsRow widthPercentage={"70%"} justify="flex-end">
              <RowTextBig theme={theme}>Średnia cena</RowTextBig>
            </SettingsRow>
            <SettingsRow
              className="empty-row"
              widthPercentage={"30%"}
              justify="flex-end"
            ></SettingsRow>
          </SettingsColumn>
          <SettingsColumn
            className="style-row__style-setting-column2"
            width={"33%"}
          >
            <SettingsRow
              marginLeft={0}
              // className="first-switch style-row__style-setting-column2__switch"
              justify="flex-end"
              alignItems="center"
            >
              <SettingText
                className="switch-text"
                fWeight={500}
                theme={theme}
                marginRight={8}
              >
                Pokaż
              </SettingText>
              <StyledSwitch
                onChange={() => {
                  chosenTheme === "dark"
                    ? setChartTheme("light")
                    : setChartTheme("dark");
                }}
              />
              <SettingText
                className="switch-text"
                fWeight={500}
                theme={theme}
                marginLeft={8}
              >
                Ukryj
              </SettingText>
            </SettingsRow>
          </SettingsColumn>
        </SettingsRow> */}

        <SettingsRow
          padding={`${dimensionHeightFix(5)}px ${0}px ${dimensionHeightFix(
            10
          )}px ${0}px `}
          className="crypto-row"
        >
          <SettingsColumn
            className="crypto-row__header-column"
            fDir={"row"}
            width={"33%"}
            justify="space-between"
          ></SettingsColumn>

          <SettingsColumn
            fDir={"row"}
            width={"33%"}
            justify="flex-end"
            alignItems="flex-start"
            className="crypto-row__settings-column"
          >
            <SettingsRow
              className="crypto-row__settings-column__currency-row"
              widthPercentage={"70%"}
              justify="flex-end"
            >
              <RowTextBig theme={theme}>Okres czasu</RowTextBig>
            </SettingsRow>
            <SettingsRow
              className="crypto-row__settings-column__currency-choose-btn-row"
              widthPercentage={"30%"}
            >
              <ChoseButton
                theme={theme}
                onClick={() => toggleChartPeriodModal()}
              >
                wybierz
              </ChoseButton>
            </SettingsRow>
          </SettingsColumn>
          <SettingsColumn fDir={"row"} width={"33%"} />
        </SettingsRow>

        <SettingsRow
          padding={`${dimensionHeightFix(0)}px ${0}px ${dimensionHeightFix(
            10
          )}px ${0}px `}
          className="crypto-row"
        >
          <SettingsColumn
            className="crypto-row__header-column"
            fDir={"row"}
            width={"33%"}
            justify="space-between"
          ></SettingsColumn>

          <SettingsColumn
            fDir={"row"}
            width={"33%"}
            justify="flex-end"
            alignItems="flex-start"
            className="crypto-row__settings-column"
          >
            <SettingsRow
              className="crypto-row__settings-column__currency-row"
              widthPercentage={"70%"}
              justify="flex-end"
              tAlign={"right"}
            >
              <RowTextBig theme={theme}>Kiedy zainwestowałeś?</RowTextBig>
            </SettingsRow>
            <SettingsRow
              className="crypto-row__settings-column__currency-choose-btn-row"
              widthPercentage={"30%"}
            >
              <ChoseButton
                theme={theme}
                onClick={() => toggleStartInvestmentModal()}
              >
                wybierz
              </ChoseButton>
            </SettingsRow>
          </SettingsColumn>
          <SettingsColumn fDir={"row"} width={"33%"}>
            <SettingsRow
              className="show-logo__column2__switch"
              justify="flex-end"
              alignItems="center"
            >
              <SettingText
                className="switch-text"
                fWeight={500}
                theme={theme}
                marginRight={8}
              >
                Pokaż
              </SettingText>

              <StyledSwitch
                checked={!wholeStore.showStartInvest}
                onChange={() => handleChangeShowStartInvest()}
              />
              <SettingText
                className="switch-text"
                fWeight={500}
                theme={theme}
                marginLeft={8}
              >
                Ukryj
              </SettingText>
            </SettingsRow>
          </SettingsColumn>
        </SettingsRow>

        {/* Style row */}

        <SettingsRow
          flexWrap={"wrap"}
          showTopBorder
          theme={theme}
          padding={`${dimensionHeightFix(10)}px ${0}px`}
          alignItems={"center"}
          className="style-row"
          width={"100%"}
        >
          <SettingsRow
            flexWrap={"wrap"}
            padding={`${dimensionHeightFix(5)}px ${0}px`}
          >
            <SettingsColumn
              className="style-row__style-header"
              width={"33%"}
              alignItems="flex-start"
            >
              <RowHeader theme={theme}>Styl</RowHeader>
            </SettingsColumn>
            <SettingsColumn
              className="style-row__style-setting-column1"
              width={"33%"}
              fDir="row"
            >
              <SettingsRow widthPercentage={"70%"} justify="flex-end">
                <RowTextBig theme={theme}>Styl</RowTextBig>
              </SettingsRow>
              <SettingsRow
                className="empty-row"
                widthPercentage={"30%"}
                justify="flex-end"
              ></SettingsRow>
            </SettingsColumn>
            <SettingsColumn
              className="style-row__style-setting-column2"
              width={"33%"}
            >
              <SettingsRow
                marginLeft={40}
                className="first-switch style-row__style-setting-column2__switch"
                justify="flex-end"
                alignItems="center"
              >
                <SettingText
                  className="switch-text"
                  fWeight={500}
                  theme={theme}
                  marginRight={8}
                >
                  Jasny
                </SettingText>
                <StyledSwitch
                  checked={chosenTheme == "dark"}
                  onChange={() => {
                    chosenTheme === "dark"
                      ? setChartTheme("light")
                      : setChartTheme("dark");
                  }}
                />
                <SettingText
                  className="switch-text"
                  fWeight={500}
                  theme={theme}
                  marginLeft={8}
                >
                  Ciemny
                </SettingText>
              </SettingsRow>
            </SettingsColumn>
          </SettingsRow>

          <SettingsRow
            padding={`${dimensionHeightFix(5)}px ${0}px`}
            className="show-logo-row"
          >
            <SettingsColumn width="33%" className="empty-row" />
            <SettingsColumn
              width="33%"
              fDir="row"
              className="show-logo__column1"
            >
              <SettingsRow
                className="show-logo__column1__text"
                widthPercentage={"70%"}
                justify="flex-end"
              >
                <RowTextBig tAlign="right" theme={theme}>
                  Logo Tradegraphy
                </RowTextBig>
              </SettingsRow>
              <SettingsRow
                className="empty-row"
                widthPercentage={"30%"}
              ></SettingsRow>
            </SettingsColumn>
            <SettingsColumn className="show-logo__column2" width="33%">
              <SettingsRow
                className="show-logo__column2__switch"
                justify="flex-end"
                alignItems="center"
              >
                <SettingText
                  className="switch-text"
                  fWeight={500}
                  theme={theme}
                  marginRight={8}
                >
                  Pokaż
                </SettingText>

                <StyledSwitch
                  // defaultChecked={!posterSettings.showTradegraphy}
                  checked={!showTradegraphy}
                  onChange={() => handleChangeShowTradeGraphyLogo()}
                />
                <SettingText
                  className="switch-text"
                  fWeight={500}
                  theme={theme}
                  marginLeft={8}
                >
                  Ukryj
                </SettingText>
              </SettingsRow>
            </SettingsColumn>
          </SettingsRow>
        </SettingsRow>

        {/* Details row */}

        <SettingsRow
          flexWrap={"wrap"}
          showTopBorder
          theme={theme}
          padding={`${dimensionHeightFix(10)}px ${0}px`}
          alignItems={"center"}
          className="style-row"
          width={"100%"}
        >
          <SettingsRow
            className="date-row"
            padding={`${dimensionHeightFix(5)}px ${0}px`}
          >
            <SettingsColumn width={"33%"} alignItems="flex-start">
              <RowHeader className="absolute-pos" theme={theme}>
                Szczegóły walut
              </RowHeader>
            </SettingsColumn>
            <SettingsColumn
              className="show-logo__column1"
              width="33%"
              fDir="row"
            >
              <SettingsRow widthPercentage={"70%"} justify="flex-end">
                <RowTextBig tAlign="right" theme={theme}>
                  Średnia cena
                </RowTextBig>
              </SettingsRow>
              <SettingsRow
                className="empty-row"
                widthPercentage={"30%"}
              ></SettingsRow>
            </SettingsColumn>
            <SettingsColumn width="33%">
              <SettingsRow justify="flex-end" alignItems="center">
                <SettingText
                  className="switch-text"
                  fWeight={500}
                  theme={theme}
                  marginRight={8}
                >
                  Pokaż
                </SettingText>

                <StyledSwitch
                  checked={!showAvaragePrice}
                  onChange={() => handleChangeShowAvaragePrice()}
                />
                <SettingText
                  className="switch-text"
                  fWeight={500}
                  theme={theme}
                  marginLeft={8}
                >
                  Ukryj
                </SettingText>
              </SettingsRow>
            </SettingsColumn>
          </SettingsRow>

          <SettingsRow
            padding={`${dimensionHeightFix(5)}px ${0}px`}
            className="show-logo-row"
          >
            <SettingsColumn width="33%" className="empty-row" />
            <SettingsColumn
              width="33%"
              fDir="row"
              className="show-logo__column1"
            >
              <SettingsRow
                className="show-logo__column1__text"
                widthPercentage={"70%"}
                justify="flex-end"
              >
                <RowTextBig tAlign="right" theme={theme}>
                  Nazwa waluty
                </RowTextBig>
              </SettingsRow>
              <SettingsRow
                className="empty-row"
                widthPercentage={"30%"}
              ></SettingsRow>
            </SettingsColumn>
            <SettingsColumn className="show-logo__column2" width="33%">
              <SettingsRow
                className="show-logo__column2__switch"
                justify="flex-end"
                alignItems="center"
              >
                <SettingText
                  className="switch-text"
                  fWeight={500}
                  theme={theme}
                  marginRight={8}
                >
                  Pokaż
                </SettingText>
                <StyledSwitch
                  checked={!showCryptoName}
                  onChange={() => setChangeShowLogo()}
                />
                <SettingText
                  className="switch-text"
                  fWeight={500}
                  theme={theme}
                  marginLeft={8}
                >
                  Ukryj
                </SettingText>
              </SettingsRow>
            </SettingsColumn>
          </SettingsRow>

          <SettingsRow padding={`${dimensionHeightFix(5)}px ${0}px`}>
            <SettingsColumn width="33%" className="empty-row" />
            <SettingsColumn width={"33%"} fDir="row">
              <SettingsRow widthPercentage={"70%"} justify="flex-end">
                <RowTextBig tAlign="right" theme={theme}>
                  Statystyki krypto
                </RowTextBig>
              </SettingsRow>
              <SettingsRow
                widthPercentage={"30%"}
                justify="flex-end"
              ></SettingsRow>
            </SettingsColumn>
            <SettingsColumn width={"33%"}>
              <SettingsRow justify="flex-end" alignItems="center">
                <SettingText
                  className="switch-text"
                  fWeight={500}
                  theme={theme}
                  marginRight={8}
                >
                  Pokaż
                </SettingText>
                <StyledSwitch
                  checked={!showCryptoStats}
                  onChange={handleChangeShowCryptoStats}
                />
                <SettingText
                  className="switch-text"
                  fWeight={500}
                  theme={theme}
                  marginLeft={8}
                >
                  Ukryj
                </SettingText>
              </SettingsRow>
            </SettingsColumn>
          </SettingsRow>

          {/* <SettingsRow padding={`${dimensionHeightFix(5)}px ${0}px`}>
            <SettingsColumn width="33%" />
            <SettingsColumn width="33%" fDir="row">
              <SettingsRow widthPercentage={"70%"} justify="flex-end">
                <RowTextBig tAlign="right" theme={theme}>
                  Market dominance
                </RowTextBig>
              </SettingsRow>
              <SettingsRow widthPercentage={"30%"}></SettingsRow>
            </SettingsColumn>
            <SettingsColumn width="33%">
              <SettingsRow justify="flex-end" alignItems="center">
                <SettingText
                  className="switch-text"
                  fWeight={500}
                  theme={theme}
                  marginRight={8}
                >
                  Pokaż
                </SettingText>
                <StyledSwitch onChange={() => setShowMarketDominance()} />
                <SettingText
                  className="switch-text"
                  fWeight={500}
                  theme={theme}
                  marginLeft={8}
                >
                  Ukryj
                </SettingText>
              </SettingsRow>
            </SettingsColumn>
          </SettingsRow> */}

          {/* <SettingsRow padding={`${dimensionHeightFix(5)}px ${0}px`}>
            <SettingsColumn width="33%" />
            <SettingsColumn width="33%" fDir="row">
              <SettingsRow widthPercentage={"70%"} justify="flex-end">
                <RowTextBig tAlign="right" theme={theme}>
                  Market cap
                </RowTextBig>
              </SettingsRow>
              <SettingsRow widthPercentage={"30%"}></SettingsRow>
            </SettingsColumn>
            <SettingsColumn width="33%">
              <SettingsRow justify="flex-end" alignItems="center">
                <SettingText
                  className="switch-text"
                  fWeight={500}
                  theme={theme}
                  marginRight={8}
                >
                  Pokaż
                </SettingText>
                <StyledSwitch onChange={() => setShowMarketCap()} />
                <SettingText
                  className="switch-text"
                  fWeight={500}
                  theme={theme}
                  marginLeft={8}
                >
                  Ukryj
                </SettingText>
              </SettingsRow>
            </SettingsColumn>
          </SettingsRow> */}

          {/* <SettingsRow padding={`${dimensionHeightFix(5)}px ${0}px`}>
            <SettingsColumn width="33%" />
            <SettingsColumn width="33%" fDir="row">
              <SettingsRow widthPercentage={"70%"} justify="flex-end">
                <RowTextBig tAlign="right" theme={theme}>
                  Market rank
                </RowTextBig>
              </SettingsRow>
              <SettingsRow widthPercentage={"30%"}></SettingsRow>
            </SettingsColumn>
            <SettingsColumn width="33%">
              <SettingsRow justify="flex-end" alignItems="center">
                <SettingText
                  className="switch-text"
                  fWeight={500}
                  theme={theme}
                  marginRight={8}
                >
                  Pokaż
                </SettingText>
                <StyledSwitch onChange={() => setShowMarketRank()} />
                <SettingText
                  className="switch-text"
                  fWeight={500}
                  theme={theme}
                  marginLeft={8}
                >
                  Ukryj
                </SettingText>
              </SettingsRow>
            </SettingsColumn>
          </SettingsRow> */}
        </SettingsRow>

        {/* Signatures row */}

        <SettingsRow
          flexWrap={"wrap"}
          showTopBorder
          theme={theme}
          padding={`${dimensionHeightFix(10)}px ${0}px`}
          alignItems={"center"}
          className="style-row"
          width={"100%"}
          margin={"0% 0 0 0"}
        >
          <SettingsRow padding={`${dimensionHeightFix(5)}px ${0}px`}>
            <SettingsRow>
              <SettingsColumn width={"33%"} alignItems="flex-start">
                <RowHeader theme={theme}>Podpisy</RowHeader>
              </SettingsColumn>

              <SettingsColumn width="33%" fDir="row">
                <SettingsRow widthPercentage={"70%"} justify="flex-end">
                  <RowTextBig tAlign="right" theme={theme}>
                    Cytat
                  </RowTextBig>
                </SettingsRow>
                <SettingsRow widthPercentage={"30%"}>
                  <ChoseButton theme={theme} onClick={() => toggleQuoteModal()}>
                    wybierz
                  </ChoseButton>
                </SettingsRow>
              </SettingsColumn>
              <SettingsColumn width="33%">
                <SettingsRow justify="flex-end" alignItems="center">
                  <SettingText
                    className="switch-text"
                    fWeight={500}
                    theme={theme}
                    marginRight={8}
                  >
                    Pokaż
                  </SettingText>

                  <StyledSwitch
                    checked={!globalShowQuote}
                    onChange={() => setShowQuote()}
                  />
                  <SettingText
                    className="switch-text"
                    fWeight={500}
                    theme={theme}
                    marginLeft={8}
                  >
                    Ukryj
                  </SettingText>
                </SettingsRow>
              </SettingsColumn>

              {/* <SettingsColumn width={"33%"} fDir="row">
                <SettingsRow widthPercentage={"70%"} justify="flex-end">
                  <RowTextBig theme={theme}>Hasło</RowTextBig>
                </SettingsRow>
                <SettingsRow widthPercentage={"30%"}>
                  <ChoseButton
                    theme={theme}
                    onClick={() => setSloganModalOpen(true)}
                  >
                    wybierz
                  </ChoseButton>
                </SettingsRow>
              </SettingsColumn> */}
              {/* <SettingsColumn width={"33%"}>
                <SettingsRow justify="flex-end" alignItems="center">
                  <SettingText
                    className="switch-text"
                    fWeight={500}
                    theme={theme}
                    marginRight={8}
                  >
                    Pokaż
                  </SettingText>
                  <StyledSwitch onChange={setShowSlogan} />
                  <SettingText
                    className="switch-text"
                    fWeight={500}
                    theme={theme}
                    marginLeft={8}
                  >
                    Ukryj
                  </SettingText>
                </SettingsRow>
              </SettingsColumn> */}
            </SettingsRow>
          </SettingsRow>
        </SettingsRow>

        {isMobile && (
          <SettingsRow
            padding={`${dimensionHeightFix(10)}px ${0}px`}
            alignItems={"center"}
          >
            <SettingsColumn
              fDir={"row"}
              width={isMobile ? "100%" : "50%"}
              alignSelf={"flex-start"}
              justify={isMobile ? "center" : "space-between"}
            >
              <SectionButton
                className="mobile-button reveal-poster"
                theme={theme}
                padding={`${dimensionHeightFix(10) + "px"} ${
                  dimensionWidthFix(45) + "px"
                }`}
                onClick={() => toggleShowMobilePoster()}
              >
                <Text color={"white"}>Podgląd</Text> <AiOutlineEye />
              </SectionButton>

              {/* <button
              onClick={() =>
                history.push({
                  pathname: "/generuj-obraz",
                  state: {
                    theme: theme,
                    avarageBitcoinPrice: avarageBitcoinPrice,
                    showSettings: showSettings,
                    chosenSlogan: chosenSlogan,
                    formattedData: formattedData,
                    quote: quote,
                  },
                })
              }
            >
              Generuj
            </button> */}
              {/* <Select
              value={wholeStore.format || formatModes[0]}
              onChange={handleChangeFormat}
              options={formatModes}
              placeholder={"wybierz format"}
            /> */}

              {/* <div>
              <DatePicker
                isOpen={true}
                open
                format="y-MM-dd"
                onChange={handleChangeInvestStartDate}
                value={investStartDate}
              />
            </div> */}
            </SettingsColumn>
          </SettingsRow>
        )}

        <SettingsRow
          padding={`${dimensionHeightFix(10)}px ${0}px`}
          alignItems={"center"}
        >
          <SettingsColumn
            fDir={"row"}
            width={isMobile ? "100%" : "50%"}
            alignSelf={"flex-start"}
            justify={isMobile ? "center" : "space-between"}
          >
            <SectionButton
              className="mobile-button"
              theme={theme}
              padding={`${dimensionHeightFix(10) + "px"} ${
                dimensionWidthFix(45) + "px"
              }`}
              onClick={() => pushUserToNextStep()}
            >
              Przejdź dalej
            </SectionButton>
          </SettingsColumn>
        </SettingsRow>
      </SettingsBox>

      <Modal
        isOpen={
          isSloganModalOpen ||
          isQuoteModalOpen ||
          isCalendarModalOpen ||
          isStartInvestmentModalOpen ||
          isCurrencyModalOpen
        }
        // onAfterOpen={afterOpenModal}
        onRequestClose={() => closeAllModals()}
        style={settingsModalStyles}
        contentLabel="Example Modal"
      >
        {isSloganModalOpen ? (
          <SettingsModalSlogan
            theme={theme}
            galleryTexts={galleryTexts}
            handleTextAreaUserSlogan={handleTextAreaUserSlogan}
            userSlogan={userSlogan}
            setUserSlogan={setUserSlogan}
            tempUserSlogan={tempUserSlogan}
            tempUserSloganLength={tempUserSloganLength}
            setSloganModalOpen={setSloganModalOpen}
          />
        ) : isCalendarModalOpen ? (
          <SettingsModalCalendar
            theme={theme}
            setCalendarModalOpen={toggleChartPeriodModal}
            dateRanges={dateRanges}
            userPickedDateRange={userPickedDateRange}
            setUserPickedDateRange={setUserPickedDateRange}
            userDateRangeDays={userDateRangeDays}
            setUserCheckedValues={setUserCheckedValues}
            setUserPickedLabelDateRange={setUserPickedLabelDateRange}
            userPickedLabelDateRange={userPickedLabelDateRange}
          />
        ) : isStartInvestmentModalOpen ? (
          <StartInvestmentModal
            theme={theme}
            setStartInvestmentModalOpen={toggleStartInvestmentModal}
            handleChangeInvestStartDate={handleChangeInvestStartDate}
            investStartDate={investStartDate}
            isStartInvestValidationModalOpen={isStartInvestValidationModalOpen}
            setStartInvestValidationModalOpen={
              setStartInvestValidationModalOpen
            }
          ></StartInvestmentModal>
        ) : isCurrencyModalOpen ? (
          <CurrencyModal
            actualCurrency={wholeStore.currency}
            theme={theme}
            setCurrencyModalOpen={toggleCurrencyModal}
            currencies={currencies}
            handleChangeCurrency={handleChangeCurrency}
            allCurrencies={allCurrencies}
            toggleCurrencyModal={toggleCurrencyModal}
          />
        ) : isQuoteModalOpen ? (
          <SettingsModalQuote
            theme={theme}
            galleryTexts={galleryTexts}
            // handleTextAreaUserSlogan={handleTextAreaUserSlogan}
            handleTextAreaUserQuoteAuthor={handleTextAreaUserQuoteAuthor}
            handleTextAreaUserQuote={handleTextAreaUserQuote}
            tempUserQuoteLength={tempUserQuoteLength}
            tempUserQuote={tempUserQuote}
            userSlogan={userSlogan}
            setUserSlogan={setUserSlogan}
            tempUserSlogan={tempUserSlogan}
            tempUserSloganLength={tempUserSloganLength}
            setQuoteModalOpen={toggleQuoteModal}
            quoteGalleryTexts={quoteGalleryTexts}
            changeRadioInput={changeRadioInput}
            userCheckedValues={userCheckedValues}
            setQuote={setQuote}
            toggleQuoteModal={toggleQuoteModal}
          />
        ) : null}
      </Modal>
    </SettingsContainer>
  );
};

export default Settings;
