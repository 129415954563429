import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import cookie from "react-cookies";
import { reactLocalStorage } from "reactjs-localstorage";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom";
import validator from "validator";
import axios from "axios";
import { isMobile } from "react-device-detect";
import { loadStripe } from "@stripe/stripe-js";
import { internatApiUrl } from "../../Api/ApiUrl";
import Loader from "../../GenericComponents/Loader.jsx";

// other components
import PosterPageMobileNav from "../PosterPage/PosterPageComponents/PosterPageMobileNav.jsx";
import NavigationHeader from "../MainPage/subComponents/NavigationHeader";
import PosterPageSteps from "../SettingsComponent/subComponents/PosterPageSteps.jsx";

import "@djthoms/pretty-checkbox";
// styles
import {
  Ccntainer,
  Row,
  Text,
  Column,
} from "../PosterSizesStepPage/style/PosterSizesStepPageStyle";

import {
  CartInput,
  PaymentColumnContainer,
  CartButton,
  CartImage,
  PosterImage,
  PayButton,
  CartTextArea,
} from "./style/CartPageStyle";
import removeIcon from "../../assets/remove-icon.svg";
import editIcon from "../../assets/edit-icon.svg";

// import {

// } from "../styles/SettingsStyles";

const CartPage = () => {
  // defining app entire state
  const wholeStore = useSelector((state) => state);
  const history = useHistory();
  const dispatch = useDispatch();

  const [deliverySettings, setDeliverySettings] = useState({
    name: "",
    lastName: "",
    email: "",
    phone: "",
    street: "",
    houseNumber: null,
    apartmentNumber: null,
    city: "",
    zipCode: "",
    country: "Polska",
    additionalInfo: "",
  });
  const [paymentSettings, setPaymentSettings] = useState({
    name: "",
    lastName: "",
    email: "",
    phone: "",
    street: "",
    houseNumber: null,
    apartmentNumber: null,
    city: "",
    zipCode: "",
    country: "Polska",
    agreementAcceptation: false,
    wantInvoice: false,
    nip: null,
    companyName: "",
  });

  const [paymentCompanyNameError, setPaymentCompanyError] = useState(false);
  const [paymentNipError, setPaymentNipError] = useState(false);
  const [paymentNameError, setPaymentNameError] = useState(false);
  const [paymentLastNameError, setPaymentLastNameError] = useState(false);
  const [paymentEmailError, setPaymentEmailError] = useState(false);
  const [paymentPhoneError, setPaymentPhoneError] = useState(false);
  const [
    paymentAgreementAcceptationError,
    setPaymentAgreementAcceptationError,
  ] = useState(false);
  const [paymentStreetError, setPaymentStreetError] = useState(false);
  const [paymentHouseNumberError, setPaymentHouseNumberError] = useState(false);
  // const [deliveryApartmentNumberError, setDeliveryApartmentNumberError] =
  //   useState(false);
  const [paymentCityError, setPaymentCityError] = useState(false);
  const [paymentZipCodeError, setPaymentZipCodeError] = useState(false);

  const [deliveryNameError, setDeliveryNameError] = useState(false);
  const [deliveryLastNameError, setDeliveryLastNameError] = useState(false);
  const [deliveryEmailError, setDeliveryEmailError] = useState(false);
  const [deliveryPhoneError, setDeliveryPhoneError] = useState(false);
  const [deliveryStreetError, setDeliveryStreetError] = useState(false);
  const [deliveryHouseNumberError, setDeliveryHouseNumberError] =
    useState(false);
  // const [deliveryApartmentNumberError, setDeliveryApartmentNumberError] =
  //   useState(false);
  const [deliveryCityError, setDeliveryCityError] = useState(false);
  const [deliveryZipCodeError, setDeliveryZipCodeError] = useState(false);

  const [paymentDataSameAsDelivery, setPaymentDataSameAsDelivery] =
    useState(false);

  // gather builded poster
  const posterSettings =
    wholeStore.chartData != null
      ? wholeStore
      : reactLocalStorage.getObject("var") &&
        reactLocalStorage.getObject("var");

  //local state
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isPosterExisting, setPosterExisting] = useState(false);
  const [posterImage, setPosterImage] = useState(null);
  const [createdPosterId, setCreatedPosterId] = useState(null);
  const [stripeSessionId, setStripeSessionId] = useState(null);
  const [showLoader, setShowLoader] = useState(false);

  //usefull variables

  //useEffects

  useEffect(() => {
    if (createdPosterId) {
      sendUserData();
    }
  }, [createdPosterId]);

  useEffect(() => {
    if (stripeSessionId != null) {
      reactLocalStorage.remove("var");
      cookie.remove("userEnteredCheckout", { path: "/" });
      reactLocalStorage.remove("posterImage");
      setPosterExisting(false);
      createStripeCheckout();
    }
  }, [stripeSessionId]);

  useEffect(() => {
    wholeStore.chartData !== null &&
      reactLocalStorage.setObject("var", wholeStore);
    localStorage.getItem("posterImage") &&
      cookie.save("userEnteredCheckout", true, {
        path: "/",
        maxAge: 24 * 60 * 60 * 365,
      });

    localStorage.getItem("posterImage") &&
      setPosterImage(localStorage.getItem("posterImage"));
  }, []);

  useEffect(() => {
    if (Object.keys(posterSettings).length) {
      setPosterExisting(true);
    }
  }, [posterSettings, isPosterExisting]);

  //local functions
  const handleHamburgerClick = () => {
    setMenuOpen(!isMenuOpen);
  };

  const removePoster = () => {
    reactLocalStorage.remove("var");
    cookie.remove("userEnteredCheckout", { path: "/" });
    reactLocalStorage.remove("posterImage");
    setPosterExisting(false);
    toast.success("Plakat został usunięty poprawnie");
    setTimeout(() => {
      history.replace({ pathname: "/", reload: true });
    }, 1500);
  };

  const handleClickPayButton = () => {
    let isError = true;
    let isTempError = false;

    setPaymentNameError(false);
    setPaymentLastNameError(false);
    setPaymentEmailError(false);
    setPaymentPhoneError(false);
    setPaymentAgreementAcceptationError(false);
    setPaymentNipError(false);
    setPaymentCompanyError(false);

    setPaymentStreetError(false);
    setPaymentHouseNumberError(false);
    // setDeliveryApartmentNumberError(false);
    setPaymentCityError(false);
    setPaymentZipCodeError(false);

    setDeliveryNameError(false);
    setDeliveryLastNameError(false);
    setDeliveryEmailError(false);
    setDeliveryPhoneError(false);
    setDeliveryStreetError(false);
    setDeliveryHouseNumberError(false);
    // setDeliveryApartmentNumberError(false);
    setDeliveryCityError(false);
    setDeliveryZipCodeError(false);

    if (deliverySettings.name.length < 1 || deliverySettings.name === "") {
      isTempError = true;
      setDeliveryNameError(true);
    } else {
      setDeliveryNameError(false);
    }
    if (
      deliverySettings.lastName.length < 1 ||
      deliverySettings.lastName === ""
    ) {
      isTempError = true;

      setDeliveryLastNameError(true);
    } else {
      setDeliveryLastNameError(false);
    }
    if (deliverySettings.email.length < 1 || deliverySettings.email === "") {
      isTempError = true;
      setDeliveryEmailError(true);
    } else {
      setDeliveryEmailError(false);
    }
    if (!validator.isEmail(deliverySettings.email)) {
      isTempError = true;
      setDeliveryEmailError(true);
    } else {
      setDeliveryEmailError(false);
    }
    if (!deliverySettings.phone) {
      isTempError = true;

      setDeliveryPhoneError(true);
    } else {
      setDeliveryPhoneError(false);
    }
    if (deliverySettings.street.length < 1 || deliverySettings.street === "") {
      isTempError = true;
      setDeliveryStreetError(true);
    } else {
      setDeliveryStreetError(false);
    }
    if (deliverySettings.houseNumber === null) {
      isTempError = true;
      setDeliveryHouseNumberError(true);
    } else {
      setDeliveryHouseNumberError(false);
    }
    // if (deliverySettings.apartmentNumber === null) {
    //   isTempError = true;
    //   setDeliveryApartmentNumberError(true);
    // } else {
    //   setDeliveryApartmentNumberError(false);
    // }
    if (deliverySettings.city.length < 1 || deliverySettings.city === "") {
      isTempError = true;

      setDeliveryCityError(true);
    } else {
      setDeliveryCityError(false);
    }
    if (
      deliverySettings.zipCode.length < 1 ||
      deliverySettings.zipCode === ""
    ) {
      isTempError = true;

      setDeliveryZipCodeError(true);
    } else {
      setDeliveryZipCodeError(false);
    }

    if (paymentDataSameAsDelivery) {
      if (paymentSettings.name.length < 1 || paymentSettings.name === "") {
        isTempError = true;
        setPaymentNameError(true);
      } else {
        setPaymentNameError(false);
      }
      if (
        paymentSettings.lastName.length < 1 ||
        paymentSettings.lastName === ""
      ) {
        isTempError = true;
        setPaymentLastNameError(true);
      } else {
        setPaymentLastNameError(false);
      }
      if (paymentSettings.email.length < 1 || paymentSettings.email === "") {
        isTempError = true;

        setPaymentEmailError(true);
      } else {
        setPaymentEmailError(false);
      }
      if (!validator.isEmail(paymentSettings.email)) {
        isTempError = true;
        setPaymentEmailError(true);
      } else {
        setPaymentEmailError(false);
      }

      if (!paymentSettings.phone || paymentSettings.phone.length == 0) {
        isTempError = true;

        setPaymentPhoneError(true);
      } else {
        setPaymentPhoneError(false);
      }

      if (paymentSettings.street.length < 1 || paymentSettings.street === "") {
        isTempError = true;
        setPaymentStreetError(true);
      } else {
        setPaymentStreetError(false);
      }
      if (paymentSettings.houseNumber === null) {
        isTempError = true;
        setPaymentHouseNumberError(true);
      } else {
        setPaymentHouseNumberError(false);
      }
      // if (deliverySettings.apartmentNumber === null) {
      //   isTempError = true;
      //   setDeliveryApartmentNumberError(true);
      // } else {
      //   setDeliveryApartmentNumberError(false);
      // }
      if (paymentSettings.city.length < 1 || paymentSettings.city === "") {
        isTempError = true;

        setPaymentCityError(true);
      } else {
        setPaymentCityError(false);
      }
      if (
        paymentSettings.zipCode.length < 1 ||
        paymentSettings.zipCode === ""
      ) {
        isTempError = true;

        setPaymentZipCodeError(true);
      } else {
        setPaymentZipCodeError(false);
      }
    }

    if (paymentSettings.wantInvoice) {
      if (!paymentSettings.nip) {
        isTempError = true;

        setPaymentNipError(true);
      } else {
        setPaymentNipError(false);
      }

      if (
        paymentSettings.companyName.length < 1 ||
        paymentSettings.companyName === ""
      ) {
        isTempError = true;

        setPaymentCompanyError(true);
      } else {
        setPaymentCompanyError(false);
      }
    }

    if (!paymentSettings.agreementAcceptation) {
      isTempError = true;
      setPaymentAgreementAcceptationError(true);
    } else {
      isError = false;
      setPaymentAgreementAcceptationError(false);
    }

    if (!isError && !isTempError) {
      handleSendData();
    } else {
      toast.error("Proszę wypełnić poprawnie wszystkie pola");
    }
  };

  const handleInputInfo = (mode, e) => {
    switch (mode) {
      case "deliveryAdditionalInfo":
        setDeliverySettings({ ...deliverySettings, additionalInfo: e });
        break;
      case "deliveryName":
        setDeliveryNameError(false);
        setDeliverySettings({ ...deliverySettings, name: e });
        break;
      case "deliveryLastName":
        setDeliveryLastNameError(false);
        setDeliverySettings({ ...deliverySettings, lastName: e });
        break;
      case "deliveryEmail":
        setDeliveryEmailError(false);
        setDeliverySettings({ ...deliverySettings, email: e });
        break;
      case "deliveryPhone":
        setDeliveryPhoneError(false);
        setDeliverySettings({ ...deliverySettings, phone: e });
        break;
      case "deliveryStreet":
        setDeliveryStreetError(false);
        setDeliverySettings({ ...deliverySettings, street: e });
        break;
      case "deliveryHouseNumber":
        setDeliveryHouseNumberError(false);
        setDeliverySettings({ ...deliverySettings, houseNumber: e });
        break;
      case "deliveryApartmentNumber":
        // setDeliveryApartmentNumberError(false);
        setDeliverySettings({ ...deliverySettings, apartmentNumber: e });
        break;
      case "deliveryCity":
        setDeliveryCityError(false);
        setDeliverySettings({ ...deliverySettings, city: e });
        break;
      case "deliveryZipCode":
        setDeliveryZipCodeError(false);
        setDeliverySettings({ ...deliverySettings, zipCode: e });
        break;
      case "paymentName":
        setPaymentNameError(false);
        setPaymentSettings({ ...paymentSettings, name: e });
        break;
      case "paymentLastName":
        setPaymentLastNameError(false);
        setPaymentSettings({ ...paymentSettings, lastName: e });
        break;
      case "paymentEmail":
        setPaymentEmailError(false);
        setPaymentSettings({ ...paymentSettings, email: e });
        break;
      case "paymentPhone":
        setPaymentPhoneError(false);
        setPaymentSettings({ ...paymentSettings, phone: e });
        break;
      case "paymentStreet":
        setPaymentStreetError(false);
        setPaymentSettings({ ...paymentSettings, street: e });
        break;
      case "paymentHouseNumber":
        setPaymentHouseNumberError(false);
        setPaymentSettings({ ...paymentSettings, houseNumber: e });
        break;
      case "paymentApartmentNumber":
        // setDeliveryApartmentNumberError(false);
        setPaymentSettings({ ...paymentSettings, apartmentNumber: e });
        break;
      case "paymentCity":
        setPaymentCityError(false);
        setPaymentSettings({ ...paymentSettings, city: e });
        break;
      case "paymentZipCode":
        setPaymentZipCodeError(false);
        setPaymentSettings({ ...paymentSettings, zipCode: e });
        break;

      case "paymentCompanyName":
        setPaymentCompanyError(false);
        setPaymentSettings({ ...paymentSettings, companyName: e });
        break;
      case "paymentNip":
        setPaymentNipError(false);
        setPaymentSettings({ ...paymentSettings, nip: e });
        break;
      default:
        break;
    }
  };

  const handleCheckboxPaymentSameAsDelivery = (value) => {
    setPaymentDataSameAsDelivery(value);
    setPaymentSettings({
      name: "",
      lastName: "",
      email: "",
      phone: "",
      street: "",
      houseNumber: null,
      apartmentNumber: null,
      city: "",
      zipCode: "",
      wantInvoice: false,
      companyName: "",
      nip: null,
    });
  };

  const createStripeCheckout = async (mode, e) => {
    const stripePromise = await loadStripe(
      "pk_live_51JnSObIeprYnYTBhgGW4ANb6PH2wPKgEiAtLhsF51LAZeUv8foad0l1WSRAgHVKDxW2WuZ3vMZM3ipWzai6bQVmh00tTIZFDA2"
    );

    const stripe = await stripePromise;

    await stripe.redirectToCheckout({
      sessionId: stripeSessionId,
    });
    setShowLoader(false);
  };

  const sendUserData = async () => {
    const sendUserDataConfig = {
      method: "post",
      url: `${internatApiUrl}/api/payments/payment_session/`,
      data: {
        chart_id: createdPosterId,
        fvat: paymentSettings.wantInvoice,
        additional_info: deliverySettings.additionalInfo,
        nip: paymentSettings.nip == null ? "" : paymentSettings.nip,
        company_name: paymentSettings.companyName,
        shipping: {
          email: deliverySettings.email,
          phone_number: deliverySettings.phone,
          full_name: `${deliverySettings.name} ${deliverySettings.lastName}`,
          street_address: deliverySettings.street,
          building_number: deliverySettings.houseNumber,
          // apartment_number: deliverySettings.apartmentNumber,
          apartment_number:
            deliverySettings.apartmentNumber === null
              ? ""
              : deliverySettings.apartmentNumber,
          zip_code: deliverySettings.zipCode,
          city: deliverySettings.city,
        },
        billing: {
          email: paymentDataSameAsDelivery
            ? paymentSettings.email
            : deliverySettings.email,
          phone_number: paymentDataSameAsDelivery
            ? paymentSettings.phone
            : deliverySettings.phone,
          full_name: paymentDataSameAsDelivery
            ? `${paymentSettings.name} ${paymentSettings.lastName}`
            : `${deliverySettings.name} ${deliverySettings.lastName}`,
          street_address: paymentDataSameAsDelivery
            ? paymentSettings.street
            : deliverySettings.street,
          building_number: paymentDataSameAsDelivery
            ? paymentSettings.houseNumber
            : deliverySettings.houseNumber,
          // apartment_number: paymentDataSameAsDelivery
          //   ? paymentSettings.apartmentNumber
          //   : deliverySettings.apartmentNumber,

          apartment_number: paymentDataSameAsDelivery
            ? paymentSettings.apartmentNumber === null
              ? ""
              : paymentSettings.apartmentNumber
            : deliverySettings.apartmentNumber === null
            ? ""
            : deliverySettings.apartmentNumber,
          zip_code: paymentDataSameAsDelivery
            ? paymentSettings.zipCode
            : deliverySettings.zipCode,
          city: paymentDataSameAsDelivery
            ? paymentSettings.city
            : deliverySettings.city,
        },
      },
    };

    axios(sendUserDataConfig)
      .then((response) => {
        setStripeSessionId(response.data.id);
      })
      .catch((error, response) => {
        console.log(error);
      });
  };

  const pushToPrivacy = () => {
    const win = window.open("/polityka-prywatnosci", "_blank");
    win.focus();
  };

  const sendPosterData = async () => {
    let posterId;
    const sendPosterConfig = {
      method: "post",
      url: `${internatApiUrl}/api/charts/`,
      data: {
        format: wholeStore.selectedFormat
          ? wholeStore.selectedFormat.toUpperCase()
          : posterSettings.selectedFormat.toUpperCase(),
        chart_settings: {
          poster_settings: {
            dateFrom: posterSettings.label.dateFrom,
            dateTo: posterSettings.label.dateTo,
            chart_period: posterSettings.chartPeriod,
            quote_text: posterSettings.quote.quote,
            quote_author: posterSettings.quote.userQuoteAuthorFirstLine,
            investment_start_date:
              posterSettings.investStartDate == null
                ? ""
                : posterSettings.investStartDate,
            theme: posterSettings.theme,
            currency: {
              full_name:
                posterSettings.currency == "Bitcoin"
                  ? "Bitcoin"
                  : posterSettings.currency.FullName,
              internal:
                posterSettings.currency == "Bitcoin"
                  ? "BTC"
                  : posterSettings.currency.Internal,
            },
            display_settings: {
              show_avarage_price: posterSettings.showSettings.showAvaragePrice,
              show_crypto_stats: posterSettings.showCryptoStats,
              show_date: true,
              show_coin_name: posterSettings.showSettings.showLogo,
              show_start_invest: posterSettings.showStartInvest,
              show_tradegraphy_logo: posterSettings.showTradegraphy,
              show_quote: posterSettings.showSettings.showQuote,
            },
            accentColor: posterSettings.accentColor,
          },
        },
        chart_data: posterSettings.chartData,
      },
      // headers: {
      //   "content-type": "multipart/form-data",
      // },
    };

    axios(sendPosterConfig)
      .then((response) => {
        posterId = response.data.id;
        setCreatedPosterId(response.data.id);
        return posterId;
      })
      .catch((error, response) => {
        console.log(error);
      });

    return posterId;
  };

  const handleSendData = async () => {
    setShowLoader(true);
    const posterId = await sendPosterData();
  };

  return (
    <>
      <Ccntainer className="cart-page__container">
        <PosterPageMobileNav
          theme={"light"}
          handleHamburgerClick={handleHamburgerClick}
        />
        <NavigationHeader
          isMenuOpen={isMenuOpen}
          handleHamburgerClick={handleHamburgerClick}
          position={"fixed"}
          theme={"light"}
        />
        {!posterImage ? (
          <Column width={"100%"} justify="center">
            <Row justify="center" margin={"20% 0 2% 0"}>
              <Text fSize={"1.4rem"}>Koszyk jest pusty</Text>
            </Row>
            <Row justify="center" margin={"3% 0"}>
              <PayButton onClick={() => history.push("/kreator")}>
                Wróć do kreatora
              </PayButton>
            </Row>
          </Column>
        ) : (
          <>
            <Column width={isMobile ? "100%" : "50%"}>
              <PosterPageSteps mode="cart" theme={"light"} activeStep={3} />
            </Column>
            <Row padding={isMobile && "0 2.5% 0 2.5%"} margin={"1% 0 0 0"}>
              <Text fWeight={500} fSize={"1.8rem"}>
                Podsumowanie
              </Text>
            </Row>

            {/* main content row */}
            <Row
              fDir={isMobile ? "column" : "row"}
              align={"flex-start"}
              margin={"2% 0 0 0"}
            >
              {/* Your purchase column */}
              <Column
                width={isMobile ? "100%" : "33%"}
                padding={isMobile ? "0 2.5% 0 2.5%" : "0 2% 0 0"}
              >
                <Row margin={isMobile ? "6% 0 6% 0" : "0 0 6% 0"}>
                  <Text fSize={"1.4rem"}>Twoje zakupy</Text>
                </Row>
                {/* chart row */}
                <Row>
                  <Column>
                    {isPosterExisting && (
                      <PosterImage
                        src={posterImage && posterImage}
                      ></PosterImage>
                    )}
                  </Column>
                  <Column
                    margin={"0 0 0 10%"}
                    alignSelf={"flex-start"}
                    align={"flex-start"}
                    width={"100%"}
                  >
                    <Row>
                      <CartButton onClick={() => removePoster()}>
                        Usuń
                      </CartButton>
                      <CartImage margin={"0 0 0 10px"} src={removeIcon} />
                    </Row>
                    <Row margin={"8% 0 0 0"}>
                      <CartButton onClick={() => history.push("/kreator")}>
                        Edytuj
                      </CartButton>
                      <CartImage margin={"0 0 0 10px"} src={editIcon} />
                    </Row>
                    <Row justify={"space-between"} margin={"12% 0 0 0"}>
                      <Text>
                        Rozmiar{" "}
                        <b className="posterSize">
                          {wholeStore.selectedFormat
                            ? wholeStore.selectedFormat.toUpperCase()
                            : posterSettings.selectedFormat}
                        </b>
                      </Text>
                      <Text>{`${
                        posterSettings.formatPrices[
                          wholeStore.selectedFormat
                            ? wholeStore.selectedFormat
                            : posterSettings.selectedFormat
                        ]
                      } zł`}</Text>
                    </Row>
                    <Row onClick={() => history.push("/wymiary")}>
                      <CartButton className="change-size-button">
                        zmień
                      </CartButton>
                    </Row>
                  </Column>
                </Row>
              </Column>

              {/* Delivery option column*/}
              <Column width={isMobile ? "100%" : "33%"} padding={"0 2%"}>
                <Row margin={"0% 0 6% 0"}>
                  <Text fSize={"1.4rem"}>Sposób dostawy</Text>
                </Row>
                <Row justify={"space-between"}>
                  <label class="container">
                    <Text>Kurier DPD</Text>
                    <input type="checkbox" checked="checked" />
                    <span class="checkmark"></span>
                  </label>

                  <>
                    <Text fSize={"0.85rem"} fWeight={300}>
                      Darmowa wysyłka
                    </Text>
                  </>
                </Row>

                <Row>
                  <Text fSize="0.9rem" fWeight="300">
                    Dostawa w 2-3 dni robocze
                  </Text>
                </Row>

                <Column width={"100%"} margin={"10% 0 0 0"}>
                  <Row>
                    <CartInput
                      className={`${deliveryNameError && "error"} no-margin`}
                      onChange={(e) =>
                        handleInputInfo("deliveryName", e.target.value)
                      }
                      type="text"
                      placeholder="Imię *"
                    ></CartInput>
                  </Row>
                  <Row>
                    {deliveryNameError && (
                      <Text color="#E8635C" className="center" fSize="0.6rem">
                        Proszę uzupełnić pole
                      </Text>
                    )}
                  </Row>
                </Column>

                <Column width={"100%"} margin={"5% 0 0 0"}>
                  <Row>
                    <CartInput
                      className={`${
                        deliveryLastNameError && "error"
                      } no-margin`}
                      onChange={(e) =>
                        handleInputInfo("deliveryLastName", e.target.value)
                      }
                      type="text"
                      placeholder="Nazwisko *"
                    ></CartInput>
                  </Row>
                  <Row>
                    {deliveryLastNameError && (
                      <Text className="center" color="#E8635C" fSize="0.6rem">
                        Proszę uzupełnić pole
                      </Text>
                    )}
                  </Row>
                </Column>

                <Column width={"100%"} margin={"5% 0 0 0"}>
                  <Row>
                    <CartInput
                      className={`${deliveryEmailError && "error"} no-margin`}
                      onChange={(e) =>
                        handleInputInfo("deliveryEmail", e.target.value)
                      }
                      type="text"
                      placeholder="E-mail *"
                    ></CartInput>
                  </Row>
                  <Row>
                    {deliveryEmailError && (
                      <Text className="center" color="#E8635C" fSize="0.6rem">
                        Proszę uzupełnić pole
                      </Text>
                    )}
                  </Row>
                </Column>

                <Column width={"100%"} margin={"5% 0 0 0"}>
                  <Row>
                    <CartInput
                      className={`${deliveryPhoneError && "error"} no-margin`}
                      onChange={(e) =>
                        handleInputInfo("deliveryPhone", e.target.value)
                      }
                      type="text"
                      placeholder="Telefon *"
                    ></CartInput>
                  </Row>
                  <Row>
                    {deliveryPhoneError && (
                      <Text className="center" color="#E8635C" fSize="0.6rem">
                        Proszę uzupełnić pole
                      </Text>
                    )}
                  </Row>
                </Column>

                {/* <Column width={"100%"} margin={"5% 0 0 0"}>
                  <Row>
                    <CartInput
                      className={`${deliveryStreetError && "error"} no-margin`}
                      onChange={(e) =>
                        handleInputInfo("deliveryStreet", e.target.value)
                      }
                      type="text"
                      placeholder="Ulica"
                    ></CartInput>
                  </Row>
                  <Row>
                    {deliveryStreetError && (
                      <Text className="center" color="#E8635C" fSize="0.6rem">
                        Proszę uzupełnić pole
                      </Text>
                    )}
                  </Row>
                </Column> */}

                <Row margin="5% 0 0% 0">
                  <Column width={"50%"} padding={"0 5% 0 0"}>
                    <CartInput
                      className={`${deliveryStreetError && "error"} no-margin`}
                      onChange={(e) =>
                        handleInputInfo("deliveryStreet", e.target.value)
                      }
                      type="text"
                      placeholder="Ulica *"
                    ></CartInput>
                  </Column>
                  <Column width={"25%"} padding={"0 0 0 5%"}>
                    <CartInput
                      className={`${
                        deliveryHouseNumberError && "error"
                      } no-margin`}
                      onChange={(e) =>
                        handleInputInfo("deliveryHouseNumber", e.target.value)
                      }
                      type="text"
                      placeholder="Nr domu *"
                    ></CartInput>
                  </Column>
                  <Column width={"20%"} margin={"0 0 0 5%"}>
                    <CartInput
                      className={`no-margin`}
                      onChange={(e) =>
                        handleInputInfo(
                          "deliveryApartmentNumber",
                          e.target.value
                        )
                      }
                      type="text"
                      placeholder="Nr lokalu"
                    ></CartInput>
                  </Column>
                </Row>
                <Row width="100%" justify="center">
                  {(deliveryHouseNumberError || deliveryStreetError) && (
                    <Text color="#E8635C" fSize="0.6rem">
                      Proszę uzupełnić pola
                    </Text>
                  )}
                </Row>

                <Row margin={"5% 0 0 0"}>
                  <Column width={"50%"} padding={"0 5% 0 0"}>
                    <CartInput
                      className={`${deliveryCityError && "error"} no-margin`}
                      onChange={(e) =>
                        handleInputInfo("deliveryCity", e.target.value)
                      }
                      type="text"
                      placeholder="Miejscowość *"
                    ></CartInput>
                  </Column>
                  <Column width={"50%"} padding={"0 0 0 5%"}>
                    <CartInput
                      className={`${deliveryZipCodeError && "error"} no-margin`}
                      onChange={(e) =>
                        handleInputInfo("deliveryZipCode", e.target.value)
                      }
                      placeholder="Kod pocztowy *"
                      id="zip"
                      name="zip"
                      type="text"
                      inputmode="numeric"
                      pattern="^(?(^00000(|-0000))|(\d{5}(|-\d{4})))$"
                    ></CartInput>
                  </Column>
                </Row>
                <Row width="100%" justify="center">
                  {(deliveryZipCodeError || deliveryCityError) && (
                    <Text color="#E8635C" fSize="0.6rem">
                      Proszę uzupełnić pola
                    </Text>
                  )}
                </Row>

                <Row margin={"2% 0 0 0"}>
                  <CartInput
                    type="text"
                    disabled
                    placeholder="Polska"
                  ></CartInput>
                </Row>

                <Row>
                  <CartTextArea
                    rows="4"
                    placeholder="Dodatkowe uwagi (opcjonalne)"
                    onChange={(e) =>
                      handleInputInfo("deliveryAdditionalInfo", e.target.value)
                    }
                  ></CartTextArea>
                </Row>
                <Row margin={"5% 0 0 0"}>
                  <Text fSize={"0.6rem"}>
                    Twoje dane będę przetwarzane przez Softroids Sp. z o.o.
                    m.in. w celu realizacji i obsługi zamówienia, a także w
                    innych celach zgodnie z{" "}
                    <a onClick={() => pushToPrivacy()}>Polityką prywatności</a>.
                  </Text>
                </Row>
              </Column>

              {/* Payment option column */}
              <Column
                width={isMobile ? "100%" : "33%"}
                padding={isMobile ? "0 2.5% 0 2.5%" : "0 0 0 2%"}
              >
                <Row margin={isMobile ? "6% 0 6% 0" : "0 0 6% 0"}>
                  <Text fSize={"1.4rem"}>Sposób płatności</Text>
                </Row>
                <Row margin={isMobile ? "6% 0 6% 0" : "0 0 6% 0"}>
                  <label class="container">
                    <Text fSize={"0.85rem"}>Inne dane do płatności</Text>
                    <input
                      type="checkbox"
                      onChange={() =>
                        handleCheckboxPaymentSameAsDelivery(
                          !paymentDataSameAsDelivery
                        )
                      }
                      checked={paymentDataSameAsDelivery}
                    />
                    <span class="checkmark"></span>
                  </label>
                </Row>
                <PaymentColumnContainer>
                  {paymentDataSameAsDelivery && (
                    <Column width={"100%"} margin={"5% 0 0 0"}>
                      <Row>
                        <CartInput
                          className={`${
                            !paymentDataSameAsDelivery && "disabled"
                          } ${
                            paymentNameError &&
                            paymentDataSameAsDelivery &&
                            "error"
                          } no-margin`}
                          onChange={(e) =>
                            handleInputInfo("paymentName", e.target.value)
                          }
                          disabled={!paymentDataSameAsDelivery}
                          value={
                            !paymentDataSameAsDelivery
                              ? deliverySettings.name
                              : paymentSettings.name
                          }
                          type="text"
                          placeholder="Imię *"
                        ></CartInput>
                      </Row>
                      <Row>
                        {paymentNameError && paymentDataSameAsDelivery && (
                          <Text
                            className="center"
                            color="#E8635C"
                            fSize="0.6rem"
                          >
                            Proszę uzupełnić pole
                          </Text>
                        )}
                      </Row>
                    </Column>
                  )}

                  {paymentDataSameAsDelivery && (
                    <Column width={"100%"} margin={"5% 0 0 0"}>
                      <Row>
                        <CartInput
                          className={`${
                            !paymentDataSameAsDelivery && "disabled"
                          } ${
                            paymentLastNameError &&
                            paymentDataSameAsDelivery &&
                            "error"
                          } no-margin`}
                          onChange={(e) =>
                            handleInputInfo("paymentLastName", e.target.value)
                          }
                          disabled={!paymentDataSameAsDelivery}
                          value={
                            !paymentDataSameAsDelivery
                              ? deliverySettings.lastName
                              : paymentSettings.lastName
                          }
                          type="text"
                          placeholder="Nazwisko *"
                        ></CartInput>
                      </Row>
                      <Row>
                        {paymentLastNameError && paymentDataSameAsDelivery && (
                          <Text
                            className="center"
                            color="#E8635C"
                            fSize="0.6rem"
                          >
                            Proszę uzupełnić pole
                          </Text>
                        )}
                      </Row>
                    </Column>
                  )}

                  {/* TODO: dodac pola dla nip i nazwa firmy */}

                  {paymentDataSameAsDelivery && (
                    <Column width={"100%"} margin={"5% 0 0 0"}>
                      <Row>
                        <CartInput
                          className={`${
                            !paymentDataSameAsDelivery && "disabled"
                          } ${
                            paymentEmailError &&
                            paymentDataSameAsDelivery &&
                            "error"
                          } no-margin`}
                          onChange={(e) =>
                            handleInputInfo("paymentEmail", e.target.value)
                          }
                          disabled={!paymentDataSameAsDelivery}
                          value={
                            !paymentDataSameAsDelivery
                              ? deliverySettings.email
                              : paymentSettings.email
                          }
                          type="text"
                          placeholder="E-mail *"
                        ></CartInput>
                      </Row>
                      <Row>
                        {paymentEmailError && paymentDataSameAsDelivery && (
                          <Text
                            className="center"
                            color="#E8635C"
                            fSize="0.6rem"
                          >
                            Proszę uzupełnić pole
                          </Text>
                        )}
                      </Row>
                    </Column>
                  )}

                  {paymentDataSameAsDelivery && (
                    <Column width={"100%"} margin={"5% 0 0 0"}>
                      <Row>
                        <CartInput
                          className={`${
                            !paymentDataSameAsDelivery && "disabled"
                          } ${
                            paymentPhoneError &&
                            paymentDataSameAsDelivery &&
                            "error"
                          } no-margin`}
                          onChange={(e) =>
                            handleInputInfo("paymentPhone", e.target.value)
                          }
                          disabled={!paymentDataSameAsDelivery}
                          value={
                            !paymentDataSameAsDelivery
                              ? deliverySettings.phone
                              : paymentSettings.phone
                          }
                          type="text"
                          placeholder="Telefon *"
                        ></CartInput>
                      </Row>
                      <Row>
                        {paymentPhoneError && paymentDataSameAsDelivery && (
                          <Text
                            className="center"
                            color="#E8635C"
                            fSize="0.6rem"
                          >
                            Proszę uzupełnić pole
                          </Text>
                        )}
                      </Row>
                    </Column>
                  )}

                  {paymentDataSameAsDelivery && (
                    <>
                      <Row margin="5% 0 0% 0">
                        <Column width={"50%"} padding={"0 5% 0 0"}>
                          <CartInput
                            className={`${
                              paymentDataSameAsDelivery &&
                              paymentStreetError &&
                              "error"
                            } no-margin`}
                            onChange={(e) =>
                              handleInputInfo("paymentStreet", e.target.value)
                            }
                            type="text"
                            placeholder="Ulica *"
                          ></CartInput>
                        </Column>
                        <Column width={"25%"} padding={"0 0 0 5%"}>
                          <CartInput
                            className={`${
                              paymentDataSameAsDelivery &&
                              paymentHouseNumberError &&
                              "error"
                            } no-margin`}
                            onChange={(e) =>
                              handleInputInfo(
                                "paymentHouseNumber",
                                e.target.value
                              )
                            }
                            type="text"
                            placeholder="Nr domu *"
                          ></CartInput>
                        </Column>
                        <Column width={"20%"} margin={"0 0 0 5%"}>
                          <CartInput
                            className={`no-margin`}
                            onChange={(e) =>
                              handleInputInfo(
                                "paymentApartmentNumber",
                                e.target.value
                              )
                            }
                            type="text"
                            placeholder="Nr lokalu"
                          ></CartInput>
                        </Column>
                      </Row>
                      <Row width="100%" justify="center">
                        {paymentDataSameAsDelivery &&
                          (paymentHouseNumberError || paymentStreetError) && (
                            <Text color="#E8635C" fSize="0.6rem">
                              Proszę uzupełnić pola
                            </Text>
                          )}
                      </Row>
                    </>
                  )}

                  {paymentDataSameAsDelivery && (
                    <>
                      <Row margin={"5% 0 0 0"}>
                        <Column width={"50%"} padding={"0 5% 0 0"}>
                          <CartInput
                            className={`${
                              paymentDataSameAsDelivery &&
                              paymentCityError &&
                              "error"
                            } no-margin`}
                            onChange={(e) =>
                              handleInputInfo("paymentCity", e.target.value)
                            }
                            type="text"
                            placeholder="Miejscowość *"
                          ></CartInput>
                        </Column>
                        <Column width={"50%"} padding={"0 0 0 5%"}>
                          <CartInput
                            className={`${
                              paymentDataSameAsDelivery &&
                              paymentZipCodeError &&
                              "error"
                            } no-margin`}
                            onChange={(e) =>
                              handleInputInfo("paymentZipCode", e.target.value)
                            }
                            placeholder="Kod pocztowy *"
                            id="zip"
                            name="zip"
                            type="text"
                            inputmode="numeric"
                            pattern="^(?(^00000(|-0000))|(\d{5}(|-\d{4})))$"
                          ></CartInput>
                        </Column>
                      </Row>
                      <Row width="100%" justify="center">
                        {paymentDataSameAsDelivery &&
                          (paymentZipCodeError || paymentCityError) && (
                            <Text color="#E8635C" fSize="0.6rem">
                              Proszę uzupełnić pola
                            </Text>
                          )}
                      </Row>
                    </>
                  )}

                  {paymentDataSameAsDelivery && (
                    <Row margin={"2% 0 0 0"}>
                      <CartInput
                        type="text"
                        disabled
                        placeholder="Polska"
                      ></CartInput>
                    </Row>
                  )}

                  <Row margin={"3% 0"}>
                    <label class="container">
                      <Text fSize={"0.85rem"}>Chcę otrzymać FV na firmę </Text>
                      <input
                        type="checkbox"
                        onChange={() =>
                          setPaymentSettings({
                            ...paymentSettings,
                            wantInvoice: !paymentSettings.wantInvoice,
                          })
                        }
                        checked={paymentSettings.wantInvoice}
                      />
                      <span class="checkmark"></span>
                    </label>
                  </Row>

                  {paymentSettings.wantInvoice && (
                    <>
                      <Row margin={"5% 0 0 0"}>
                        <Column width={"50%"} padding={"0 5% 0 0"}>
                          <CartInput
                            className={`${
                              paymentSettings.wantInvoice &&
                              paymentCompanyNameError &&
                              "error"
                            } no-margin`}
                            onChange={(e) =>
                              handleInputInfo(
                                "paymentCompanyName",
                                e.target.value
                              )
                            }
                            type="text"
                            placeholder="Nazwa firmy *"
                          ></CartInput>
                        </Column>
                        <Column width={"50%"} padding={"0 0 0 5%"}>
                          <CartInput
                            className={`${
                              paymentSettings.wantInvoice &&
                              paymentNipError &&
                              "error"
                            } no-margin`}
                            onChange={(e) =>
                              handleInputInfo("paymentNip", e.target.value)
                            }
                            placeholder="NIP *"
                            id="zip"
                            name="zip"
                            type="text"
                            inputmode="numeric"
                            pattern="^(?(^00000(|-0000))|(\d{5}(|-\d{4})))$"
                          ></CartInput>
                        </Column>
                      </Row>
                      <Row width="100%" justify="center">
                        {paymentSettings.wantInvoice &&
                          (paymentCompanyNameError || paymentNipError) && (
                            <Text color="#E8635C" fSize="0.6rem">
                              Proszę uzupełnić pola
                            </Text>
                          )}
                      </Row>
                    </>
                  )}

                  <Row margin={"6% 0 3% 0"}>
                    <label class="container">
                      {" "}
                      <Text fSize={"0.85rem"} fWeight={400}>
                        Szybkie płatności <b> z Przelewy24 lub kartą</b>
                      </Text>
                      <input type="checkbox" checked={true} />
                      <span class="checkmark"></span>
                    </label>
                  </Row>
                  <Row margin={"8% 0 3% 0"} justify="space-between">
                    <Text fSize={"0.85rem"}>Suma:</Text>
                    <Text fSize={"0.85rem"}>{`${
                      posterSettings.formatPrices[posterSettings.selectedFormat]
                    } zł`}</Text>
                  </Row>
                  <Row margin={"3% 0"} justify="space-between">
                    <Text fWeight={400} fSize={"0.85rem"}>
                      Dostawa:
                    </Text>
                    <Text fSize={"0.85rem"}>0 zł</Text>
                  </Row>
                  <Row margin={"6% 0 3% 0"} justify="space-between">
                    <Text fSize={"1.1rem"} fWeight={600}>
                      Do zapłaty <light>(z VAT)</light>
                    </Text>
                    {/* <Text fWeight="300"> z VAT() :</Text> */}
                    <Text fSize={"1.8rem"} fWeight={600}>
                      {`${
                        posterSettings.formatPrices[
                          posterSettings.selectedFormat
                        ]
                      } zł`}
                    </Text>
                  </Row>
                  <Row
                    className={paymentAgreementAcceptationError && "error"}
                    align={"center"}
                    margin={"0% 0 5% 0"}
                  >
                    <label class="container">
                      <Text fSize={"0.85rem"}>
                        Akceptuję{" "}
                        <a href="/regulamin" target="_blank">
                          umowę
                        </a>{" "}
                        *
                      </Text>
                      <input
                        type="checkbox"
                        onChange={() =>
                          setPaymentSettings({
                            ...paymentSettings,
                            agreementAcceptation:
                              !paymentSettings.agreementAcceptation,
                          })
                        }
                        checked={paymentSettings.agreementAcceptation}
                      />
                      <span class="checkmark"></span>
                    </label>
                  </Row>
                  <Row>
                    <PayButton onClick={() => handleClickPayButton()}>
                      PŁACĘ
                    </PayButton>
                  </Row>
                </PaymentColumnContainer>
                <Row margin="5px 0 0 0">
                  <Text fSize="0.6rem">* pole obowiązkowe</Text>
                </Row>
              </Column>
            </Row>
            {showLoader && <Loader />}
          </>
        )}
        <ToastContainer
          position="top-center"
          autoClose={2500}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </Ccntainer>
    </>
  );
};

export default CartPage;
