import { Component } from "react";
import cookie from "react-cookies";
import { BsXCircle } from "react-icons/bs";
import history from "./history";

import {
  CookieBannerContainer,
  CookieBannerElement,
  AgreeButton,
  FreeShippingBanner,
} from "./utils/atoms";

import AppRouter from "./routes/router";
class App extends Component {
  state = {
    userClosedCookieBanner: cookie.load("userClosedCookieBanner") || false,
  };

  closeCookieBanner = () => {
    this.setState({
      userClosedCookieBanner: true,
    });
    cookie.save("userClosedCookieBanner", true);
  };

  pushToPrivacy = () => {
    // this.setState({
    //   userClosedCookieBanner: true,
    // });
    // cookie.save("userClosedCookieBanner", true);
    history.push("/polityka-prywatnosci");
  };

  render() {
    return (
      <>
        {!this.state.userClosedCookieBanner && (
          <CookieBannerContainer>
            <CookieBannerElement>
              Czy wyrażają Państwo zgodę na stosowanie plików cookies zgodnie z
              <a
                onClick={() => this.pushToPrivacy()}
                rel="nofollow noopener noreferrer"
                target="/polityka-prywatnosci"
              >
                Polityką prywatności
              </a>
              ?
            </CookieBannerElement>
            <AgreeButton onClick={() => this.closeCookieBanner()}>
              OK
            </AgreeButton>
            {/* <BsXCircle onClick={() => this.closeCookieBanner()} /> */}
          </CookieBannerContainer>
        )}

        <AppRouter />
      </>
    );
  }
}

export default App;
