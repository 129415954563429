import { fontSizeFixer } from "../utils/dimensionFixer";
import { isMobile } from "react-device-detect";

const mainTheme = {
  spacing: {
    defaultPadding: "0 7.5vw",
  },
  colors: {
    accentBlue: "#008cff",
    white: "#ffffff;",
    textMainBlack: "#000000;",
    textMainWhite: "#ffffff",
    placeholderColor: "#E1E1E1",
    dotDarkGrey: "#C4C4C4",
    testimonialBackgroundCOlor: "#F5F5F5",
    footerBackgroundColor: "#000000",
  },
  font: {
    headerFont: "Lexend",
    buttonFont: "Lexend",
    paragraphFont: "Inter",
    weight: {
      normal: 500,
      light: 400,
      bold: 800,
      semiBold: 600,
    },
    size: {
      footer: isMobile ? 12 : fontSizeFixer(17),
      button: isMobile ? 18 : fontSizeFixer(20),
      paragraph: isMobile ? 14 : fontSizeFixer(18),
      mediumHeader: fontSizeFixer(55),
      smallHeader: fontSizeFixer(33),
      bigHeader: fontSizeFixer(107),
    },
  },
};

export default mainTheme;
